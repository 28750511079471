import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: { },

  appBar: {
    backgroundColor: theme.palette.common.white
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between"
  },

  logo: {
    height: 40,
    [theme.breakpoints.down("sm")]: {
      height: 30
    },
    [theme.breakpoints.down("xs")]: {
      height: 20
    }
  },

  selectRoot: {
    marginLeft: "auto",
    textTransform: "uppercase",
    "& .MuiSelect-icon": {
      top: "calc(50% - 8px)"
    },
    [theme.breakpoints.down("xs")]: {
      width: 80,
      fontSize: 12,
      "& svg": {
        fontSize: 20
      },
      "& .MuiInputAdornment-positionStart": {
        marginRight: 0
      },
      "& .MuiSelect-select.MuiSelect-select": {
        paddingRight: 14,
        paddingLeft: 4,
        fontSize: 13
      },
    },
  },

  selectInput: {
    fontSize: 16,
    height: 40
  },

  select: {
    height: 40,
    paddingBottom: 8,
    "& .MuiSelect-filled": {
      paddingTop: 15,
      paddingBottom: 0
    }
  },

  menuItem: {
    textTransform: "uppercase"
  },

  button: {
    marginLeft: 20,
    textTransform: "initial",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      fontSize: 12,
      height: 40,
      minWidth: 80
    }
  },

});