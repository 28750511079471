/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CancelReasonCategoryDescription,
    CancelReasonCategoryDescriptionFromJSON,
    CancelReasonCategoryDescriptionFromJSONTyped,
    CancelReasonCategoryDescriptionToJSON,
    CancelReasonCategoryName,
    CancelReasonCategoryNameFromJSON,
    CancelReasonCategoryNameFromJSONTyped,
    CancelReasonCategoryNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface CancelReasonCategory
 */
export interface CancelReasonCategory {
    /**
     * Id of the category
     * @type {number}
     * @memberof CancelReasonCategory
     */
    id?: number;
    /**
     * Type of reservation for which this cancel reason category is used. Choices: confirmed, requested, own.
     * @type {string}
     * @memberof CancelReasonCategory
     */
    reservationType?: string;
    /**
     * 
     * @type {CancelReasonCategoryName}
     * @memberof CancelReasonCategory
     */
    name?: CancelReasonCategoryName;
    /**
     * 
     * @type {CancelReasonCategoryDescription}
     * @memberof CancelReasonCategory
     */
    description?: CancelReasonCategoryDescription;
}

export function CancelReasonCategoryFromJSON(json: any): CancelReasonCategory {
    return CancelReasonCategoryFromJSONTyped(json, false);
}

export function CancelReasonCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelReasonCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reservationType': !exists(json, 'reservation_type') ? undefined : json['reservation_type'],
        'name': !exists(json, 'name') ? undefined : CancelReasonCategoryNameFromJSON(json['name']),
        'description': !exists(json, 'description') ? undefined : CancelReasonCategoryDescriptionFromJSON(json['description']),
    };
}

export function CancelReasonCategoryToJSON(value?: CancelReasonCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reservation_type': value.reservationType,
        'name': CancelReasonCategoryNameToJSON(value.name),
        'description': CancelReasonCategoryDescriptionToJSON(value.description),
    };
}


