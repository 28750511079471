/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * Resource image
 * @export
 * @interface ResourceImage
 */
export interface ResourceImage {
    /**
     * URL of the image
     * @type {string}
     * @memberof ResourceImage
     */
    url: string;
    /**
     * Type of the image
     * @type {LocalizedValue}
     * @memberof ResourceImage
     */
    type: ImageTypeEnum;
    /**
     * Localized caption of the image
     * @type {LocalizedValue}
     * @memberof ResourceImage
     */
    caption?: LocalizedValue;
}

export function ResourceImageFromJSON(json: any): ResourceImage {
    return ResourceImageFromJSONTyped(json, false);
}

export function ResourceImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'caption': !exists(json, 'caption') ? undefined : LocalizedValueFromJSON(json['caption'])
    };
}

export function ResourceImageToJSON(value?: ResourceImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'type': value.type,
        'caption': LocalizedValueToJSON(value.caption)
    };
}

/**
* @export
* @enum {string}
*/
export enum ImageTypeEnum {
  Main = 'main',
  GroundPlan = 'ground_plan',
  Map = 'map',
  Other = 'other'
}