import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center"
  },

});