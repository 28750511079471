import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    minHeight: 200,
    width: "100%"
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 25,
    paddingBottom: 25
  },

  grid: {},

  firstColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  logo: {
    width: "25%"
  },

  appTitleText: {
    width: "25%"
  },

  secondColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem"
  },

  thirdColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  footerLink: {
    color: theme.palette.text.secondary,
    textTransform: "initial",
    fontWeight: "bold",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1)
    }
  },

});