import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Typography } from "@material-ui/core";
import styles from "../../styles/screens/home-screen";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles } from "../../types";
import PurposesLister from "../purposes/purposes-lister";
import Banner from "../generic/banner";
import { InlineResponse2002 as PurposeResponse } from "../../generated/client";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Component for home screen
 *
 * @param props component properties
 */
const HomeScreen: React.FC<Props> = ({ classes, locale, customStyles }) => {
  const [ purposeResponse, setPurposeResponse ] = React.useState<PurposeResponse>();

  /**
   * Effect that fetches purposes
   */
  React.useEffect(() => {
    Api.getFilterApi().purposeGet({}).then(setPurposeResponse);
  }, []);

  /**
   * Component render
   */
  return (
    <AppLayout>
      <div className={ classes.container }>
        <Banner />
        <div
          className={ classes.purposes }
          style={ customStyles?.purposes }
        >
          <Typography
            variant={ "inherit" }
            className={ classes.heading }
            style={ customStyles?.heading }
          >
            { strings.homeScreen.purposeQuestion }
          </Typography>
          <PurposesLister
            locale={ locale }
            purposeResponse={ purposeResponse }
          />
        </div>
      </div>
    </AppLayout>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(HomeScreen);
const CustomStyled = withCustomStyles("screens/home-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
