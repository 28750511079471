import * as React from "react";
import { withStyles, WithStyles, List, Divider, Typography, CircularProgress } from "@material-ui/core";
import styles from "../../styles/resources/resource-date-list";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, DateHolder, SelectedDateHolder } from "../../types";
import { Resource } from "../../generated/client";
import { ResourceReservationDataHolder } from "./constants";
import * as ResourceUtils from "../../utils/resource-utils";
import ReservationItem from "../reservations/reservation-item";
import strings from "../../localization/strings";
import  * as Moment from "moment";
import { extendMoment } from "moment-range";

/**
 * Moment extended with moment-range
 */
const moment = extendMoment(Moment);

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  resource: Resource;
  selectedDates: SelectedDateHolder;
  checkedTimes: DateHolder[];
  reservationData?: ResourceReservationDataHolder;
  notReservable?: boolean;
  needsLogin?: boolean;
  customStyles?: CustomStyles;
  locale: string;
  updateCheckedTimes: (timeToAdd: DateHolder, checked: boolean) => void;
}

/**
 * Resource date list component
 *
 * @param props component props
 */
const ResourceDateList: React.FC<Props> = props => {
  const {
    classes,
    customStyles,
    loading,
    selectedDates,
    checkedTimes,
    reservationData,
    notReservable,
    needsLogin,
    locale,
    updateCheckedTimes
  } = props;

  if (!reservationData) {
    return null;
  }

  const reservationSlots = ResourceUtils.generateReservationList(reservationData, selectedDates.startDate);
  const reservationStartTime = ResourceUtils.getShortReservationStartTime(checkedTimes);
  const reservationEndTime = ResourceUtils.getShortReservationEndTime(checkedTimes);;
  const checkedTimesRange = reservationStartTime && reservationEndTime ?
    moment.range(moment(reservationStartTime), moment(reservationEndTime)) :
    undefined;
  const checkedTimesDuration = checkedTimesRange?.duration("milliseconds") ?? 0;
  const maxDuration = moment.duration(reservationData.maxPeriod).asMilliseconds();
  const maxAllowedPeriodSelected = checkedTimesDuration >= maxDuration;

  /**
   * Event handler for check box change event
   *
   * @param dateHolder date holder
   * @param event react change event
   * @param checked is checkbox checked or not
   */
  const handleCheckboxChange = (dateHolder: DateHolder) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateCheckedTimes(dateHolder, checked);
  };

  /**
   * Returns whether time slot is checked or not
   *
   * @param slot time slot
   * @returns true if slot is checked
   */
  const isChecked = (slot: DateHolder): boolean => {
    return checkedTimesRange?.contains(moment.range(slot.dates.starts, slot.dates.ends)) || false;
  }

  /**
   * Returns whether time slot is disabled or not
   *
   * @param slot time slot
   * @returns true if date is disabled
   */
  const isDisabled = (slot: DateHolder): boolean => {
    if (!checkedTimesRange) {
      return false;
    }

    const { starts, ends } = slot.dates;
    const slotRange = moment.range(starts, ends);

    const adjacent = checkedTimesRange.adjacent(slotRange);
    if (adjacent) {
      return maxAllowedPeriodSelected;
    }

    const contains = checkedTimesRange.contains(slotRange, { excludeEnd: true, excludeStart: true });
    if (contains) {
      return true;
    }

    return !checkedTimesRange.overlaps(slotRange);
  }

  /**
   * Renders loader
   */
  const renderLoader = () => {
    return (
      <div className={ classes.loaderContainer }>
        <CircularProgress size={ 70 } color="secondary"/>
      </div>
    );
  }

  /**
   * Lists available reservations
   */
  const listAvailableReservations = () => {
    if (!reservationSlots.length) {
      return (
        <div className={ classes.noReservationItems }>
          <Typography variant="h6" gutterBottom>
            { strings.reservation.reservationStatus.noReservationItems }
          </Typography>
        </div>
      );
    }

    return reservationSlots.map(slot =>
      <ReservationItem
        key={ slot.reservationTimeString }
        checked={ isChecked(slot) }
        locale={ locale }
        notReservable={ notReservable || !reservationData.reservable || slot.pastTime || slot.reserved }
        needsLogin={ needsLogin }
        disabled={ isDisabled(slot) }
        slot={ slot }
        onCheckboxChange={ handleCheckboxChange(slot) }
      />
    );
  };

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <List>
        <div
          className={ classes.headerContainer }
          style={ customStyles?.headerContainer }
        >
          <Typography
            className={ classes.timeText }
            style={ customStyles?.timeText }
          >
            { strings.reservation.time }
          </Typography>
          <Typography
            className={ classes.reservationText }
            style={ customStyles?.reservationText }
          >
            { strings.reservation.status }
          </Typography>
        </div>
        <Divider variant="fullWidth"/>
        { loading ?
          renderLoader() :
          listAvailableReservations()
        }
      </List>
    </div>
  );
};

const Styled = withStyles(styles)(ResourceDateList);
const CustomStyled = withCustomStyles("resources/resource-date-list")(Styled);

export default CustomStyled;
