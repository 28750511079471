import { CSSProperties } from "react";

/**
 * Interface describing an access token
 */
export interface AccessToken {
  created: Date;
  access_token: string;
  expires_in?: number;
  refresh_token?: string;
  refresh_expires_in?: number;
  firstName?: string;
  lastName?: string;
  userId?: string;
  email?: string;
  roles: string[];
  clientRoles: string[];
}

/**
 * Interface describing application configuration
 */
export interface Configuration {
  auth: {
    url: string;
    realm: string;
    clientId: string;
  };
  api: {
    basePath: string;
  };
  site: {
    title: string;
  };
}

/**
 * Interface describing public content
 */
export interface PublicContent {
  privacyPolicy: string;
  accessibilityStatement: string;
  frontPageDescription: string;
  moreInformationPage: string;
}

/**
 * Enum describing possible client roles
 */
export enum ClientRoles {
  CAN_IGNORE_RESOURCES_MAX_RESERVATIONS_PER_USER = "can-ignore-resources-max-reservations-per-user"
}

/**
 * Union type for nullable access token
 */
export type NullableToken = AccessToken | null;

/**
 * Interface describing custom CSS styles
 */
export interface CustomStyles {
  [className: string]: CSSProperties;
}

/**
 * Interface describing search form values
 */
export interface FormValues {
  use: string;
  office: string;
  searchString: string;
  freeOfCharge: boolean;
}

/**
 * Interface describing dropdown options
 */
export interface DropdownOption {
  label: string;
  value: string;
}

/*
 * Used resource detail types
 */
export enum ResourceDetailTypes {
  PEOPLE_CAPACITY = "peopleCapacity",
  MIN_PRICE = "minPrice",
  MAX_PRICE = "maxPrice",
  PRICE_TYPE = "priceType",
  UNIT = "unit"
}

/**
 * Custom date holder object that is used to help date data
 * processing across the application.
 */
export interface DateHolder {
  dates: {
    starts: Date;
    ends: Date;
  };
  reservationTimeString: string;
  reserved: boolean;
  pastTime: boolean;
}

/**
 * Interface for selected date holder
 */
export interface SelectedDateHolder {
  startDate: Date;
  endDate: Date;
}

/**
 * Reservation date picker type
 */
export enum ReservationDatePickerType {
  START="START",
  END="END"
}

/**
 * Interface describing date picker custom header parameters
 */
export interface DatepickerCustomHeaderParams {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

/**
 * Interface for breadcrumb links
 */
export interface BreadcrumbLink {
  path: string;
  label: string;
}

/**
 * Interface describing Respa reservation info
 */
export interface RespaReservationInfo {
  reserver_name?: string;
  reserver_phone_number?: string;
  reserver_address_street?: string;
  reserver_address_zip?: string;
  reserver_address_city?: string;
  billing_address_street?: string;
  billing_address_zip?: string;
  billing_address_city?: string;
  company?: string;
  event_description?: string;
  reserver_id?: string;
  number_of_participants?: string;
  reserver_email_address?: string;
  event_subject?: string;
  reservation_extra_questions?: string;
  billing_first_name?: string;
  billing_email_address?: string;
  billing_last_name?: string;
  billing_phone_number?: string;
  host_name?: string;
  participants?: string;
}

/**
 * Interface describing reservation date display strings
 */
export interface ReservationDateDisplayStrings {
  begin: string;
  end: string;
}