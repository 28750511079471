import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: { },

  dialog: { },

  dialogActions: {
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "flex-start",
    "&.MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: 0
    }
  },

  dialogActionsRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },

  title: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.primary
  },

  text: {
    color: theme.palette.text.primary,
    marginRight: 6
  },

  confirmFormGroup: {
    marginLeft: 10
  },

  confirmCheckboxRoot: {
    color: "black"
  },

  confirmText: { },

  termsOfUseContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 25
    }
  },

  termsOfUseLink: {
    marginLeft: 0,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },

  actionButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%"
    }
  },

  cancelButton: {
    color: theme.palette.text.primary,
    padding: 15,
    borderRadius: 30,
    marginRight: 10,
    minWidth: 120,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: 20
    }
  },

  confirmButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    borderRadius: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },

  termsOfUseDialogRoot: { },

  termsOfUseDialogPaper: { },

  termsOfUseDialogContent: {
    whiteSpace: "pre-line",
    padding: 30,
    color: theme.palette.text.primary
  }

});