/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface ResourceGetRequest {
    user?: string;
    purpose?: string;
    type?: string;
    search?: string;
    start?: Date;
    end?: Date;
    duration?: number;
    duringClosing?: boolean;
    people?: number;
    equipment?: string;
    resourceGroup?: string;
    unit?: string;
    needManualConfirmation?: boolean;
    isFavorite?: boolean;
    availableBetween?: string;
    page?: number;
    pageSize?: number;
    lat?: number;
    lon?: number;
    distance?: number;
    freeOfCharge?: boolean;
    municipality?: string;
    orderBy?: string;
    accessibilityViewpoint?: number;
    include?: string;
}

export interface ResourceIdGetRequest {
    id: string;
    start?: Date;
    end?: Date;
    duration?: number;
}

/**
 * 
 */
export class ResourceApi extends runtime.BaseAPI {

    /**
     * The resource endpoint returns resources (e.g. meeting rooms) listed in the reservation system, allowing queries based on resource purpose, type, name and availability. Availability can be specified for a desired duration in a desired time interval. This allows fetching only the resources that match a particular need at a particular time. The optional **page** parameter allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async resourceGetRaw(requestParameters: ResourceGetRequest): Promise<runtime.ApiResponse<InlineResponse2006>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.purpose !== undefined) {
            queryParameters['purpose'] = requestParameters.purpose;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }

        if (requestParameters.duringClosing !== undefined) {
            queryParameters['during_closing'] = requestParameters.duringClosing;
        }

        if (requestParameters.people !== undefined) {
            queryParameters['people'] = requestParameters.people;
        }

        if (requestParameters.equipment !== undefined) {
            queryParameters['equipment'] = requestParameters.equipment;
        }

        if (requestParameters.resourceGroup !== undefined) {
            queryParameters['resource_group'] = requestParameters.resourceGroup;
        }

        if (requestParameters.unit !== undefined) {
            queryParameters['unit'] = requestParameters.unit;
        }

        if (requestParameters.needManualConfirmation !== undefined) {
            queryParameters['need_manual_confirmation'] = requestParameters.needManualConfirmation;
        }

        if (requestParameters.isFavorite !== undefined) {
            queryParameters['is_favorite'] = requestParameters.isFavorite;
        }

        if (requestParameters.availableBetween !== undefined) {
            queryParameters['available_between'] = requestParameters.availableBetween;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }

        if (requestParameters.lon !== undefined) {
            queryParameters['lon'] = requestParameters.lon;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        if (requestParameters.freeOfCharge !== undefined) {
            queryParameters['free_of_charge'] = requestParameters.freeOfCharge;
        }

        if (requestParameters.municipality !== undefined) {
            queryParameters['municipality'] = requestParameters.municipality;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }

        if (requestParameters.accessibilityViewpoint !== undefined) {
            queryParameters['accessibility_viewpoint'] = requestParameters.accessibilityViewpoint;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resource/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * The resource endpoint returns resources (e.g. meeting rooms) listed in the reservation system, allowing queries based on resource purpose, type, name and availability. Availability can be specified for a desired duration in a desired time interval. This allows fetching only the resources that match a particular need at a particular time. The optional **page** parameter allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async resourceGet(requestParameters: ResourceGetRequest): Promise<InlineResponse2006> {
        const response = await this.resourceGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The resource endpoint returns resources (meeting rooms, workstations, reservable spaces etc.) listed in the reservation system.
     */
    async resourceIdGetRaw(requestParameters: ResourceIdGetRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resourceIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resource/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * The resource endpoint returns resources (meeting rooms, workstations, reservable spaces etc.) listed in the reservation system.
     */
    async resourceIdGet(requestParameters: ResourceIdGetRequest): Promise<Resource> {
        const response = await this.resourceIdGetRaw(requestParameters);
        return await response.value();
    }

}
