import * as React from "react";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import styles from "../../styles/generic/form-item";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  label: string;
  component: JSX.Element;
  customStyles?: CustomStyles;
  orientation?: "row" | "column";
}

/**
 * Form item component
 *
 * @param props component props
 */
const FormItem: React.FC<Props> = ({
  label,
  classes,
  component,
  orientation,
  customStyles
}) => {

  return (
    <div
      className={ classes.root }
      style={
        {
          ...customStyles?.root,
          flexDirection: orientation ? orientation : "column"
        }
      }
    >
      <Typography
        className={ classes.label }
        style={
          {
            ...customStyles?.label,
            alignSelf: orientation === "row" ? "center" : "left"
          }
        }
      >
        { label }
      </Typography>
      <div
        className={ classes.component }
        style={
          {
            ...customStyles?.component,
            alignSelf: orientation === "row" ? "center" : "left"
          }
        }
      >
        { component }
      </div>
    </div>
  );
};

const Styled = withStyles(styles)(FormItem);
const CustomStyled = withCustomStyles("generic/form-item")(Styled);

export default CustomStyled;