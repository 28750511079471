/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductDescription,
    ProductDescriptionFromJSON,
    ProductDescriptionFromJSONTyped,
    ProductDescriptionToJSON,
    ProductName,
    ProductNameFromJSON,
    ProductNameFromJSONTyped,
    ProductNameToJSON,
    ProductPrice,
    ProductPriceFromJSON,
    ProductPriceFromJSONTyped,
    ProductPriceToJSON,
} from './';

/**
 * Products represent everything one can order and pay related to reservations. A product can be the mandatory rent of the resource, or some optional extra accessories. (available only when the payment support is enabled)
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Unique identifier of the product
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * - `rent`: At least one product of type `rent` must be ordered when such is available on the resource.
     * - `extra`: Ordering of products of type `extra` is not mandatory, so when there are only `extra` products available, one can create a reservation without an order. However, when an order is created, even with just extra product(s), it must be paid to get the reservation confirmed.
     * @type {string}
     * @memberof Product
     */
    type?: ProductTypeEnum;
    /**
     * 
     * @type {ProductName}
     * @memberof Product
     */
    name?: ProductName;
    /**
     * 
     * @type {ProductDescription}
     * @memberof Product
     */
    description?: ProductDescription;
    /**
     * 
     * @type {ProductPrice}
     * @memberof Product
     */
    price?: ProductPrice;
    /**
     * The maximum quantity of this product in a single order
     * @type {number}
     * @memberof Product
     */
    maxQuantity?: number;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : ProductNameFromJSON(json['name']),
        'description': !exists(json, 'description') ? undefined : ProductDescriptionFromJSON(json['description']),
        'price': !exists(json, 'price') ? undefined : ProductPriceFromJSON(json['price']),
        'maxQuantity': !exists(json, 'max_quantity') ? undefined : json['max_quantity'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': ProductNameToJSON(value.name),
        'description': ProductDescriptionToJSON(value.description),
        'price': ProductPriceToJSON(value.price),
        'max_quantity': value.maxQuantity,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProductTypeEnum {
    Rent = 'rent',
    Extra = 'extra'
}


