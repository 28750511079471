/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelReasonCategoryDescription
 */
export interface CancelReasonCategoryDescription {
    /**
     * Swedish full description for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryDescription
     */
    sv?: string;
    /**
     * Finnish full description for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryDescription
     */
    fi?: string;
    /**
     * English full description for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryDescription
     */
    en?: string;
}

export function CancelReasonCategoryDescriptionFromJSON(json: any): CancelReasonCategoryDescription {
    return CancelReasonCategoryDescriptionFromJSONTyped(json, false);
}

export function CancelReasonCategoryDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelReasonCategoryDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function CancelReasonCategoryDescriptionToJSON(value?: CancelReasonCategoryDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sv': value.sv,
        'fi': value.fi,
        'en': value.en,
    };
}


