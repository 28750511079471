import { cleanEnv, str, url } from "envalid";
import { Configuration, PublicContent } from "../types";

/**
 * Validates that environment variables are in place and have correct form
 */
const env = cleanEnv(process.env, {
  REACT_APP_KEYCLOAK_URL: url(),
  REACT_APP_KEYCLOAK_REALM: str(),
  REACT_APP_KEYCLOAK_CLIENT_ID: str(),
  REACT_APP_API_BASE_PATH: url(),
  REACT_APP_PUBLIC_CONTENT_PATH: url(),
  REACT_APP_SITE_TITLE: str()
});

/**
 * Class providing access to application configuration
 */
class Config {

  private publicContent?: PublicContent;

  /**
   * Returns public content for site
   *
   * @returns promise of public content or undefined if could not be loaded
   */
  public getPublicContent = async () => {
    if (!this.publicContent) {
      await this.fetchPublicContent();
    }

    return this.publicContent;
  }

  /**
   * Get static application configuration
   *
   * @returns promise of static application configuration
   */
  public get = (): Configuration => ({
    auth: {
      url: env.REACT_APP_KEYCLOAK_URL,
      realm: env.REACT_APP_KEYCLOAK_REALM,
      clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID,
    },
    api: {
      basePath: env.REACT_APP_API_BASE_PATH
    },
    site: {
      title: env.REACT_APP_SITE_TITLE
    }
  });

  /**
   * Fetches public content to static memory
   */
  private fetchPublicContent = async () => {
    this.publicContent = await fetch(env.REACT_APP_PUBLIC_CONTENT_PATH)
      .then(response => response.json())
      .catch(error => console.warn("Failed to fetch site public content", JSON.stringify(error)));
  }

}

export default new Config();
