import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    maxWidth: 1200,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {      
      padding: 25,
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: 1200
    }
  },

  heading: {
    fontWeight: 800,
    color: theme.palette.text.primary,
    marginBottom: 20
  },

  subheading: {
    maxWidth: "65ch",
    marginBottom: 20
  },

  description: {
    maxWidth: "65ch",
    whiteSpace: "pre-line"
  }

});