import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    flex: 1,
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },

  label: {
    marginRight: 10
  },

  component: {
    flex: 1
  },

});
