import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Typography } from "@material-ui/core";
import styles from "../../styles/screens/accessibility-statement";
import { History } from "history";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import strings from "../../localization/strings";
import HTMLparser from "html-react-parser";
import config from "../../config";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  accessToken?: NullableToken;
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Component for privacy policy screen
 *
 * @param props component properties
 */
const PrivacyPolicyScreen: React.FC<Props> = ({ classes, customStyles }) => {
  const [ privacyPolicy, setPrivacyPolicy ] = React.useState("");
  /**
   * Effect that fetches privacy policy content
   */
  React.useEffect(() => {
    config.getPublicContent()
      .then(content => content && setPrivacyPolicy(content?.privacyPolicy))
      .catch(console.warn);
  }, []);

  /**
   * Component render
   */
  return (
    <AppLayout>
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <Typography
          variant="h4"
          className={ classes.heading }
          style={ customStyles?.heading }
        >
          { strings.privacyPolicy.heading }
        </Typography>
        <Typography
          variant="body1"
          className={ classes.description }
          style={ customStyles?.description }
        >
          { HTMLparser(privacyPolicy ?? "") }
        </Typography>
      </div>
    </AppLayout>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  locale: state.locale.locale
});

const Styled = withStyles(styles)(PrivacyPolicyScreen);
const CustomStyled = withCustomStyles("screens/privacy-policy")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
