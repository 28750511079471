import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    width: "100%",
    marginTop: 15,
    paddingLeft: 50,
    paddingRight: 50
  },

  cannotReserveText: {
    textAlign: "center"
  }

});