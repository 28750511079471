/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PurposeName,
    PurposeNameFromJSON,
    PurposeNameFromJSONTyped,
    PurposeNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface Purpose
 */
export interface Purpose {
    /**
     * Unique identifier of the usage purpose
     * @type {string}
     * @memberof Purpose
     */
    id?: string;
    /**
     * The parent purpose of this purpose, or null, if this purpose is main purpose type
     * @type {string}
     * @memberof Purpose
     */
    parent?: string;
    /**
     * 
     * @type {PurposeName}
     * @memberof Purpose
     */
    name?: PurposeName;
}

export function PurposeFromJSON(json: any): Purpose {
    return PurposeFromJSONTyped(json, false);
}

export function PurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Purpose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'name': !exists(json, 'name') ? undefined : PurposeNameFromJSON(json['name']),
    };
}

export function PurposeToJSON(value?: Purpose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parent': value.parent,
        'name': PurposeNameToJSON(value.name),
    };
}


