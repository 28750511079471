import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("")`,
  },

  banner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },

  bannerImage: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },

  bannerInfoContainer: {
    padding: `0 ${theme.spacing(2)}px`,
  },

  bannerHeading: {
    fontWeight: 800,
    marginBottom: 10
  },

  bannerDescription: { },

});