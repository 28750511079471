import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    display: "flex"
  },

  select: {
    flex: 1
  },

});
