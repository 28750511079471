
import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  loaderContainer: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  container: {
    width: "100%",
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {      
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: 1200
    }
  },

  buttonGroup: {
    justifyContent: "center",
    alignItems: "center"
  },

  reservationSwitch: {
    height: 45,
    minWidth: 300,
    borderRadius: 50,
    backgroundColor: "white",
    "&:not(:first-child)": {
      marginLeft: 1
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 200
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 130,
      padding: 10,
      fontSize: 12
    }
  },

  selected: {
    height: 48,
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },

  reservationContainer: {
    display: "flex",
    flexFlow: "row wrap"
  },

  datePickerContainer: {
    width: "50%",
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },

  timeSelectInput: {
    flexGrow: 1
  },

  dateListContainer: {
    width: "50%",
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 15
    },
  },

  reservedTimesContainer: {
    display: "flex",
    width: "100%"
  },

  actionButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },

  cancelButton: {
    width: "45%",
    margin: "0 30px",
    padding: 15,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 10px 20px 10px"
    }
  },

  reserveButton: {
    width: "45%",
    margin: "0 30px",
    padding: 15,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 10px"
    }
  },

  longReservationDateTitle: {
    margin: "10px 0"
  },

  maxUserReservations: {
    margin: "auto"
  },

});
