import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    marginTop: 25,
    marginBottom: 25,
    cursor: "pointer"
  },

  card: {
    height: "auto"
  },

  cardContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  cardImageContainer: {
    width: "25%",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  cardImage: {
    width: "100%"
  },

  cardText: {
    flex: 2,
    padding: 10,
    display: "flex",
    flexDirection: "column"
  },

  cardDetails: {
    flex: 1,
    padding: 10,
    display: "flex",
    flexDirection: "column"
  },

  cardName: {
    color: theme.palette.text.primary,
    marginBottom: 10
  },

  cardDescription: {
    color: theme.palette.text.primary,
  },

});
