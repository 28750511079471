import * as React from "react";
import { withStyles, WithStyles, TextField } from "@material-ui/core";
import styles from "../../styles/generic/search-bar";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import strings from "../../localization/strings";

/**
 * Interface representing component properties
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  searchValue?: string;
  onChange: (searchString: string) => void;
  onEnter?: () => void;
}

/**
 * Component state
 */
interface State {
  searchValue: string;
}

/**
 * Component for location section
 */
class SearchBar extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: this.props.searchValue || ""
    };
  }

  /**
   * Debounce delay for search field
   */
  private delay: NodeJS.Timeout | null = null;

  /**
   * Component did update life-cycle handler
   *
   * @param prevProps previous props
   */
  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ searchValue: this.props.searchValue || "" });
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, customStyles } = this.props;
    const { searchValue } = this.state;

    return (
      <div className={ classes.root }>
        <TextField
          variant="filled"
          className={ classes.input }
          style={ customStyles?.input }
          onChange={ this.onChange }
          onKeyDown={ this.onKeyDown }
          placeholder={ strings.mainMenu.search }
          inputProps={{ 'aria-label': strings.mainMenu.search }}
          value={ searchValue }
        />
      </div>
    );
  }

  /**
   * Event handler for text field value change
   *
   * @param event react change event
   */
  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!this.delay) {
      this.delay = setTimeout(() => {
        this.updateValue(value);
      }, 500);
    } else {
      clearTimeout(this.delay);
      this.delay = setTimeout(() => {
        this.updateValue(value);
      }, 500);
    }

    this.setState({ searchValue: value });
  };

  /**
   * Event handler for key down
   * 
   * @param event React keyboard event
   */
  private onKeyDown = (event: React.KeyboardEvent) => {
    event.key === "Enter" && this.props.onEnter && this.props.onEnter();
  }

  /**
   * Update value with delay
   *
   * @param value value to update
   */
  private updateValue = (value: string) => {
    const { onChange } = this.props;

    if (value !== undefined) {
      onChange(value);
    }
  }

}
const Styled = withStyles(styles)(SearchBar);
const CustomStyled = withCustomStyles("generic/search-bar")(Styled);

export default CustomStyled;
