import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: { },

  noResultsText: {
    width: "100%",
    textAlign: "center",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginTop: 25
    }
  },

  noReservationsText: {
    margin: 50,
    [theme.breakpoints.down("sm")]: {
      margin: 25,
      marginTop: 0
    }
  }

});