import * as React from "react";
import { withStyles, WithStyles, Checkbox, Typography, Divider } from "@material-ui/core";
import styles from "../../styles/resources/reservation-item";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, DateHolder } from "../../types";
import classNames from "classnames";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  slot: DateHolder;
  checked: boolean;
  customStyles?: CustomStyles;
  locale: string;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  notReservable?: boolean;
  needsLogin?: boolean;
  disabled?: boolean;
}

/**
 * Individual reservation list item component
 *
 * @param props component props
 */
const ReservationItem: React.FC<Props> = props => {
  const {
    classes,
    customStyles,
    slot,
    checked,
    onCheckboxChange,
    notReservable,
    needsLogin,
    disabled
  } = props;

  /**
   * Returns possible custom unavailable styles
   */
  const getCustomUnavailableStyles = () => {
    if (!notReservable && !needsLogin) {
      return {};
    };

    return customStyles?.unavailable || {};
  };

  /**
   * Returns possible custom available styles
   */
  const getCustomAvailableStyles = () => {
    if (notReservable || needsLogin || disabled){
      return {};
    }

    return customStyles?.available || {};
  }

  /**
   * Get slot status text based on the the reservation status
   *
   * @param slot slot
   * @param notReservable not reservable
   * @returns status text
   */
  const getSlotStatusText = (slot: DateHolder, notReservable: boolean): string => {
    const { reservationStatus } = strings.reservation;

    if (slot.reserved) return reservationStatus.reserved;
    if (slot.pastTime || notReservable) return reservationStatus.notAvailable;
    if (needsLogin) return reservationStatus.needsLogin;

    return reservationStatus.available;
  };

  console.log(getCustomUnavailableStyles());

  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <div
        className={
          classNames(
            classes.dataContainer,
            (notReservable || disabled) && classes.unavailable
          )
        }
        style={{
          ...customStyles?.dataContainer,
          ...getCustomUnavailableStyles()
        }}
      >
        <Checkbox
          className={
            classNames(
              classes.checkBox,
              !notReservable && !disabled && classes.available
            )
          }
          style={{
            ...customStyles?.checkBox,
            ...getCustomAvailableStyles()
          }}
          onChange={ onCheckboxChange }
          disabled={ disabled || notReservable || needsLogin }
          checked={ checked }
        />
        <Typography
          className={ classes.timeText }
          style={ customStyles?.timeText }
        >
          { slot.reservationTimeString }
        </Typography>
        <Typography
          className={ classes.reservationText }
          style={{
            ...customStyles?.reservationText,
            ...getCustomAvailableStyles()
          }}
        >
          { getSlotStatusText(slot, !!notReservable) }
        </Typography>
      </div>
      <Divider variant="fullWidth"/>
      <div className={ classNames(classes.overlay, (notReservable || disabled) && !checked && classes.visible) }/>
    </div>
  );
};

const Styled = withStyles(styles)(ReservationItem);
const CustomStyled = withCustomStyles("resources/reservation-item")(Styled);

export default CustomStyled;