/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CancelReasonRead,
    CancelReasonReadFromJSON,
    CancelReasonReadFromJSONTyped,
    CancelReasonReadToJSON,
} from './';

/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    id?: string;
    /**
     * URL of the reservation
     * @type {string}
     * @memberof Reservation
     */
    url?: string;
    /**
     * Unique identifier of the reserved resource
     * @type {string}
     * @memberof Reservation
     */
    resource?: string;
    /**
     * Unique identifier of the user who made the reservation
     * @type {string}
     * @memberof Reservation
     */
    user?: string;
    /**
     * The starting time of the reservation in ISO 8601 format
     * @type {string}
     * @memberof Reservation
     */
    begin?: string;
    /**
     * The ending time of the reservation in ISO 8601 format
     * @type {string}
     * @memberof Reservation
     */
    end?: string;
    /**
     * Is the reservation is made by the current user.
     * @type {boolean}
     * @memberof Reservation
     */
    readonly isOwn?: boolean;
    /**
     * The state the reservation is currently in.
     * @type {string}
     * @memberof Reservation
     */
    state?: ReservationStateEnum;
    /**
     * Type of the reservation
     * @type {string}
     * @memberof Reservation
     */
    type?: ReservationTypeEnum;
    /**
     * Does the reservation require a confirmation from the unit personnel?
     * @type {boolean}
     * @memberof Reservation
     */
    readonly needManualConfirmation?: boolean;
    /**
     * Reserver id
     * @type {string}
     * @memberof Reservation
     */
    reserverId?: string;
    /**
     * Reserver name
     * @type {string}
     * @memberof Reservation
     */
    reserverName?: string;
    /**
     * Reserver phone number
     * @type {string}
     * @memberof Reservation
     */
    reserverPhoneNumber?: string;
    /**
     * Reserver street address
     * @type {string}
     * @memberof Reservation
     */
    reserverAddressStreet?: string;
    /**
     * Reserver address zip code
     * @type {string}
     * @memberof Reservation
     */
    reserverAddressZip?: string;
    /**
     * Reserver address city
     * @type {string}
     * @memberof Reservation
     */
    reserverAddressCity?: string;
    /**
     * Billing street address
     * @type {string}
     * @memberof Reservation
     */
    billingAddressStreet?: string;
    /**
     * Billing address zip code
     * @type {string}
     * @memberof Reservation
     */
    billingAddressZip?: string;
    /**
     * Billing address city
     * @type {string}
     * @memberof Reservation
     */
    billingAddressCity?: string;
    /**
     * Billpayer's first name
     * @type {string}
     * @memberof Reservation
     */
    billingFirstName?: string;
    /**
     * Billpayer's last name
     * @type {string}
     * @memberof Reservation
     */
    billingLastName?: string;
    /**
     * Billpayer's phone number
     * @type {string}
     * @memberof Reservation
     */
    billingPhoneNumber?: string;
    /**
     * Billpayer's email address
     * @type {string}
     * @memberof Reservation
     */
    billingEmailAddress?: string;
    /**
     * Reserver company
     * @type {string}
     * @memberof Reservation
     */
    company?: string;
    /**
     * Description of the event the reservation is for
     * @type {string}
     * @memberof Reservation
     */
    eventDescription?: string;
    /**
     * Subject of the event the reservation is for
     * @type {string}
     * @memberof Reservation
     */
    eventSubject?: string;
    /**
     * Business ID of the reserver company
     * @type {string}
     * @memberof Reservation
     */
    businessId?: string;
    /**
     * Number of participants in the reservation
     * @type {number}
     * @memberof Reservation
     */
    numberOfParticipants?: number;
    /**
     * Reserver email address
     * @type {string}
     * @memberof Reservation
     */
    reserverEmailAddress?: string;
    /**
     * Reservation extra questions
     * @type {string}
     * @memberof Reservation
     */
    reservationExtraQuestions?: string;
    /**
     * Cancel reason
     * @type {CancelReasonRead}
     * @memberof Reservation
     */
    cancelReason?: CancelReasonRead;
    staffEvent?: boolean;
    comments?: string;
}

export function ReservationFromJSON(json: any): Reservation {
    return ReservationFromJSONTyped(json, false);
}

export function ReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'resource': !exists(json, 'resource') ? undefined : json['resource'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'begin': !exists(json, 'begin') ? undefined : json['begin'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'isOwn': !exists(json, 'is_own') ? undefined : json['is_own'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'needManualConfirmation': !exists(json, 'need_manual_confirmation') ? undefined : json['need_manual_confirmation'],
        'reserverId': !exists(json, 'reserver_id') ? undefined : json['reserver_id'],
        'reserverName': !exists(json, 'reserver_name') ? undefined : json['reserver_name'],
        'reserverPhoneNumber': !exists(json, 'reserver_phone_number') ? undefined : json['reserver_phone_number'],
        'reserverAddressStreet': !exists(json, 'reserver_address_street') ? undefined : json['reserver_address_street'],
        'reserverAddressZip': !exists(json, 'reserver_address_zip') ? undefined : json['reserver_address_zip'],
        'reserverAddressCity': !exists(json, 'reserver_address_city') ? undefined : json['reserver_address_city'],
        'billingAddressStreet': !exists(json, 'billing_address_street') ? undefined : json['billing_address_street'],
        'billingAddressZip': !exists(json, 'billing_address_zip') ? undefined : json['billing_address_zip'],
        'billingAddressCity': !exists(json, 'billing_address_city') ? undefined : json['billing_address_city'],
        'billingFirstName': !exists(json, 'billing_first_name') ? undefined : json['billing_first_name'],
        'billingLastName': !exists(json, 'billing_last_name') ? undefined : json['billing_last_name'],
        'billingPhoneNumber': !exists(json, 'billing_phone_number') ? undefined : json['billing_phone_number'],
        'billingEmailAddress': !exists(json, 'billing_email_address') ? undefined : json['billing_email_address'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'eventDescription': !exists(json, 'event_description') ? undefined : json['event_description'],
        'eventSubject': !exists(json, 'event_subject') ? undefined : json['event_subject'],
        'businessId': !exists(json, 'business_id') ? undefined : json['business_id'],
        'numberOfParticipants': !exists(json, 'number_of_participants') ? undefined : json['number_of_participants'],
        'reserverEmailAddress': !exists(json, 'reserver_email_address') ? undefined : json['reserver_email_address'],
        'reservationExtraQuestions': !exists(json, 'reservation_extra_questions') ? undefined : json['reservation_extra_questions'],
        'cancelReason': !exists(json, 'cancel_reason') ? undefined : CancelReasonReadFromJSON(json['cancel_reason']),
        'staffEvent': !exists(json, 'staff_event') ? undefined : json['staff_event'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function ReservationToJSON(value?: Reservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'resource': value.resource,
        'user': value.user,
        'begin': value.begin,
        'end': value.end,
        'state': value.state,
        'type': value.type,
        'reserver_id': value.reserverId,
        'reserver_name': value.reserverName,
        'reserver_phone_number': value.reserverPhoneNumber,
        'reserver_address_street': value.reserverAddressStreet,
        'reserver_address_zip': value.reserverAddressZip,
        'reserver_address_city': value.reserverAddressCity,
        'billing_address_street': value.billingAddressStreet,
        'billing_address_zip': value.billingAddressZip,
        'billing_address_city': value.billingAddressCity,
        'billing_first_name': value.billingFirstName,
        'billing_last_name': value.billingLastName,
        'billing_phone_number': value.billingPhoneNumber,
        'billing_email_address': value.billingEmailAddress,
        'company': value.company,
        'event_description': value.eventDescription,
        'event_subject': value.eventSubject,
        'business_id': value.businessId,
        'number_of_participants': value.numberOfParticipants,
        'reserver_email_address': value.reserverEmailAddress,
        'reservation_extra_questions': value.reservationExtraQuestions,
        'cancel_reason': CancelReasonReadToJSON(value.cancelReason),
        'staff_event': value.staffEvent,
        'comments': value.comments
    };
}

/**
* @export
* @enum {string}
*/
export enum ReservationStateEnum {
    Cancelled = 'cancelled',
    Confirmed = 'confirmed',
    Denied = 'denied',
    Requested = 'requested',
    WaitingForPayment = 'waiting_for_payment'
}
/**
* @export
* @enum {string}
*/
export enum ReservationTypeEnum {
    Normal = 'normal',
    Blocked = 'blocked'
}
