import * as React from "react";
import { AppBar, Button, Collapse, Hidden, IconButton, List, MenuItem, Toolbar, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/sub-header";
import MenuIcon from '@material-ui/icons/Menu';
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import strings from "../../localization/strings";
import { useHistory } from "react-router-dom";
import AuthorizedOnly from "../containers/authorized-only";
import config from "../../config";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Sub header component
 *
 * @param props component properties
 */
const SubHeader: React.FC<Props & OptionalProps> = ({ classes, customStyles }) => {
  const history = useHistory();
  const [ mobileMenuExpanded, setMobileMenuExpanded ] = React.useState(false);
  const [ moreInformation, setMoreInformation ] = React.useState<string>();
  /**
   * Effect that fetches public content
   */
  React.useEffect(() => {
    config.getPublicContent()
      .then(content => content && setMoreInformation(content?.moreInformationPage))
      .catch(console.warn);
  }, []);

  /**
   * Renders desktop content
   */
  const renderToolBarDesktopContent = () => (
    <List
      className={ classes.desktopList }
      style={ customStyles?.desktopList }
    >
      { renderMainMenuItems() }
    </List>
  );

  /**
   * Renders mobile content
   */
  const renderToolBarMobileContent = () => (
    <IconButton
      className={ classes.mobileMenuButton }
      style={ customStyles?.mobileMenuButton }
      onClick={ () => setMobileMenuExpanded(!mobileMenuExpanded) }
    >
      <MenuIcon />
    </IconButton>
  );

  /**
   * Renders main menu items
   */
  const renderMainMenuItems = () => (
    <>
      { renderMenuItem(strings.mainMenu.search, "/search") }
      { moreInformation &&
        renderMenuItem(strings.mainMenu.moreInformation, "/more-information")
      }
      { renderMenuItem(strings.mainMenu.genericTerms, "/generic-terms") }
      <AuthorizedOnly component={ renderMenuItem(strings.mainMenu.myReservations, "/my-reservations") }/>
    </>
  );

  /**
   * Renders single menu item
   *
   * @param name item name
   * @param route item route path
   */
  const renderMenuItem = (name: string, route: string) => (
    <MenuItem
      tabIndex={ 0 }
      className={ classes.menuItem }
      style={ customStyles?.menuItem }
      selected={ history.location.pathname.includes(route) }
      onClick={ () => history.push(route) }
    >
      { name }
    </MenuItem>
  );

  return (
    <AppBar
      position="sticky"
      className={ classes.appBar }
      style={ customStyles?.appBar }
    >
      <Toolbar
        className={ classes.toolBar }
        style={ customStyles?.toolBar }
      >
        <Button
          className={ classes.homeButton }
          style={ customStyles?.homeButton }
          onClick={ () => history.push("/") }
        >
          { strings.app.title }
        </Button>
        <div className={ classes.toolBarDesktopContent }>
          <Hidden smDown implementation="css">
            { renderToolBarDesktopContent() }
          </Hidden>
        </div>
        <div className={ classes.toolBarMobileContent }>
          <Hidden mdUp implementation="css">
            { renderToolBarMobileContent() }
          </Hidden>
        </div>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Collapse
          in={ mobileMenuExpanded }
          timeout={ 200 }
        >
          <List
            className={ classes.mobileList }
            style={ customStyles?.mobileList }
          >
            { renderMainMenuItems() }
          </List>
        </Collapse>
      </Hidden>
    </AppBar>
  );
};

const Styled = withStyles(styles)(SubHeader);
const CustomStyled = withCustomStyles("generic/sub-header")(Styled);

export default CustomStyled;
