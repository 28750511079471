import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    display: "flex",
    flexDirection: "column",
  },

  formRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  formColumn: {
    flex: 4,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flex: 1
    }
  },

  formDivider: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  formSubmit: {
    flex: 1,
    margin: "25px 10px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start"
    }
  },

  searchButton: {
    width: "48%",
    height: 50,
    borderRadius: 50,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },

  clearButton: {
    width: "48%",
    height: 50,
    borderRadius: 50,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 25
    }
  },

});
