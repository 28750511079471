import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Divider } from "@material-ui/core";
import styles from "../../styles/screens/search-screen";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles, FormValues } from "../../types";
import { InlineResponse2002 as ResourceResponse, Purpose, Unit } from "../../generated/client";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import ResourcesLister from "../resources/resources-lister";
import SearchForm from "../generic/search-form";
import Pagination from "../generic/pagination";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  units: Unit[];
  count: number;
  pageNumber: number;
  purposes: Purpose[];
  itemsPerPage: number;
  resourceResponse?: ResourceResponse;
  loading: boolean;
}

/**
 * Search screen component
 */
class SearchScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      count: 1,
      units: [],
      purposes: [],
      pageNumber: 1,
      itemsPerPage: 10,
      loading: false
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    this.setState({ loading: true });
    await this.fetchUnits();
    await this.fetchPurposes();
    await this.fetchResources({
      freeOfCharge: false,
      office: "",
      searchString: "",
      use: ""
    });
    this.setState({ loading: false });
  }

  /**
   * Component did update life cycle handler
   *
   * @param prevProps previous component props
   * @param prevState previous component state
   */
  public componentDidUpdate = async (prevProps: Props, prevState: State) => {

    if (this.state.pageNumber !== prevState.pageNumber) {
      this.setState({ loading: true });
      await this.fetchResources({
        freeOfCharge: false,
        office: "",
        searchString: "",
        use: ""
      });
      this.setState({ loading: false });
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, locale, customStyles } = this.props;
    const { units, purposes } = this.state;

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <SearchForm
            units={ units }
            locale={ locale }
            purposes={ purposes }
            onSearch={ this.search }
          />
        <Divider />
          { this.renderLayoutContent() }
        </div>
      </AppLayout>
    );
  }

  /**
   * Renders layout content
   */
  private renderLayoutContent = () => {
    const { locale, classes } = this.props;
    const {
      resourceResponse,
      count,
      pageNumber,
      itemsPerPage,
      loading
    } = this.state;

    if (loading) {
      return (
        <div className={ classes.loaderContainer }>
          <CircularProgress size={ 50 } color="secondary"/>
        </div>
      );
    }

    return (
      <>
        <ResourcesLister resourceResponse={ resourceResponse } locale={ locale } />
        { resourceResponse && resourceResponse.results && resourceResponse.results.length > 0 &&
          <Pagination
            count={ count }
            changePageNumber={ this.changePageNumber }
            pageNumber={ pageNumber }
            onChangeRowsPerPage={ this.changeRowsPerPage }
            itemsPerPage={ itemsPerPage }
          />
        }
      </>
    );
  }

  /**
   * Fetch units from API
   */
  private fetchUnits = async () => {
    const { accessToken } = this.props;

    const unitApi = Api.getUnitApi(accessToken);
    const unitResponse = await unitApi.unitGet({ });
    this.setState({
      units: unitResponse.results || []
    });
  }

  /**
   * Fetch purposes from API
   */
  private fetchPurposes = async () => {
    const { accessToken } = this.props;

    const filterApi = Api.getFilterApi(accessToken);
    const purposeResponse = await filterApi.purposeGet({ });
    this.setState({
      purposes: purposeResponse.results || []
    });
  }

  /**
   * Fetch resources from API
   */
  private fetchResources = async (formValues: FormValues) => {
    const { accessToken } = this.props;
    const { pageNumber, itemsPerPage } = this.state;
    const { use, freeOfCharge, office, searchString } = formValues;

    const resourceApi = Api.getResourceApi(accessToken);
    const resourceResponse = await resourceApi.resourceGet({
      unit: office,
      purpose: use,
      page: pageNumber,
      search: searchString,
      pageSize: itemsPerPage,
      freeOfCharge: freeOfCharge ? freeOfCharge : undefined,
    });
    const resultCount = resourceResponse.count;
    const count = resultCount ? Math.floor(resultCount / itemsPerPage) + (resultCount % itemsPerPage ? 1 : 0) || 1 : 1;
    this.setState({
      pageNumber: 1,
      count: count,
      resourceResponse: resourceResponse
    });
  }

  /**
   * Sets search form values
   *
   * @param form form values
   */
  private search = async (form: FormValues) => {
    this.setState({ loading: true });
    await this.fetchResources(form);
    this.setState({ loading: false });
  }

  /**
   * Handles changing page number
   *
   * @param event event object
   * @param page page number
   */
  private changePageNumber = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({
      pageNumber: page + 1
    });
  }

  /**
   * Handles changing rows per page
   *
   * @param event event object
   */
  private changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsPerPage: parseInt(event.target.value),
      pageNumber: 1
    })
  };
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(SearchScreen);
const CustomStyled = withCustomStyles("screens/search-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
