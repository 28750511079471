import * as React from "react";

import { FormGroup, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/resources/resource-metadata";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, RespaReservationInfo } from "../../types";
import TextField from "@material-ui/core/TextField/TextField";
import strings from "../../localization/strings";
import SimpleReactValidator from "simple-react-validator";
import WithDebounce from "../generic/with-debounce";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  supportedReservationExtraFields: string[];
  requiredReservationExtraFields: string[];
  extraContent?: string | undefined | JSX.Element;
  reservationExtraInfo: RespaReservationInfo;
  onUpdate: (extraInformation: RespaReservationInfo) => void;
  isValid: (valid: boolean) => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for resource metadata
 */
class ResourceMetadata extends React.Component<Props, State> {

  private validator: SimpleReactValidator;

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: strings.reservation.addDialog.fieldRequired,
        email: strings.reservation.addDialog.invalidEmail
      },
      element: (message: string) => <p style={{ color: "red", margin: 0 }}>{ message }</p>
    });

    this.state = { };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = () => {
    this.props.isValid(this.validator.allValid());
  }

  /**
   * Component render method
   */
  public render = () => {
    const {
      classes,
      customStyles,
      extraContent,
      requiredReservationExtraFields
    } = this.props;

    const formFields = this.renderFormFields();
    const hasRequiredFields = requiredReservationExtraFields.length > 0;

    return (
      <div className={ classes.container }>
        { extraContent }
        { hasRequiredFields &&
          <Typography
            className={ classes.requiredFieldsText }
            style={ customStyles?.requiredFieldsText }
          >
            { strings.reservation.addDialog.required }
          </Typography>
        }
        { formFields }
      </div>
    );
  }

  /**
   * Renders form fields
   */
  private renderFormFields = () => {
    const {
      reservationExtraInfo,
      supportedReservationExtraFields,
      requiredReservationExtraFields
    } = this.props;

    return supportedReservationExtraFields.map(field => {
      const reservationInfoKey = field as keyof RespaReservationInfo;
      const metadataKey = field as keyof typeof strings.resource.metadata;
      const localizedFieldName = strings.resource.metadata[metadataKey] as string;
      const value = reservationExtraInfo[field as keyof RespaReservationInfo] || "";
      const required = requiredReservationExtraFields.includes(field);
      return this.renderTextField(localizedFieldName, reservationInfoKey, value, required);
    });
  }

  /**
   * Render text field with given parameters
   *
   * @param label text field label
   * @param key settings key
   * @param value settings value
   * @param required is text field required
   */
  private renderTextField = (
    label: string,
    key: keyof RespaReservationInfo,
    value: string,
    required: boolean
  ) => {
    const { classes } = this.props;
    const validationRules = this.resolveFieldValidationRules(key, required);

    return (
      <WithDebounce
        key={ key }
        name={ key }
        label={ label }
        value={ value }
        onChange={ this.onTextFieldChange(validationRules) }
        debounceTimeout={ 300 }
        component={ props =>
          <FormGroup>
            <TextField
              { ...props }
              required={ required }
              className={ classes.metadataTextField }
              variant="filled"
              fullWidth
            />
            { this.validator.message(key, value, validationRules) }
          </FormGroup>
        }
      />
    );
  }

  /**
   * Resolves field validation rules
   *
   * @param key field key
   * @param required field is required
   * @returns list of validation rule strings
   */
  private resolveFieldValidationRules = (key: keyof RespaReservationInfo, required?: boolean): string[] => {
    const validationRules: string[] = [];

    if (required) {
      validationRules.push("required");
    }

    if (key === "billing_email_address" || key === "reserver_email_address") {
      validationRules.push("email");
    }

    return validationRules;
  }

  /**
   * Event handler for text field value change
   *
   * @param validationRules validation rules for text field
   */
  private onTextFieldChange = (validationRules: string[]) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { reservationExtraInfo, onUpdate } = this.props;
    const { name, value } = event.target;

    if (!name) {
      return;
    }

    onUpdate({ ...reservationExtraInfo, [name]: value });

    if (validationRules.length) {
      const updatedFieldValid = validationRules.every(rule => this.validator.check(value, rule));
      const otherFieldsValid = this.validator.allValid();

      if (!updatedFieldValid || !otherFieldsValid) {
        this.validator.showMessageFor(name);
      }

      this.props.isValid(updatedFieldValid && otherFieldsValid);
    }
  }
}

const Styled = withStyles(styles)(ResourceMetadata);
const CustomStyled = withCustomStyles("screens/resource-screen")(Styled);
const Connected = CustomStyled;

export default Connected;