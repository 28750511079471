/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelReasonCategory,
    CancelReasonCategoryFromJSON,
    CancelReasonCategoryToJSON,
} from '../models';

export interface CancelReasonCategoryGetRequest {
    reservationType?: string;
}

/**
 * 
 */
export class CancelReasonApi extends runtime.BaseAPI {

    /**
     * Predefined categories for cancellation reason for reservations. Includes predefined notification messages in all languages.
     */
    async cancelReasonCategoryGetRaw(requestParameters: CancelReasonCategoryGetRequest): Promise<runtime.ApiResponse<CancelReasonCategory>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.reservationType !== undefined) {
            queryParameters['reservation_type'] = requestParameters.reservationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cancel_reason_category/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelReasonCategoryFromJSON(jsonValue));
    }

    /**
     * Predefined categories for cancellation reason for reservations. Includes predefined notification messages in all languages.
     */
    async cancelReasonCategoryGet(requestParameters: CancelReasonCategoryGetRequest): Promise<CancelReasonCategory> {
        const response = await this.cancelReasonCategoryGetRaw(requestParameters);
        return await response.value();
    }

}
