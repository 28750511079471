/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Purpose,
    PurposeFromJSON,
    PurposeFromJSONTyped,
    PurposeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * URL for the next page
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string;
    /**
     * URL for the previous page
     * @type {string}
     * @memberof InlineResponse2002
     */
    prev?: string;
    /**
     * The total number of results
     * @type {number}
     * @memberof InlineResponse2002
     */
    count?: number;
    /**
     * 
     * @type {Array<Purpose>}
     * @memberof InlineResponse2002
     */
    results?: Array<Purpose>;
}

export function InlineResponse2002FromJSON(json: any): InlineResponse2002 {
    return InlineResponse2002FromJSONTyped(json, false);
}

export function InlineResponse2002FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2002 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next': !exists(json, 'next') ? undefined : json['next'],
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(PurposeFromJSON)),
    };
}

export function InlineResponse2002ToJSON(value?: InlineResponse2002 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next': value.next,
        'prev': value.prev,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(PurposeToJSON)),
    };
}


