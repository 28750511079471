import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Typography, CircularProgress } from "@material-ui/core";
import styles from "../../styles/screens/my-reservations-screen";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles } from "../../types";
import { InlineResponse2002 as ResourceResponse, InlineResponse2007 as ReservationResponse, ReservationStateEnum } from "../../generated/client";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import ReservationsLister from "../reservations/reservations-lister";
import ConfirmDialog from "../generic/confirm-dialog";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  reservationId: string;
  confirmDialogOpen: boolean;
  resourceResponse?: ResourceResponse;
  reservationResponse?: ReservationResponse;
}

/**
 * Component for user reservations
 */
class MyReservationsScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      reservationId: "",
      confirmDialogOpen: false,
      loading: true
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    this.setState({ loading: true });
    await this.fetchReservations();
    this.setState({ loading: false });
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, locale, customStyles } = this.props;
    const { resourceResponse, reservationResponse, loading } = this.state;

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <Typography
            variant="h4"
            className={ classes.heading }
            style={ customStyles?.heading }
          >
            { strings.mainMenu.myReservations }
          </Typography>
          { loading ?
            this.renderLoader() :
            <ReservationsLister
              locale={ locale }
              resourceResponse={ resourceResponse }
              reservationResponse={ reservationResponse }
              onCancelReservation={ this.openDialog }
            />
          }
        </div>
        { this.renderConfirmDialog() }
      </AppLayout>
    );
  }

  /**
   * Renders loader
   */
  private renderLoader = () => {
    return (
      <div className={ this.props.classes.loaderContainer }>
        <CircularProgress size={ 50 } color="secondary"/>
      </div>
    );
  }

  /**
   * Fetch reservations from API
   */
  private fetchReservations = async () => {
    const { accessToken } = this.props;

    if (!accessToken) {
      return;
    }

    const resourceApi = Api.getResourceApi(accessToken);
    const reservationApi = Api.getReservationApi(accessToken);

    const { results } = await reservationApi.reservationGet({ isOwn: true, pageSize: 1000, page: 1 });
    const reservationResults = (results || []).filter(result => result.state !== ReservationStateEnum.Cancelled);
    const resourceIds = Array.from(
      new Set(
        reservationResults
          .filter(result => result.resource)
          .map(result => result.resource!)
      )
    );
    const resourceResults = await Promise.all(
      resourceIds.map(id => resourceApi.resourceIdGet({ id: id || "" }))
    );

    this.setState({
      reservationResponse: { results: reservationResults } as ReservationResponse,
      resourceResponse: { results: resourceResults } as ResourceResponse
    });
  }

  /**
   * Method for rendering confirm dialog
   */
  private renderConfirmDialog = () => {
    const { confirmDialogOpen } = this.state;

    return (
      <ConfirmDialog
        open={ confirmDialogOpen }
        onClose={ this.closeDialog }
        onCancel={ this.closeDialog }
        onConfirm={ this.confirmDialog }
        title={ strings.reservation.deleteDialog.title }
        cancelButtonText={ strings.reservation.deleteDialog.cancel }
        confirmButtonText={ strings.reservation.deleteDialog.confirm }
      >
        { strings.reservation.deleteDialog.text }
      </ConfirmDialog>
    );
  }

  /**
   * Method for handling dialog confirm
   */
  private confirmDialog = () => {
    this.cancelReservation();
    this.setState({
      reservationId: "",
      confirmDialogOpen: false
    });
  }

  /**
   * Method for opening confirm dialog
   *
   * @param id reservation id
   */
  private openDialog = (id: string) => () => {
    this.setState({
      reservationId: id,
      confirmDialogOpen: true
    });
  }

  /**
   * Method for closing confirm dialog
   */
  private closeDialog = () => {
    this.setState({
      reservationId: "",
      confirmDialogOpen: false
    });
  }

  /**
   * Method for cancelling reservation
   */
  private cancelReservation = async () => {
    const { accessToken } = this.props;
    const { reservationId } = this.state;

    if (!accessToken) {
      return;
    }

    const reservationApi = Api.getReservationApi(accessToken);
    await reservationApi.reservationIdDelete({ id: reservationId });
    this.fetchReservations();
  };

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(MyReservationsScreen);
const CustomStyled = withCustomStyles("screens/my-reservations-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
