import * as React from "react";
import { withStyles, WithStyles, Typography, Tooltip } from "@material-ui/core";
import styles from "../../styles/resources/resource-details";
import strings from "../../localization/strings";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { LocalizedValue, PriceTypeEnum, Resource } from "../../generated/client";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import EuroIcon from "@material-ui/icons/Euro";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BetweenIcon from "../../resources/svg-paths/between-icon";
import BorderStyleIcon from "@material-ui/icons/BorderStyle";
import moment from "moment"

/**
 * Humanized time values for displaying min and max reservation times
 */
const HumanizedTimeUnits = new Map<string, LocalizedValue>([
  ["m", { en: "min", fi: "min" }],
  ["h", { en: "h", fi: "h" }],
  ["d", { en: "d", fi: "pv" }],
  ["w", { en: "wk", fi: "vk" }],
  ["M", { en: "mth", fi: "kk" }],
  ["Y", { en: "yr", fi: "v" }]
]);

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  resource: Resource;
  customStyles?: CustomStyles;
  locale: string;
}

/**
 * Interface describing resource opening hours for single day
 */
interface ResourceOpeningHours {
  date: string,
  opens: string,
  closes: string
}

/**
 * Interface describing unit opening hours
 */
interface UnitOpeningHours {
  [date: string]: { opens: string, closes: string }[];
}

/**
 * Custom styles to tooltip element
 */
const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#e8e8e8",
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: "theme.shadows[1]",
    fontSize: 11,
  },
}))(Tooltip);

/**
 * Resource details component
 *
 * @param props component props
 */
const ResourceDetails: React.FC<Props> = props => {
  const {
    classes,
    resource,
    customStyles,
    locale
  } = props;

  const {
    minPeriod,
    maxPeriod,
    minPrice,
    maxPrice,
    priceType,
    peopleCapacity,
    openingHours,
    unit,
    area
  } = resource;

  /**
   * Returns opening hours for today, if found.
   * Checks opening hours first from resource and then from unit.
   */
  const getOpeningHoursToday = () => {
    if (openingHours) {
      const resourceOpeningHours = openingHours[0] as ResourceOpeningHours;

      if (resourceOpeningHours) {
        return resourceOpeningHours;
      }
    }

    if (unit && unit.openingHoursToday) {
      const key = moment().format("YYYY-MM-DD");
      const unitOpeningHoursObject = unit.openingHoursToday as UnitOpeningHours;
      const unitOpeningHoursList = unitOpeningHoursObject[key];

      if (unitOpeningHoursList && unitOpeningHoursList.length > 0) {
        return unitOpeningHoursList[0];
      }
    }

    return undefined;
  }

  /**
   * Returns formatted local time string from UTC date string
   * 
   * @param date UTC date as string
   * @returns time as string
   */
  const getTimeString = (date: string): string => {
    return moment.utc(date).locale(locale).local().format("LT");
  }

  /**
   * Returns reservation time limits
   */
  const getReservationTimeLimits = () => {
    if (minPeriod && maxPeriod) {
      return `${displayTime(minPeriod)} - ${displayTime(maxPeriod)}`;
    }

    if (minPeriod) {
      return `${displayTime(minPeriod)} -`;
    }

    if (maxPeriod) {
      return `- ${displayTime(maxPeriod)}`;
    }
  }

  /**
   * Returns resources area
   */
  const getResourceArea = () => {
    if (area) {
      return `${area} m2`;
    }
    return undefined;
  }

  /**
   * Display time string
   * 
   * @param time time string
   */
  const displayTime = (time: string) => {
    const duration = moment.duration(time);

    if (duration.asHours() < 1) {
      return `${duration.asMinutes()}${getHumanizedTimeValue("m")}`;
    }

    if (duration.asDays() < 1) {
      return `${duration.asHours()}${getHumanizedTimeValue("h")}`;
    }

    if (duration.asDays() < 7) {
      return `${duration.asDays()}${getHumanizedTimeValue("d")}`;
    }

    if (duration.asDays() < 30) {
      return `${duration.asWeeks().toPrecision(1)}${getHumanizedTimeValue("w")}`;
    }

    if (duration.asMonths() < 11) {
      return `${duration.asMonths().toPrecision(1)}${getHumanizedTimeValue("M")}`;
    }

    if (duration.asMonths() > 11) {
      return `${duration.asYears().toPrecision(1)}${getHumanizedTimeValue("Y")}`;
    }
  }

  /**
   * Humanize time value
   * 
   * @param unit unit to humanize
   */
  const getHumanizedTimeValue = (unit: "m" | "h" | "d" | "w" | "M" | "Y") => {
    const localizedValue = HumanizedTimeUnits.get(unit);
    return localizedValue ? localizedValue[locale as keyof LocalizedValue] : "";
  }

  /**
   * Returns display price based on resource price type and min and max prices
   */
  const getDisplayPrice = () => {
    if (!minPrice) {
      return strings.genericWords.freeOfCharge;
    }

    const minPriceNumber = Number(minPrice);
    if (Number.isNaN(minPriceNumber)) {
      return;
    }

    const maxPriceNumber = Number(maxPrice);
    if (Number.isNaN(minPriceNumber)) {
      return;
    }

    let displayPrice: string = minPriceNumber.toFixed(2);
    if (maxPrice) {
      let maxPriceString: string = maxPriceNumber.toFixed(2);
      displayPrice += `-${maxPriceString}`;
    }

    displayPrice += "€";

    if (priceType && priceType !== PriceTypeEnum.Fixed) {
      switch (priceType) {
        case PriceTypeEnum.Hourly:
          displayPrice += `/${strings.genericWords.hour}`;
        break;
        case PriceTypeEnum.Daily:
          displayPrice += `/${strings.genericWords.day}`;
        break;
        default:
          displayPrice += `/${strings.genericWords.week}`;
        break;
      }
    }

    return displayPrice;
  }

  /**
   * Renders detail row
   * 
   * @param value value as string or number
   * @param options possible icon as JSX element and name as string
   */
  const renderDetailRow = (tooltip: string, value: string | number, options?: { icon?: JSX.Element, name?: string }) => {
    return (
      <LightTooltip title={ tooltip } placement="left-start">
        <span>
          <div
            className={ classes.detailRow }
            style={ customStyles?.detailRow }
          >
            { options?.name &&
              <Typography
                variant="body1"
                className={ classes.detailName }
                style={ customStyles?.detailName }
              >
                { options.name }
              </Typography>
            }
            { options?.icon }
            <Typography
              className={ classes.detailValue }
              style={ customStyles?.detailValue }
            >
              { value }
            </Typography>
          </div>
        </span>
      </LightTooltip>
    );
  }

  const reservationTimeLimitString = getReservationTimeLimits();
  const resourceArea = getResourceArea();
  const priceString = getDisplayPrice();
  const openingHoursToday = getOpeningHoursToday();
  const openingHoursString = openingHoursToday && openingHoursToday.opens && openingHoursToday.closes ?
    `${getTimeString(openingHoursToday.opens)} - ${getTimeString(openingHoursToday.closes)}` :
    strings.genericWords.closed;

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      { unit && unit.addressPostalFull &&
        renderDetailRow(
          strings.tooltip.address,
          unit.addressPostalFull,
          { icon: <RoomIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
      { resourceArea &&
        renderDetailRow(
          strings.tooltip.resourceArea,
          resourceArea,
          { icon: <BorderStyleIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
      { peopleCapacity &&
        renderDetailRow(
          strings.tooltip.peopleCapacity,
          `${peopleCapacity} ${strings.genericWords.people}`,
          { icon: <PersonIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
      { reservationTimeLimitString &&
        renderDetailRow(
          strings.tooltip.reservationTimeLimit,
          reservationTimeLimitString,
          { icon: <BetweenIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
      { priceString &&
        renderDetailRow(
          strings.tooltip.price,
          priceString,
          { icon: <EuroIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
      { openingHoursString &&
        renderDetailRow(
          strings.tooltip.openingHours,
          openingHoursString,
          { icon: <AccessTimeIcon className={ classes.detailIcon } style={ customStyles?.detailIcon }/> }
        )
      }
    </div>
  );
};

const Styled = withStyles(styles)(ResourceDetails);
const CustomStyled = withCustomStyles("resources/resource-details")(Styled);

export default CustomStyled;
