import * as React from "react";

import { Card, withStyles, WithStyles, Button, Paper } from "@material-ui/core";
import styles from "../../styles/generic/reservation-card";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Resource, Reservation, ReservationStateEnum, ImageTypeEnum } from "../../generated/client";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, NullableToken } from "../../types";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import strings from "../../localization/strings";
import moment from "moment";
import { ReduxState, ReduxActions } from "../../store";
import * as ResourceUtils from "../../utils/resource-utils";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  resource: Resource;
  reservation: Reservation;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  onCancelReservation: (id: string) => () => void;
}

/**
 * Reservation card component
 *
 * @param props component props
 */
const ReservationCard: React.FC<Props> = props => {
  const {
    classes,
    locale,
    resource,
    reservation,
    customStyles,
    onCancelReservation
  } = props;

  /**
   * Renders cancel button
   */
  const renderCancelButton = () => {
    return (
      <Button
        variant="contained"
        className={ classes.cancelButton }
        style={ customStyles?.cancelButton }
        onClick={ onCancelReservation(reservation.id || "") }
      >
        { strings.generic.reservationCard.cancelReservation }
      </Button>
    );
  }

  /**
   * Renders waiting for confirmation notice
   */
  const renderWaitingForConfirmation = () => {
    return (
      <Paper
        elevation={ 0 }
        className={ classes.noticeContainer }
        style={ customStyles?.noticeContainer }
      >
        { strings.reservation.waitingForConfirmation }
      </Paper>
    );
  }

  /**
   * Renders waiting for payment
   */
  const renderWaitingForPayment = () => {
    return (
      <Paper
        elevation={ 0 }
        className={ classes.noticeContainer }
        style={ customStyles?.noticeContainer }
      >
        { strings.reservation.waitingForPayment }
      </Paper>
    );
  }

  /**
   * Renders contact to cancel
   */
  const renderContactToCancel = () => {
    return (
      <Paper
        elevation={ 0 }
        className={ classes.noticeContainer }
        style={ customStyles?.noticeContainer }
      >
        { strings.reservation.contactStaffToCancel }
      </Paper>
    );
  }

  /**
   * Render rejected notice and remove reservation button
   */
  const renderRejected = () => {
    return (
      <>
        <Paper
          elevation={ 0 }
          className={ classes.rejectedContainer }
          style={ customStyles?.rejectedContainer }
        >
          { strings.reservation.rejected }
        </Paper>
        <Button
          variant="outlined"
          className={ classes.removeButton }
          style={ customStyles?.removeButton }
          onClick={ onCancelReservation(reservation.id || "") }
        >
          { strings.reservation.actions.remove }
        </Button>
    </>
    );
  }

  /**
   * Renders reservation state
   */
  const renderReservationState = () => {
    if (!reservation.needManualConfirmation) {
      return renderCancelButton();
    }

    switch (reservation.state) {
      case ReservationStateEnum.Requested:
        return renderWaitingForConfirmation();
      case ReservationStateEnum.Denied:
        return renderRejected();
      case ReservationStateEnum.WaitingForPayment:
        return renderWaitingForPayment();
      case ReservationStateEnum.Confirmed:
      default:
        if (reservation.needManualConfirmation) {
          return renderContactToCancel();
        }

        return renderCancelButton();
    }
  }

  /**
   * Construct display time
   *
   * @param reservation reservation
   * @returns display string
   */
  const constructDisplayTime = (reservation: Reservation) => {
    const { begin, end } = reservation;

    if (!begin || !end) {
      return;
    }

    const longReservation = moment(end).diff(moment(begin), 'days') >= 1;

    return longReservation ?
      ResourceUtils.getLongReservationDisplayString(begin, end) :
      ResourceUtils.getShortReservationDisplayString(begin, end);
  };

  const displayTimes = constructDisplayTime(reservation);

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <Card
        className={ classes.card }
        style={ customStyles?.card }
      >
        <CardContent
          className={ classes.cardContent }
          style={ customStyles?.cardContent }
        >
          <div
            className={ classes.cardImageContainer }
            style={ customStyles?.cardImageContainer }
          >
            <img
              src={ resource.images?.find(image => image.type === ImageTypeEnum.Main)?.url || "" }
              alt="resource"
              className={ classes.cardImage }
            />
          </div>
          <div
            className={ classes.cardText }
            style={ customStyles?.cardText }
          >
            <Typography
              className={ classes.cardName }
              style={ customStyles?.cardName }
            >
              { resource.name && ResourceUtils.getLocalizedProperty(resource.name, locale) }
            </Typography>
            <Typography
              className={ classes.cardDescription }
              style={ customStyles?.cardDescription }
            >
              { resource.description && ResourceUtils.getLocalizedProperty(resource.description, locale) }
            </Typography>
            <div
              className={ classes.subDescription }
              style={ customStyles?.subDescription }
            >
              <Typography>
                { displayTimes &&
                  `${strings.generic.reservationCard.begins} ${displayTimes.begin}`
                }
              </Typography>
              <Typography>
                { displayTimes &&
                  `${ strings.generic.reservationCard.ends } ${displayTimes.end}`
                }
              </Typography>
            </div>
          </div>
          <div
            className={ classes.cardDetails }
            style={ customStyles?.cardDetails }
          >
            { renderReservationState() }
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({ });

const Styled = withStyles(styles)(ReservationCard);
const CustomStyled = withCustomStyles("generic/reservation-card")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
