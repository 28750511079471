/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    Purpose,
    PurposeFromJSON,
    PurposeFromJSONTyped,
    PurposeToJSON,
    Reservation,
    ReservationFromJSON,
    ReservationFromJSONTyped,
    ReservationToJSON,
    ResourceEquipment,
    ResourceEquipmentFromJSON,
    ResourceEquipmentFromJSONTyped,
    ResourceEquipmentToJSON,
    ResourceImage,
    ResourceImageFromJSON,
    ResourceImageFromJSONTyped,
    ResourceImageToJSON,
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
    Unit,
    UnitFromJSON,
    UnitFromJSONTyped,
    UnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * Unique identifier of the resource in the reservation system
     * @type {string}
     * @memberof Resource
     */
    id?: string;
    /**
     * Usage purposes for this resource
     * @type {Array<Purpose>}
     * @memberof Resource
     */
    purposes?: Array<Purpose>;
    /**
     * Usage purposes for this resource
     * @type {Array<Purpose>}
     * @memberof Resource
     */
    images?: Array<ResourceImage>;
    /**
     * 
     * @type {Type}
     * @memberof Resource
     */
    type?: Type;
    /**
     * The intervals when the resource is not reserved during the queried period
     * @type {Array<object>}
     * @memberof Resource
     */
    availableHours?: Array<object>;
    /**
     * The intervals when the resource is open during the queried period
     * @type {Array<object>}
     * @memberof Resource
     */
    openingHours?: Array<object>;
    /**
     * Is it possible to create or modify reservations for this resource through the API. Even if this is false, reservations by other means (eg. phone call) might still be possible.
     * @type {boolean}
     * @memberof Resource
     */
    reservable?: boolean;
    /**
     * Info about reservation
     * @type {LocalizedValue}
     * @memberof Resource
     */
    reservationInfo?: LocalizedValue;
    /**
     * Reservable max. days in advance, accepts null value
     * @type {number}
     * @memberof Resource
     */
    reservableMaxDaysInAdvance?: number;
    /**
     * Reservable min. days in advance, accepts null value
     * @type {number}
     * @memberof Resource
     */
    reservableMinDaysInAdvance?: number;
    /**
     * The reservations made for the resource during the queried period
     * @type {Array<Reservation>}
     * @memberof Resource
     */
    reservations?: Array<Reservation>;
    /**
     * Generic terms of use for the resource
     * @type {LocalizedValue}
     * @memberof Resource
     */
    genericTerms?: LocalizedValue;
    /**
     * Responsible contact info of use for the resource
     * @type {LocalizedValue}
     * @memberof Resource
     */
    responsibleContactInfo?: LocalizedValue;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    modifiedAt?: string;
    /**
     * 
     * @type {LocalizedValue}
     * @memberof Resource
     */
    name?: LocalizedValue;
    /**
     * 
     * @type {LocalizedValue}
     * @memberof Resource
     */
    description?: LocalizedValue;
    /**
     * URL of resource picture
     * @type {string}
     * @memberof Resource
     */
    photo?: string;
    /**
     * Does making a reservation require confirmation from the unit personnel?
     * @type {boolean}
     * @memberof Resource
     */
    needManualConfirmation?: boolean;
    /**
     * The type of authentication required to reserve the resource
     * @type {string}
     * @memberof Resource
     */
    authentication?: string;
    /**
     * The maximum number of people for the resource
     * @type {number}
     * @memberof Resource
     */
    peopleCapacity?: number;
    /**
     * The floor area, in sq. m.
     * @type {number}
     * @memberof Resource
     */
    area?: number;
    /**
     * URL of the floor plan
     * @type {string}
     * @memberof Resource
     */
    groundPlan?: string;
    /**
     * The minimum duration of a reservation for the resource, in hh:mm:ss
     * @type {string}
     * @memberof Resource
     */
    minPeriod?: string;
    /**
     * The minimum price of a reservation
     * @type {string}
     * @memberof number
     */
    minPrice?: number;
    /**
     * The maximum price of a reservation
     * @type {number}
     * @memberof Resource
     */
    maxPrice?: number;
    /**
     * The maximum duration of a reservation for the resource, in hh:mm:ss
     * @type {string}
     * @memberof Resource
     */
    maxPeriod?: string;
    /**
     * Price type
     * @type {PriceTypeEnum}
     * @memberof Resource
     */
    priceType?: PriceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    modifiedBy?: string;
    /**
     * Unique identifier for the unit where the resource is located
     * @type {Unit}
     * @memberof Resource
     */
    unit?: Unit;
    /**
     * 
     * @type {Location}
     * @memberof Resource
     */
    location?: Location;
    /**
     * 
     * @type {Array<ResourceEquipment>}
     * @memberof Resource
     */
    equipment?: ResourceEquipment[];
    /**
     * URL to an external calendar for the resource.
     * @type {string}
     * @memberof Resource
     */
    externalReservationUrl?: string;
    /**
     * List of supported reservation extra fields
     * @type {Array<string>}
     * @memberof Resource
     */
    supportedReservationExtraFields?: Array<string>;
    /**
     * List of supported required extra fields
     * @type {Array<string>}
     * @memberof Resource
     */
    requiredReservationExtraFields?: Array<string>;
    /**
     * Max reservation per user
     * @type {number}
     * @memberof Resource
     */
    maxReservationsPerUser?: number;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'purposes': !exists(json, 'purposes') ? undefined : ((json['purposes'] as Array<any>).map(PurposeFromJSON)),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ResourceImageFromJSON)),
        'type': !exists(json, 'type') ? undefined : TypeFromJSON(json['type']),
        'availableHours': !exists(json, 'available_hours') ? undefined : json['available_hours'],
        'openingHours': !exists(json, 'opening_hours') ? undefined : json['opening_hours'],
        'reservable': !exists(json, 'reservable') ? undefined : json['reservable'],
        'reservationInfo': !exists(json, 'reservation_info') ? undefined : LocalizedValueFromJSON(json['reservation_info']),
        'genericTerms': !exists(json, 'generic_terms') ? undefined : LocalizedValueFromJSON(json['generic_terms']),
        'responsibleContactInfo': !exists(json, 'responsible_contact_info') ? undefined : LocalizedValueFromJSON(json['responsible_contact_info']),
        'reservableMaxDaysInAdvance': !exists(json, 'reservable_max_days_in_advance') ? undefined : json['reservable_max_days_in_advance'],
        'reservableMinDaysInAdvance': !exists(json, 'reservable_min_days_in_advance') ? undefined : json['reservable_min_days_in_advance'],
        'reservations': !exists(json, 'reservations') ? undefined : ((json['reservations'] as Array<any>).map(ReservationFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'modifiedAt': !exists(json, 'modified_at') ? undefined : json['modified_at'],
        'name': !exists(json, 'name') ? undefined : LocalizedValueFromJSON(json['name']),
        'description': !exists(json, 'description') ? undefined : LocalizedValueFromJSON(json['description']),
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'needManualConfirmation': !exists(json, 'need_manual_confirmation') ? undefined : json['need_manual_confirmation'],
        'authentication': !exists(json, 'authentication') ? undefined : json['authentication'],
        'peopleCapacity': !exists(json, 'people_capacity') ? undefined : json['people_capacity'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'groundPlan': !exists(json, 'ground_plan') ? undefined : json['ground_plan'],
        'minPeriod': !exists(json, 'min_period') ? undefined : json['min_period'],
        'maxPeriod': !exists(json, 'max_period') ? undefined : json['max_period'],
        'minPrice': !exists(json, 'min_price') ? undefined : json['min_price'],
        'maxPrice': !exists(json, 'max_price') ? undefined : json['max_price'],
        'priceType': !exists(json, 'price_type') ? undefined : json['price_type'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'modifiedBy': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'unit': !exists(json, 'unit') ? undefined : UnitFromJSON(json['unit']),
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'equipment': !exists(json, 'equipment') ? undefined : ((json['equipment'] as Array<any>).map(ResourceEquipmentFromJSON)),
        'externalReservationUrl': !exists(json, 'external_reservation_url') ? undefined : json['external_reservation_url'],
        'supportedReservationExtraFields': !exists(json, 'supported_reservation_extra_fields') ? undefined : ((json['supported_reservation_extra_fields'] as Array<string>)),
        'requiredReservationExtraFields': !exists(json, 'required_reservation_extra_fields') ? undefined : ((json['required_reservation_extra_fields'] as Array<string>)),
        'maxReservationsPerUser': !exists(json, 'max_reservations_per_user') ? undefined : json['max_reservations_per_user']
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'purposes': value.purposes === undefined ? undefined : ((value.purposes as Array<any>).map(PurposeToJSON)),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ResourceImageToJSON)),
        'type': TypeToJSON(value.type),
        'available_hours': value.availableHours,
        'opening_hours': value.openingHours,
        'reservable': value.reservable,
        'reservationInfo': LocalizedValueToJSON(value.reservationInfo),
        'genericTerms': LocalizedValueToJSON(value.genericTerms),
        'responsibleContactInfo': LocalizedValueFromJSON(value.responsibleContactInfo),
        'reservable_max_days_in_advance': value.reservableMaxDaysInAdvance,
        'reservable_min_days_in_advance': value.reservableMinDaysInAdvance,
        'reservations': value.reservations === undefined ? undefined : ((value.reservations as Array<any>).map(ReservationToJSON)),
        'created_at': value.createdAt,
        'modified_at': value.modifiedAt,
        'name': LocalizedValueToJSON(value.name),
        'description': LocalizedValueToJSON(value.description),
        'photo': value.photo,
        'need_manual_confirmation': value.needManualConfirmation,
        'authentication': value.authentication,
        'people_capacity': value.peopleCapacity,
        'area': value.area,
        'ground_plan': value.groundPlan,
        'min_period': value.minPeriod,
        'max_period': value.maxPeriod,
        'created_by': value.createdBy,
        'modified_by': value.modifiedBy,
        'unit': UnitToJSON(value.unit),
        'location': LocationToJSON(value.location),
        'equipment': value.equipment === undefined ? undefined : ((value.equipment as Array<any>).map(ResourceEquipmentToJSON)),
        'external_reservation_url': value.externalReservationUrl,
        'supported_reservation_extra_fields': value.supportedReservationExtraFields,
        'required_reservation_extra_fields': value.requiredReservationExtraFields,
        max_reservations_per_user: value.maxReservationsPerUser
    };
}

/**
* @export
* @enum {string}
*/
export enum PriceTypeEnum {
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Fixed = 'fixed'
}
