import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Typography, CircularProgress } from "@material-ui/core";
import styles from "../../styles/screens/generic-terms";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles } from "../../types";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Component for generic terms screen
 *
 * @param props component properties
 */
const GenericTermsScreen: React.FC<Props> = ({ classes, customStyles }) => {
  const [ genericTerms, setGenericTerms ] = React.useState<string>();
  const [ loading, setLoading ] = React.useState(true);
  /**
   * Effect that fetches generic terms
   */
  React.useEffect(() => {
    Api.getResourceApi().resourceGet({})
      .then(response => setGenericTerms((response.results || [])[0].genericTerms?.fi))
      .catch(console.warn)
      .finally(() => setLoading(false));
  }, []);

  /**
   * Renders terms
   */
  const renderTerms = () => {
    if (loading) {
      return (
        <div className={ classes.loaderContainer }>
          <CircularProgress size={ 50 } color="secondary"/>
        </div>
      );
    }

    return (
      <Typography
        variant="body1"
        className={ classes.description }
        style={ customStyles?.description }
      >
        { genericTerms }
      </Typography>
    );
  }
  /**
   * Component render
   */
  return (
    <AppLayout>
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <Typography
          variant="h4"
          className={ classes.heading }
          style={ customStyles?.heading }
        >
          { strings.genericTerms.heading }
        </Typography>
        { renderTerms() }
      </div>
    </AppLayout>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(GenericTermsScreen);
const CustomStyled = withCustomStyles("screens/generic-terms-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
