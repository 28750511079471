/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
    UnitStreetAddress,
    UnitStreetAddressFromJSON,
    UnitStreetAddressFromJSONTyped,
    UnitStreetAddressToJSON,
    UnitWwwUrl,
    UnitWwwUrlFromJSON,
    UnitWwwUrlFromJSONTyped,
    UnitWwwUrlToJSON,
} from './';

/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * Unique identifier for the unit in the service registry.
     * @type {string}
     * @memberof Unit
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof Unit
     */
    openingHoursToday?: object;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    modifiedAt?: string;
    /**
     * 
     * @type {UnitName}
     * @memberof Unit
     */
    name?: LocalizedValue;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    description?: string;
    /**
     * tz database name of the time zone used at the unit
     * @type {string}
     * @memberof Unit
     */
    timeZone?: string;
    /**
     * 
     * @type {UnitStreetAddress}
     * @memberof Unit
     */
    streetAddress?: UnitStreetAddress;
    /**
     * Zip code for the street address
     * @type {string}
     * @memberof Unit
     */
    addressZip?: string;
    /**
     * Phone number
     * @type {string}
     * @memberof Unit
     */
    phone?: string;
    /**
     * Contact email address
     * @type {string}
     * @memberof Unit
     */
    email?: string;
    /**
     * 
     * @type {UnitWwwUrl}
     * @memberof Unit
     */
    wwwUrl?: UnitWwwUrl;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    addressPostalFull?: string;
    /**
     * URL of unit picture
     * @type {string}
     * @memberof Unit
     */
    pictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    pictureCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Location}
     * @memberof Unit
     */
    location?: Location;
}

export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'openingHoursToday': !exists(json, 'opening_hours_today') ? undefined : json['opening_hours_today'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'modifiedAt': !exists(json, 'modified_at') ? undefined : json['modified_at'],
        'name': !exists(json, 'name') ? undefined : LocalizedValueFromJSON(json['name']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'streetAddress': !exists(json, 'street_address') ? undefined : UnitStreetAddressFromJSON(json['street_address']),
        'addressZip': !exists(json, 'address_zip') ? undefined : json['address_zip'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'wwwUrl': !exists(json, 'www_url') ? undefined : UnitWwwUrlFromJSON(json['www_url']),
        'addressPostalFull': !exists(json, 'address_postal_full') ? undefined : json['address_postal_full'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
        'pictureCaption': !exists(json, 'picture_caption') ? undefined : json['picture_caption'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'modifiedBy': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
    };
}

export function UnitToJSON(value?: Unit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'opening_hours_today': value.openingHoursToday,
        'created_at': value.createdAt,
        'modified_at': value.modifiedAt,
        'name': LocalizedValueToJSON(value.name),
        'description': value.description,
        'time_zone': value.timeZone,
        'street_address': UnitStreetAddressToJSON(value.streetAddress),
        'address_zip': value.addressZip,
        'phone': value.phone,
        'email': value.email,
        'www_url': UnitWwwUrlToJSON(value.wwwUrl),
        'address_postal_full': value.addressPostalFull,
        'picture_url': value.pictureUrl,
        'picture_caption': value.pictureCaption,
        'created_by': value.createdBy,
        'modified_by': value.modifiedBy,
        'location': LocationToJSON(value.location),
    };
}
