/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelReasonCategoryName
 */
export interface CancelReasonCategoryName {
    /**
     * Swedish name for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryName
     */
    sv?: string;
    /**
     * Finnish name for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryName
     */
    fi?: string;
    /**
     * English name for the cancel reason category
     * @type {string}
     * @memberof CancelReasonCategoryName
     */
    en?: string;
}

export function CancelReasonCategoryNameFromJSON(json: any): CancelReasonCategoryName {
    return CancelReasonCategoryNameFromJSONTyped(json, false);
}

export function CancelReasonCategoryNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelReasonCategoryName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function CancelReasonCategoryNameToJSON(value?: CancelReasonCategoryName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sv': value.sv,
        'fi': value.fi,
        'en': value.en,
    };
}


