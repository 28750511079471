import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import MuiCheckbox from "@material-ui/core/Checkbox";
import styles from "../../styles/generic/checkbox";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

/**
 * Checkbox component
 *
 * @param props component props
 */
const Checkbox: React.FC<Props> = ({
  classes,
  customStyles,
  checked,
  onChange
}) => {

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <MuiCheckbox
        className={ classes.checkbox }
        style={ customStyles?.checkbox }
        onChange={ (event, checked) => onChange(checked) }
        checked={ checked }
      />
    </div>
  );
};

const Styled = withStyles(styles)(Checkbox);
const CustomStyled = withCustomStyles("generic/checkbox")(Styled);

export default CustomStyled;