import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {},

  appBar: {
    color: theme.palette.text.secondary,
  },

  toolBar: {
    width: "100%"
  },

  homeButton: {
    color: theme.palette.text.secondary,
    textTransform: "initial",
    fontWeight: "bold"
  },

  toolBarDesktopContent: {
    width: "100%"
  },

  toolBarMobileContent: {
    marginLeft: "auto"
  },

  desktopList: {
    display: "flex"
  },

  mobileList: {},

  menuItem: {
    "&:nth-of-type(3)": {
      marginLeft: "auto"
    }
  },

  mobileMenuButton: {
    marginLeft: "auto",
    color: theme.palette.common.white
  }

});
