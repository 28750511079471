
import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    width: "100%",
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {      
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: 1200
    }
  },

  loaderContainer: {
    width: "100%",
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  heading: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 25
    }
  },

});