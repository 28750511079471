import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  root: {
    padding: 25,
  },

  breadcrumb: {
    color: theme.palette.text.primary
  }

});