import * as React from "react";
import { withStyles, WithStyles, Typography, Card } from "@material-ui/core";
import styles from "../../styles/resources/resource-general-info";
import strings from "../../localization/strings";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { ImageTypeEnum, Resource, ResourceEquipment } from "../../generated/client";
import "moment/locale/fi";
import * as ResourceUtils from "../../utils/resource-utils";
import ResourceDetails from "../resources/resource-details";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  resource: Resource;
  customStyles?: CustomStyles;
  locale: string;
}

/**
 * Resource general info component
 *
 * @param props component props
 */
const ResourceGeneralInfo: React.FC<Props> = props => {
  const {
    classes,
    resource,
    customStyles,
    locale
  } = props;

  const {
    name,
    description,
    images,
    equipment,
    reservationInfo,
    responsibleContactInfo,
  } = resource;

  /**
   * Returns main image from resource if found
   */
  const getMainImage = () => {
    if (!images || images.length < 1) {
      return;
    }

    return images.find(image => image.type === ImageTypeEnum.Main)?.url;
  }

  /**
   * Renders equipment
   */
  const renderEquipment = () => {
    return equipment && equipment.length > 0 && (
      <>
        <Typography
          variant="h5"
          paragraph
          className={ classes.equipmentTitle }
          style={ customStyles?.equipmentTitle }
        >
          { strings.resource.equipment }
        </Typography>
        { equipment.map(equipmentPiece => renderEquipmentPiece(equipmentPiece)) }
      </>
    );
  }

  /**
   * Renders single piece of equipment
   * 
   * @param equipmentPiece equipment piece
   */
  const renderEquipmentPiece = (equipmentPiece: ResourceEquipment) => {
    if (!equipmentPiece.name) {
      return;
    }

    return (
      <div key={ equipmentPiece.id }>
        <Typography
          className={ classes.equipmentName }
          style={ customStyles?.equipmentName }
        >
          { ResourceUtils.getLocalizedProperty(equipmentPiece.name, locale) }
        </Typography>
        { equipmentPiece.description &&
          <Typography
            paragraph
            className={ classes.equipmentDescription }
            style={ customStyles?.equipmentDescription }
          >
            { ResourceUtils.getLocalizedProperty(equipmentPiece.description, locale) }
          </Typography>
        }
      </div>
    );
  }

  /**
   * Renders details
   */
  const renderDetails = () => {
    return (
      <ResourceDetails
        locale={ locale }
        resource={ resource }
      />
    );
  }

  /**
   * Component render
   */
  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <div
        className={ classes.infoContainer }
        style={ customStyles?.infoContainer }
      >
        <div
          className={ classes.descriptionContainer }
          style={ customStyles?.descriptionContainer }
        >
          { name &&
            <Typography
              className={ classes.title }
              style={ customStyles?.title }
            >
              { ResourceUtils.getLocalizedProperty(name, locale) }
            </Typography>
          }
          { description &&
            <Typography
              className={ classes.description }
              style={ customStyles?.description }
            >
              { ResourceUtils.getLocalizedProperty(description, locale) }
            </Typography>
          }
          { (reservationInfo || responsibleContactInfo) &&
          <Card className={ classes.reservationInfoCard }>
            { reservationInfo &&
            <Typography
              className={ classes.reservationInfo }
              style={ customStyles?.reservationInfo }
            >
              { ResourceUtils.getLocalizedProperty(reservationInfo, locale) }
            </Typography>
            }
            { responsibleContactInfo &&
            <Typography
              className={ classes.reservationInfo }
              style={ customStyles?.reservationInfo }
            >
              { ResourceUtils.getLocalizedProperty(responsibleContactInfo, locale) }
            </Typography>
            }
          </Card>
          }
        </div>
        <div
          className={ classes.detailsContainer }
          style={ customStyles?.detailsContainer }
        >
          { renderDetails() }
        </div>
      </div>
      <div
        className={ classes.equipmentContainer }
        style={ customStyles?.equipmentContainer }
      >
        { renderEquipment() }
      </div>
      <div
        className={ classes.imageContainer }
        style={ customStyles?.imageContainer }
      >
        <img
          className={ classes.resourceImage }
          style={ customStyles?.resourceImage }
          alt={ strings.generic.imageAlt }
          src={ getMainImage() }
        />
      </div>
    </div>
  );
};

const Styled = withStyles(styles)(ResourceGeneralInfo);
const CustomStyled = withCustomStyles("resources/general-resource-info")(Styled);

export default CustomStyled;