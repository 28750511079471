import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    width: "100%",
    borderRadius: 0,
    boxShadow: theme.shadows[2],
    marginTop: 8,
    padding: 40,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      maxWidth: 400
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
      padding: 20
    }
  },

  reservedDayWrapper: {
    color: theme.palette.common.white,
    borderRadius: "50%",
    border: `2px solid ${theme.palette.error.main}`,
    height: 60,
    width: 60,
    margin: "0 2px",
    "& .MuiPickersDay-day": {
      margin: 0,
      width: 56,
      height: 56
    },
    [theme.breakpoints.down("md")]: {
      height: 40,
      width: 40,
      "& .MuiPickersDay-day": {
        height: 36,
        width: 36
      }
    },
    [theme.breakpoints.down("sm")]: {
      height: 60,
      width: 60,
      "& .MuiPickersDay-day": {
        height: 56,
        width: 56
      }
    },
    [theme.breakpoints.down("xs")]: {
      height: 35,
      width: 35,
      "& .MuiPickersDay-day": {
        height: 31,
        width: 31
      }
    }
  },

  legendContainer: {
    display: "flex",
    width: "100%",
    padding: "0 10px",
    justifyContent: "center"
  },

  legendMarkerReserved: {
    display: "block",
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: "50%",
    marginRight: 15
  }

});
