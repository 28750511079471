import { ResourceReservationDataHolder, RespaDateObject } from "../components/resources/constants";
import { DateHolder, ReservationDateDisplayStrings, ReservationDatePickerType, RespaReservationInfo, SelectedDateHolder } from "../types";
import { Reservation, ReservationTypeEnum, Resource, ReservationStateEnum, LocalizedValue } from "../generated/client";
import strings from "../localization/strings";
import moment from "moment";

/**
 * Generates available reservation list based on resource opening hours and
 * minimum reservation length.
 *
 * @param reservationData reservation data holder
 * @returns list of available dates or empty list
 */
export const generateReservationList = (reservationData: ResourceReservationDataHolder, selectedDate: Date): DateHolder[] => {
  const { openingHours, minPeriod } = reservationData;
  const reservations = reservationData.reservations
    .filter(reservation => reservation.state !== ReservationStateEnum.Cancelled)
    .filter(reservation => reservation.begin && moment(reservation.begin).isSame(selectedDate, "day"));
  
  const selectedDateOpeningHours = openingHours.find(openingHoursItem => openingHoursItem.date === moment(selectedDate).format("YYYY-MM-DD"));
  const startTime = (selectedDateOpeningHours || openingHours[0]).opens;
  const stopTime = (selectedDateOpeningHours || openingHours[0]).closes;
  const period = minPeriod;
  const periodSplit = period.split(":");

  if (periodSplit.length !== 3) {
    return [];
  }

  const firstTime = new Date(startTime);
  const lastTime = new Date(stopTime);

  const periodHours = periodSplit[0] === "00" ? 0 : Number(periodSplit[0]);
  const periodMinutes = periodSplit[1] === "00" ? 0 : Number(periodSplit[1]);
  const periodSeconds = periodSplit[2] === "00" ? 0 : Number(periodSplit[2]);

  const seconds = periodSeconds * 1000;
  const minutes = periodMinutes * 60 * 1000;
  const hours =  periodHours * 60 * 60 * 1000;
  const timeToAdd = hours + minutes + seconds;
  return processTimes(firstTime, lastTime, timeToAdd, reservations);
};

/**
 * Process all times and create list of DateHolder objects
 *
 * @param firstTime first time slot to process
 * @param lastTime last available time slot
 * @param timeToAdd time to add (how long each individual time slot is)
 * @param reservations list of already existing reservations
 * @returns returns list of generated date holder objects
 */
const processTimes = (firstTime: Date, lastTime: Date, timeToAdd: number, reservations: Reservation[]): DateHolder[] => {
  const listOfAvailableTimes: DateHolder[] = [];

  let timeToProcess = firstTime;

  while (timeToProcess.getTime() < lastTime.getTime()) {
    const nextTime = new Date(timeToProcess.getTime() + timeToAdd);

    const dateData: DateHolder = {
      dates: {
        starts: timeToProcess,
        ends: nextTime
      },
      reservationTimeString: parseDateAsReservationSlotString(timeToProcess, nextTime),
      reserved: checkReservation(timeToProcess, reservations),
      pastTime: checkIfPastTime(timeToProcess)
    };

    listOfAvailableTimes.push(dateData);
    timeToProcess = nextTime;
  }

  return listOfAvailableTimes;
};

/**
 * Parse date as reservation slot string
 *
 * @param start start date
 * @param end end date
 * @returns string that will be displayed in the UI
 */
const parseDateAsReservationSlotString = (start: Date, end: Date): string => {
  const startHour = parseNumberToDoubleCharacterString(start.getHours());
  const startMinutes = parseNumberToDoubleCharacterString(start.getMinutes());

  const endHour = parseNumberToDoubleCharacterString(end.getHours());
  const endMinutes = parseNumberToDoubleCharacterString(end.getMinutes());

  return (`${startHour}:${startMinutes}-${endHour}:${endMinutes}`);
};

/**
 * Parse number to double character number
 *
 * @param value number value
 * @returns parsed string
 */
const parseNumberToDoubleCharacterString = (value: number): string => {
  return ( value < 10 ? `0${value}` : String(value));
};

/**
 * Check if current processed time is reserved
 *
 * @param timeToProcess time to process
 * @param reservations list of reservations
 * @returns return true if time has reservation otherwise return false
 */
const checkReservation = (timeToProcess: Date, reservations: Reservation[]): boolean => {
  const test = reservations.filter(reservation => {
    if (reservation.begin && reservation.end) {
      const beginDate = new Date(reservation.begin);
      const endDate = new Date(reservation.end);
      return (
        timeToProcess.getTime() >= beginDate.getTime() &&
        timeToProcess.getTime() < endDate.getTime()
      );
    }
    return false;
  });

  return test.length > 0;
};

/**
 * Check if current processed time is in the past
 *
 * @param timeToProcess time to check
 * @returns return true if time was in the past otherwise return false
 */
const checkIfPastTime = (timeToProcess: Date): boolean => {
  const currentDate = new Date();
  return currentDate.valueOf() >= timeToProcess.valueOf();
};

/**
 * Construct date to search from selected date (date picker) and opening hours of
 * selected resource. In order to fetch all reservations of selected date we have to
 * use first available time of the day (for example 09:00). Date picker always returns
 * selected date with current time stamp.
 *
 * @param selectedDate selected date
 * @param resource selected resource
 * @returns constructed date or current time as date time
 */
export const constructDateToSearch = (selectedDate: Date, resource: Resource): Date => {
  const currentDate = new Date();
  if (!resource.openingHours || selectedDate.getTime() < currentDate.getTime()) {
    return currentDate;
  }

  const openingHours = resource.openingHours[0] as RespaDateObject;
  const startDate = new Date(openingHours.opens);
  const startHour = startDate.getHours();
  const startMinute = startDate.getMinutes();

  setTimeValues(selectedDate, startHour, startMinute, 0);

  return selectedDate;
};

/**
 * Set time values to date
 *
 * @param dateToUpdate date to update
 * @param hours hours to set, defaults to 0
 * @param minutes minutes to set, defaults to 0
 * @param seconds seconds to set, defaults to 0
 * @returns update date
 */
export const setTimeValues = (dateToUpdate: Date, hours: number = 0, minutes: number = 0, seconds: number = 0): Date => {
  dateToUpdate.setHours(hours);
  dateToUpdate.setMinutes(minutes);
  dateToUpdate.setSeconds(seconds);
  return dateToUpdate;
};

/**
 * Constructs single reservation from checked times
 *
 * @param resource resource to be reserved
 * @param checkedTimes list of selected times
 * @param reservationExtraInfo extra information related to reservation
 * @returns new reservation object
 */
export const constructReservationFromCheckedTimes = (
  resource: Resource,
  checkedTimes: DateHolder[],
  reservationExtraInfo: RespaReservationInfo
): Reservation => {
  checkedTimes.sort((time1, time2) => {
    return time1.dates.starts.getTime() - time2.dates.starts.getTime();
  });

  const start = checkedTimes[0].dates.starts;
  const end = checkedTimes[checkedTimes.length - 1].dates.ends;
  return createSingleReservation(resource.id!!, start, end, reservationExtraInfo, false);
};

/**
 * Construct single reservation item
 *
 * @param id resource ID
 * @param begin reservation begins
 * @param end reservation ends
 * @param reservationExtraInfo extra information related to reservation
 * @param isLongReservation is this reservation long reservation
 * @returns reservation to save
 */
export const createSingleReservation = (
  id: string,
  begin: Date | moment.Moment,
  end: Date | moment.Moment,
  reservationExtraInfo: RespaReservationInfo,
  isLongReservation?: boolean
): Reservation => {
  const reservationDates = isLongReservation ?
    getLongReservationDates(begin, end) :
    getShortReservationDates(begin, end);

  return {
    resource: id,
    begin: reservationDates.startDate.toISOString(),
    end: reservationDates.endDate.toISOString(),
    eventDescription: reservationExtraInfo.event_description,
    eventSubject: reservationExtraInfo.event_subject,
    reserverAddressCity: reservationExtraInfo.reserver_address_city,
    reserverAddressStreet: reservationExtraInfo.reserver_address_street,
    reserverAddressZip: reservationExtraInfo.reserver_address_zip,
    reserverEmailAddress: reservationExtraInfo.reserver_email_address,
    reserverId: reservationExtraInfo.reserver_id,
    reserverName: reservationExtraInfo.reserver_name,
    reserverPhoneNumber: reservationExtraInfo.reserver_phone_number,
    billingAddressCity: reservationExtraInfo.billing_address_city,
    billingAddressStreet: reservationExtraInfo.billing_address_street,
    billingAddressZip: reservationExtraInfo.billing_address_zip,
    billingEmailAddress: reservationExtraInfo.billing_email_address,
    billingFirstName: reservationExtraInfo.billing_first_name,
    billingLastName: reservationExtraInfo.billing_last_name,
    billingPhoneNumber: reservationExtraInfo.billing_phone_number,
    company: reservationExtraInfo.company,
    reservationExtraQuestions: reservationExtraInfo.reservation_extra_questions,
    numberOfParticipants: reservationExtraInfo.number_of_participants ? Number(reservationExtraInfo.number_of_participants) : undefined,
    type: ReservationTypeEnum.Normal
  } as Reservation;
};

/**
 * Returns long reservation dates
 * Both dates are converted to UTC time while keeping local time values.
 * Start date is then returned as start of day from given date, and end date as start of day from given date + 1 day,
 * so that the last reserved day is calculated as whole day in Respa.
 * 
 * @param startDate start date
 * @param endDate end date
 */
export const getLongReservationDates = (startDate: Date | moment.Moment, endDate: Date | moment.Moment): SelectedDateHolder => ({
    startDate: moment(startDate).utc(true).startOf("day").toDate(),
    endDate: moment(endDate).utc(true).add(1, "day").startOf("day").toDate()
});

/**
 * Returns short reservation dates
 * 
 * @param startDate start date
 * @param endDate end date
 */
export const getShortReservationDates = (startDate: Date | moment.Moment, endDate: Date | moment.Moment): SelectedDateHolder => ({
  startDate: moment(startDate).toDate(),
  endDate: moment(endDate).toDate()
});

/**
 * Returns property from resource according to locale
 *
 * @param property property
 * @param locale current locale
 */
export const getLocalizedProperty = (property: LocalizedValue, locale: string) => {
  return property[locale as keyof LocalizedValue] ?? property.fi;
};

/**
 * Update long reservation times
 *
 * @param date date
 * @param dataHolder data holder
 * @param type reservation date picker type
 * @param minimumReservationLength possible minimum reservation length
 * @param maximumReservationLength possible maximum reservation length
 * @returns date holder
 */
export const updateLongReservationTimes = (
  date: Date,
  dataHolder: SelectedDateHolder,
  type: ReservationDatePickerType,
  minimumReservationLength?: moment.Duration,
  maximumReservationLength?: moment.Duration
): SelectedDateHolder => {
  switch (type) {
    case ReservationDatePickerType.START:
      dataHolder.startDate = moment(date).startOf("day").toDate();
      const endDate = moment(date).endOf("day");

      const minimumEndDate = minimumReservationLength ?
        moment(endDate).add(minimumReservationLength).subtract(1, "day") :
        undefined;

      const maximumEndDate = maximumReservationLength ?
        moment(endDate).add(maximumReservationLength).subtract(1, "days") :
        undefined;

      if (minimumEndDate && moment(dataHolder.endDate).isBefore(minimumEndDate)) {
        dataHolder.endDate = minimumEndDate.toDate();
        break;
      }

      if (maximumEndDate && moment(dataHolder.endDate).isAfter(maximumEndDate)) {
        dataHolder.endDate = maximumEndDate.toDate();
        break;
      }

      if (moment(dataHolder.endDate).isBefore(dataHolder.startDate)) {
        dataHolder.endDate = moment(dataHolder.startDate).endOf("day").toDate();
      }
    break;
    case ReservationDatePickerType.END:
      dataHolder.endDate = moment(date).endOf("day").toDate();
    break;
    default:
    break;
  }

  return dataHolder;
};

/**
 * Update short reservation times
 *
 * @param date date
 * @param dataHolder data holder
 * @returns updated date holder
 */
export const updateShortReservationTimes = (date: Date, dataHolder: SelectedDateHolder): SelectedDateHolder => {
  const start = setTimeValues(date);
  dataHolder.startDate = start;
  dataHolder.endDate = start;
  return dataHolder;
};

/**
 * Returns long reservation display strings
 * 
 * @param beginDateString begin date as string
 * @param endDateString end date as string
 * @returns reservation date display strings object
 */
export const getLongReservationDisplayString = (beginDateString: string, endDateString: string): ReservationDateDisplayStrings => {
  return {
    begin: moment(beginDateString).format("DD.MM.YYYY"),
    end: moment(endDateString).subtract(1, "day").endOf("day").format("DD.MM.YYYY")
  };
}

/**
 * Returns short reservation display strings
 * 
 * @param beginDateString begin date as string
 * @param endDateString end date as string
 * @returns reservation date display strings object
 */
export const getShortReservationDisplayString = (beginDateString: string, endDateString: string): ReservationDateDisplayStrings => {
  return {
    begin: moment(beginDateString).format(`DD.MM.YYYY [${strings.genericWords.clock}] HH.mm`),
    end: moment(endDateString).format(`DD.MM.YYYY [${strings.genericWords.clock}] HH.mm`)
  };
}

/**
 * Get short reservation start time from reservation slots
 *
 * @param slots list of reserved slots
 * @returns start time as date object or undefined if slots is undefined or empty array
 */
export const getShortReservationStartTime = (slots?: DateHolder[]): Date | undefined => {
  return slots && slots.length ?
    moment(Math.min(...slots.map(slot => slot.dates.starts.getTime()))).toDate() :
    undefined;
}

/**
 * Get short reservation end time from reservation slots
 *
 * @param slots list of reserved slots
 * @returns end time as date object or undefined if slots is undefined or empty array
 */
export const getShortReservationEndTime = (slots?: DateHolder[]): Date | undefined => {
  return slots && slots.length ?
    moment(Math.max(...slots.map(slot => slot.dates.ends.getTime()))).toDate() :
    undefined;
}