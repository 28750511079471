import * as React from "react";
import { withStyles, WithStyles, Select, MenuItem } from "@material-ui/core";
import styles from "../../styles/generic/dropdown";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, DropdownOption } from "../../types";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  selected: string;
  options: DropdownOption[];
  customStyles?: CustomStyles;
  onChange: (option: string) => void;
}

/**
 * Dropdown component
 *
 * @param props component props
 */
const Dropdown: React.FC<Props> = ({
  classes,
  options,
  selected,
  onChange,
  customStyles
}) => {
  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <Select
        value={ selected }
        variant={ "filled" }
        className={ classes.select }
        onChange={ (event) => onChange(String(event.target.value)) }
      >
        <MenuItem value="">
          <em>{ strings.genericWords.none }</em>
        </MenuItem>
        {
          options.map(({ label, value }, index) => (
            <MenuItem key={ index } value={ value }>{ label }</MenuItem>
          ))
        }
      </Select>
    </div>
  );
};

const Styled = withStyles(styles)(Dropdown);
const CustomStyled = withCustomStyles("generic/dropdown")(Styled);

export default CustomStyled;