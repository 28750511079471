import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    flex: 1,
    flexGrow: 1,
    maxWidth: 1200,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 25
  },

  heading: {
    fontWeight: 800,
    color: theme.palette.text.primary,
    marginBottom: 20
  },

  subheading: {
    maxWidth: "65ch",
    marginBottom: 20
  },

  description: {
    maxWidth: "65ch",
    whiteSpace: "pre-line"
  },

  loaderContainer: {
    width: "100%",
    height: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }

});