import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    display: "flex",
  },

  input: {
    flex: 1,
    color: theme.palette.text.primary
  },

});