import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/layouts/app-layout";
import Header, { OptionalProps as HeaderProps } from "../generic/header";
import SubHeader, { OptionalProps as SubHeaderProps } from "../generic/sub-header";
import Footer, { OptionalProps as FooterProps } from "../generic/footer";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  headerProps?: HeaderProps;
  subHeaderProps?: SubHeaderProps;
  footerProps?: FooterProps;
  customStyles?: CustomStyles;
}

/**
 * App layout component
 *
 * @param props component props
 */
const AppLayout: React.FC<Props> = ({
  classes,
  customStyles,
  children,
  headerProps,
  subHeaderProps,
  footerProps
}) => {
  return (
    <div className={ classes.root }>
      <Header { ...headerProps }/>
      <SubHeader { ...subHeaderProps }/>
      <div
        className={ classes.content }
        style={ customStyles?.content }
      >
        { children }
      </div>
      <Footer { ...footerProps }/>
    </div>
  );
}

const Styled = withStyles(styles)(AppLayout);
const CustomStyled = withCustomStyles("layouts/app-layout")(Styled);

export default CustomStyled;