import * as React from "react";
import { withStyles, WithStyles, List, Typography } from "@material-ui/core";
import styles from "../../styles/resources/resources-lister";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { InlineResponse2002 as ResourceResponse, InlineResponse2007 as ReservationResponse } from "../../generated/client";
import ReservationCard from "../generic/reservation-card";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  customStyles?: CustomStyles;
  resourceResponse?: ResourceResponse;
  reservationResponse?: ReservationResponse;
  onCancelReservation: (id: string) => () => void;
}

/**
 * Reservations lister component
 *
 * @param props component props
 */
const ReservationsLister: React.FC<Props> = props => {
  const {
    locale,
    classes,
    customStyles,
    resourceResponse,
    onCancelReservation,
    reservationResponse,
  } = props;

  /**
   * Renders reservations
   */
  const renderReservations = () => {
    if (!resourceResponse || !reservationResponse) {
      return null;
    }

    const resources = resourceResponse.results;
    const reservations = reservationResponse.results;

    if (!resources || !reservations) {
      return null;
    }

    if (reservations.length < 1) {
      return (
        <Typography
          variant="h5"
          className={ classes.noReservationsText }
          style={ customStyles?.noReservationsText }
        >
          { strings.reservation.noReservations }
        </Typography>
      );
    }

    return reservations.map(reservation => {
      const resource = resources.find(item => item.id === reservation.resource);

      if (!resource || !reservation) {
        return null;
      }

      return (
        <ReservationCard
          locale={ locale }
          resource={ resource }
          key={ reservation.id }
          reservation={ reservation }
          onCancelReservation={ onCancelReservation }
        />
      );
    });
  };

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <List>
        { renderReservations() }
      </List>
    </div>
  );
};

const Styled = withStyles(styles)(ReservationsLister);
const CustomStyled = withCustomStyles("generic/reservations-lister")(Styled);

export default CustomStyled;