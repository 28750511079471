/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CancelReasonCategory,
    CancelReasonCategoryFromJSON,
    CancelReasonCategoryFromJSONTyped,
    CancelReasonCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CancelReasonRead
 */
export interface CancelReasonRead {
    /**
     * Free description of reason of reservations cancellation
     * @type {string}
     * @memberof CancelReasonRead
     */
    description?: string;
    /**
     * 
     * @type {CancelReasonCategory}
     * @memberof CancelReasonRead
     */
    category?: CancelReasonCategory;
}

export function CancelReasonReadFromJSON(json: any): CancelReasonRead {
    return CancelReasonReadFromJSONTyped(json, false);
}

export function CancelReasonReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelReasonRead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'category': !exists(json, 'category') ? undefined : CancelReasonCategoryFromJSON(json['category']),
    };
}

export function CancelReasonReadToJSON(value?: CancelReasonRead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'category': CancelReasonCategoryToJSON(value.category),
    };
}


