/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    Unit,
    UnitFromJSON,
    UnitToJSON,
} from '../models';

export interface UnitGetRequest {
    resourceGroup?: string;
    page?: number;
    pageSize?: number;
    unitHasResource?: boolean;
    include?: string;
}

export interface UnitIdGetRequest {
    id: string;
}

/**
 * 
 */
export class UnitApi extends runtime.BaseAPI {

    /**
     * The unit endpoint returns units (libraries, youth centers etc.) listed in the reservation system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async unitGetRaw(requestParameters: UnitGetRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.resourceGroup !== undefined) {
            queryParameters['resource_group'] = requestParameters.resourceGroup;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.unitHasResource !== undefined) {
            queryParameters['unit_has_resource'] = requestParameters.unitHasResource;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/unit/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * The unit endpoint returns units (libraries, youth centers etc.) listed in the reservation system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async unitGet(requestParameters: UnitGetRequest): Promise<InlineResponse2001> {
        const response = await this.unitGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The unit endpoint returns units (libraries, youth centers etc.) listed in the reservation system.
     */
    async unitIdGetRaw(requestParameters: UnitIdGetRequest): Promise<runtime.ApiResponse<Unit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unitIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/unit/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitFromJSON(jsonValue));
    }

    /**
     * The unit endpoint returns units (libraries, youth centers etc.) listed in the reservation system.
     */
    async unitIdGet(requestParameters: UnitIdGetRequest): Promise<Unit> {
        const response = await this.unitIdGetRaw(requestParameters);
        return await response.value();
    }

}
