import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {

  },

  list: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around"
  },

  listItem: {
    backgroundColor: "#757575",
    color: "white"
  },
});
