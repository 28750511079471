/**
 * Utility class for site
 */
export class SiteUtils {

  /**
   * Sets site favicon
   *
   * @param url favicon url
   */
  public static setFavicon = (url: string) => {
    let link: HTMLLinkElement | null = (
      document.querySelector('link[rel="shortcut icon"]') ??
      document.querySelector('link[rel="icon"]')
    );

    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }

    link.href = url;
  }

}
