import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    position: "relative"
  },

  checkBox: {
    color: theme.palette.primary.main
  },

  available: {
    color: theme.palette.primary.contrastText
  },

  unavailable: {
    opacity: "75%"
  },

  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "transparent",
    transition: "background-color 0.2s ease-in-out"
  },

  visible: {
    backgroundColor: theme.palette.action.disabledBackground
  },

  timeText: {
    paddingLeft: 10
  },

  reservationText: {
    marginLeft: "auto",
    paddingRight: 10
  },

  dataContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-colorSecondary.Mui-checked" : {
      color: theme.palette.primary.main
    }
  }

});