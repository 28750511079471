import * as React from "react";

import { Container, Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/footer";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, PublicContent } from "../../types";
import strings from "../../localization/strings";
import { Link } from "react-router-dom";
import config from "../../config";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Functional component for app footer
 *
 * @param props component props
 */
const Footer: React.FC<Props & OptionalProps> = ({
  classes,
  customStyles
}) => {
  const [ logo, setLogo ] = React.useState<string | undefined>();
  const [ publicContent, setPublicContent ] = React.useState<PublicContent>();

  /**
   * Imports logo after component mounts
   */
  React.useEffect(() => {
    const logoPath = "resources/svg/logo-white.svg";
    import(`../../dynamic-content/${logoPath}`)
      .then(logo => setLogo(logo.default))
      .catch(() => {});

    config.getPublicContent().then(setPublicContent);
  }, []);

  /**
   * Component render
   */
  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <Container
        className={ classes.container }
        style={ customStyles?.container }
      >
        <Grid
          container
          justify="center"
          spacing={ 2 }
          className={ classes.grid }
          style={ customStyles?.grid }
        >
          <Grid
            item
            xs={ 12 }
            md={ 4 }
            className={ classes.firstColumn }
            style={ customStyles?.firstColumn }
          >
            { logo &&
              <img
                alt="footer logo"
                src={ logo }
                className={ classes.logo }
                style={ customStyles?.logo }
              />
            }
            <Typography
              variant="h5"
              className={ classes.appTitleText }
              style={ customStyles?.appTitleText }
            >
              { strings.app.title }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 }
            md={ 4 }
            className={ classes.secondColumn }
            style={ customStyles?.secondColumn }
          >
            { strings.formatString(strings.app.footerDescription, "") }
          </Grid>
          <Grid
            item
            xs={ 12 }
            md={ 4 }
            className={ classes.thirdColumn }
            style={ customStyles?.thirdColumn }
          >
            { publicContent?.accessibilityStatement &&
              <Link
                tabIndex={ 0 }
                className={ classes.footerLink }
                style={ customStyles?.footerLink }
                to={ "/accessibility-statement" }
              >
                { strings.mainMenu.accessibilityStatement }
              </Link>
            }
            { publicContent?.privacyPolicy &&
              <Link
                tabIndex={ 1 }
                className={ classes.footerLink }
                style={ customStyles?.footerLink }
                to={ "/privacy-policy" }
              >
                { strings.mainMenu.privacyPolicy }
              </Link>
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const Styled = withStyles(styles)(Footer);
const CustomStyled = withCustomStyles("generic/footer")(Styled);

export default CustomStyled;
