import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: { },

  headerContainer: {
    minHeight: 70,
    display: "flex",
    color: "#ffffff",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      minHeight: 50
    }
  },

  timeText: {
    paddingLeft: 10
  },

  reservationText: {
    marginLeft: "auto",
    paddingRight: 10
  },

  loaderContainer: {
    width: "100%",
    height: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  noReservationItems: {
    marginTop: 25,
    textAlign: "center",
  },

});