import * as React from "react";
import { withStyles, WithStyles, List, Typography } from "@material-ui/core";
import styles from "../../styles/resources/resource-reservation-lister";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, SelectedDateHolder } from "../../types";
import ReservedItem from "../reservations/reserved-item";
import strings from "../../localization/strings";
import { Reservation } from "../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  selectedDates: SelectedDateHolder;
  reservations: Reservation[];
  customStyles?: CustomStyles;
  locale: string;
}

/**
 * Resource reservation lister component
 *
 * @param props component props
 */
const ResourcesReservationLister: React.FC<Props> = ({
  classes,
  reservations,
  locale,
  customStyles
}) => {
  if (reservations.length < 1) {
    return null;
  }

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <Typography
        className={ classes.cannotReserveText }
        style={ customStyles?.cannotReserveText }
      >
        { strings.reservation.cannotReserve }
      </Typography>
      <List>
        { reservations.map((reservation, index) =>
          <ReservedItem
            key={ index }
            locale={ locale }
            reservation={ reservation }
          />
        )}
      </List>
    </div>
  );
};

const Styled = withStyles(styles)(ResourcesReservationLister);
const CustomStyled = withCustomStyles("generic/resource-reservation-lister")(Styled);

export default CustomStyled;
