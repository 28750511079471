import * as React from "react";
import { withStyles, WithStyles, List, Typography } from "@material-ui/core";
import styles from "../../styles/resources/resources-lister";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { InlineResponse2002 as ResourceResponse } from "../../generated/client";
import ResourceCard from "../generic/resource-card";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  resourceResponse?: ResourceResponse;
  customStyles?: CustomStyles;
  locale: string;
}

/**
 * Resources lister component
 *
 * @param props component props
 */
const ResourcesLister: React.FC<Props> = props => {
  const { classes, resourceResponse, locale, customStyles } = props;

  /**
   * Renders resources
   */
  const renderResources = () => {
    if (!resourceResponse) {
      return null;
    }

    const resources = resourceResponse.results;
    if (!resources || resources.length < 1) {
      return (
        <Typography
          variant="h5"
          className={ classes.noResultsText }
          style={ customStyles?.noResultsText }
        >
          { strings.generic.resourcesLister.noResults }
        </Typography>
      );
    }

    return resources.map(resource => (
      <ResourceCard
        key={ resource.id }
        locale={ locale }
        resource={ resource }
      />
    ));
  };

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <List>
        { renderResources() }
      </List>
    </div>
  );
};

const Styled = withStyles(styles)(ResourcesLister);
const CustomStyled = withCustomStyles("generic/resources-lister")(Styled);

export default CustomStyled;