import * as React from "react";

import { Card, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/purpose-card";

import { Purpose, PurposeName } from "../../generated/client";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import { Link } from 'react-router-dom';

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  purpose: Purpose;
  customStyles?: CustomStyles;
}

/**
 * Top bar component
 *
 * @param props component props
 */
const PurposeCard: React.FC<Props> = props => {
  const { classes, locale, purpose, customStyles } = props;

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <Link
        tabIndex={ 0 }
        className={ classes.link }
        style={ customStyles?.link }
        to={ `purposes/${ purpose.id }` }
      >
        <Card
          variant="outlined"
          className={ classes.card }
          style={ customStyles?.card }
        >
          <CardContent
            className={ classes.cardContent }
            style={ customStyles?.cardContent }
          >
            <Typography
              className={ classes.cardName }
              style={ customStyles?.cardName }
            >
              { purpose.name ? purpose.name[locale as keyof PurposeName] : "" }
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

const Styled = withStyles(styles)(PurposeCard);
const CustomStyled = withCustomStyles("generic/purpose-card")(Styled);

export default CustomStyled;