import { createStyles } from "@material-ui/core";

export default createStyles({

  container: {
    marginTop: 15
  },

  reservationTime: {

  },

  reservedText: {
    color: "red"
  },

  dataContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }

});