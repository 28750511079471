/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    Purpose,
    PurposeFromJSON,
    PurposeToJSON,
    Type,
    TypeFromJSON,
    TypeToJSON,
} from '../models';

export interface PurposeGetRequest {
    page?: number;
    pageSize?: number;
}

export interface PurposeIdGetRequest {
    id: string;
}

export interface TypeGetRequest {
    resourceGroup?: string;
    page?: number;
    pageSize?: number;
}

export interface TypeIdGetRequest {
    id: string;
}

/**
 * 
 */
export class FilterApi extends runtime.BaseAPI {

    /**
     * The purpose endpoint returns the possible resource usage purposes registered in the system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 50 purposes per page.
     */
    async purposeGetRaw(requestParameters: PurposeGetRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purpose/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * The purpose endpoint returns the possible resource usage purposes registered in the system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 50 purposes per page.
     */
    async purposeGet(requestParameters: PurposeGetRequest): Promise<InlineResponse2002> {
        const response = await this.purposeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The purpose endpoint returns the possible resource usage purposes registered in the system.
     */
    async purposeIdGetRaw(requestParameters: PurposeIdGetRequest): Promise<runtime.ApiResponse<Purpose>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purposeIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purpose/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PurposeFromJSON(jsonValue));
    }

    /**
     * The purpose endpoint returns the possible resource usage purposes registered in the system.
     */
    async purposeIdGet(requestParameters: PurposeIdGetRequest): Promise<Purpose> {
        const response = await this.purposeIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The type endpoint returns the possible resource types registered in the system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async typeGetRaw(requestParameters: TypeGetRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.resourceGroup !== undefined) {
            queryParameters['resource_group'] = requestParameters.resourceGroup;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * The type endpoint returns the possible resource types registered in the system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async typeGet(requestParameters: TypeGetRequest): Promise<InlineResponse2003> {
        const response = await this.typeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The type endpoint returns the possible resource types registered in the system.
     */
    async typeIdGetRaw(requestParameters: TypeIdGetRequest): Promise<runtime.ApiResponse<Type>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling typeIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeFromJSON(jsonValue));
    }

    /**
     * The type endpoint returns the possible resource types registered in the system.
     */
    async typeIdGet(requestParameters: TypeIdGetRequest): Promise<Type> {
        const response = await this.typeIdGetRaw(requestParameters);
        return await response.value();
    }

}
