import * as React from "react";

import { Card, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/resource-card";

import { ImageTypeEnum, LocalizedValue, Resource } from "../../generated/client";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import { useHistory } from "react-router-dom";
import * as ResourceUtils from "../../utils/resource-utils";
import ResourceDetails from "../resources/resource-details";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  resource: Resource;
  customStyles?: CustomStyles;
}

/**
 * Resource card component
 *
 * @param props component props
 */
const ResourceCard: React.FC<Props> = props => {
  const { classes, locale, resource, customStyles } = props;
  const history = useHistory();

  /**
   * Event handler for resource click
   */
  const onResourceClick = () => {
    history.push(`/resources/${resource.id}`);
  };

  /**
   * Returns main image data from resource if found
   */
  const getMainImageData = () => {
    return resource?.images?.find(image => image.type === ImageTypeEnum.Main);
  }

  /**
   * Returns localized alt text for resource image
   * 
   * @param imageCaption caption from resource image
   */
  const getImageAlt = (imageCaption?: LocalizedValue) => {
    if (!imageCaption) {
      return strings.generic.imageAlt;
    }

    return (
      imageCaption[locale as keyof LocalizedValue] ??
      imageCaption?.fi ??
      strings.generic.imageAlt
    );
  }

  /**
   * Component render
   */
  const imageData = getMainImageData();
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <Card
        tabIndex={ 0 }
        className={ classes.card }
        style={ customStyles?.card }
        onClick={ onResourceClick }
        onKeyPress={event => {
          if (event.key === 'Enter' || event.key === 'Spacebar') {
            onResourceClick();
          }
        }}
      >
        <CardContent
          className={ classes.cardContent }
          style={ customStyles?.cardContent }
        >
          <div
            className={ classes.cardImageContainer }
            style={ customStyles?.cardImageContainer }
          >
            <img
              src={ imageData?.url }
              alt={ getImageAlt(imageData?.caption) }
              className={ classes.cardImage }
              style={ customStyles?.cardImage }
            />
          </div>
          <div
            className={ classes.cardText }
            style={ customStyles?.cardText }
          >
            <Typography
              variant="h5"
              color="textSecondary"
              className={ classes.cardName }
              style={ customStyles?.cardName }
            >
              { resource.name && ResourceUtils.getLocalizedProperty(resource.name, locale) }
            </Typography>
            <Typography
              color="textSecondary"
              className={ classes.cardDescription }
              style={ customStyles?.cardDescription }
            >
              { resource.description && ResourceUtils.getLocalizedProperty(resource.description, locale) }
            </Typography>
          </div>
          <div
            className={ classes.cardDetails }
            style={ customStyles?.cardDetails }
          >
            <ResourceDetails
              locale={ locale }
              resource={ resource }
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const Styled = withStyles(styles)(ResourceCard);
const CustomStyled = withCustomStyles("generic/resource-card")(Styled);

export default CustomStyled;