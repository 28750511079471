import * as React from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../store";

/**
 * Interface describing component properties
 */
interface Props {
  component?: React.ReactNode;
  children?: (authorized: boolean) => React.ReactNode;
}

/**
 * Wrapper component for limiting accessibility to authorized users only
 * Can be used two ways:
 * - to hide a component when user is not authorized by setting component property
 * - to create custom render logic by giving a callback render function as child, which exposes authorized state as a parameter
 * 
 * @param props component properties
 */
const AuthorizedOnly: React.FC<Props> = ({ component, children }) => {
  const accessToken = useSelector((state: ReduxState) => state.auth.accessToken);

  if (component) {
    return <>{ accessToken ? component : null }</>;
  }

  return <>{ children && children(!!accessToken) }</>;
}

export default AuthorizedOnly;