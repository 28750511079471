import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  root: {
    color: theme.palette.primary.main,
    "& .Mui-checked": {
      color: theme.palette.primary.main
    }
  },

  checkbox: {
    color: theme.palette.primary.main,
  }

});