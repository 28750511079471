/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
    InlineResponse400,
    InlineResponse400FromJSON,
    InlineResponse400ToJSON,
    InlineResponse4001,
    InlineResponse4001FromJSON,
    InlineResponse4001ToJSON,
    Reservation,
    ReservationFromJSON,
    ReservationToJSON,
    OrderCreationResponse,
    OrderCreationResponseFromJSON,
    OrderJustId,
    OrderJustIdFromJSON
} from '../models';

export interface ReservationGetRequest {
    page?: number;
    pageSize?: number;
    resource?: string;
    all?: boolean;
    needManualConfirmation?: boolean;
    reserverInfoSearch?: string;
    state?: string;
    canApprove?: boolean;
    resourceGroup?: string;
    eventSubject?: string;
    hostName?: string;
    reserverId?: string;
    reserverName?: string;
    resourceName?: string;
    unit?: string;
    start?: string;
    end?: string;
    hasCateringOrder?: boolean;
    isFavoriteResource?: boolean;
    user?: string;
    isOwn?: boolean;
    include?: string;
}

export interface ReservationIdDeleteRequest {
    id: string;
}

export interface ReservationIdGetRequest {
    id: string;
}

export interface ReservationIdPatchRequest {
    id: string;
    uNKNOWNBASETYPE?: any;
}

export interface ReservationIdPutRequest {
    reservation: Reservation;
    id: string;
}

export interface ReservationPostRequest {
    reservation: Reservation;
}

/**
 * 
 */
export class ReservationApi extends runtime.BaseAPI {

    /**
     * The reservation endpoint returns reservations listed in the reservation system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async reservationGetRaw(requestParameters: ReservationGetRequest): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.resource !== undefined) {
            queryParameters['resource'] = requestParameters.resource;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.needManualConfirmation !== undefined) {
            queryParameters['need_manual_confirmation'] = requestParameters.needManualConfirmation;
        }

        if (requestParameters.reserverInfoSearch !== undefined) {
            queryParameters['reserver_info_search'] = requestParameters.reserverInfoSearch;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.canApprove !== undefined) {
            queryParameters['can_approve'] = requestParameters.canApprove;
        }

        if (requestParameters.resourceGroup !== undefined) {
            queryParameters['resource_group'] = requestParameters.resourceGroup;
        }

        if (requestParameters.eventSubject !== undefined) {
            queryParameters['event_subject'] = requestParameters.eventSubject;
        }

        if (requestParameters.hostName !== undefined) {
            queryParameters['host_name'] = requestParameters.hostName;
        }

        if (requestParameters.reserverId !== undefined) {
            queryParameters['reserver_id'] = requestParameters.reserverId;
        }

        if (requestParameters.reserverName !== undefined) {
            queryParameters['reserver_name'] = requestParameters.reserverName;
        }

        if (requestParameters.resourceName !== undefined) {
            queryParameters['resource_name'] = requestParameters.resourceName;
        }

        if (requestParameters.unit !== undefined) {
            queryParameters['unit'] = requestParameters.unit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.hasCateringOrder !== undefined) {
            queryParameters['has_catering_order'] = requestParameters.hasCateringOrder;
        }

        if (requestParameters.isFavoriteResource !== undefined) {
            queryParameters['is_favorite_resource'] = requestParameters.isFavoriteResource;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.isOwn !== undefined) {
            queryParameters['is_own'] = requestParameters.isOwn;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservation/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     * The reservation endpoint returns reservations listed in the reservation system. The optional parameter **page** allows specifying page number and **page_size** allows specifying more than the default 20 units per page.
     */
    async reservationGet(requestParameters: ReservationGetRequest): Promise<InlineResponse2007> {
        const response = await this.reservationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The reservation endpoint allows deleting existing reservations.
     */
    async reservationIdDeleteRaw(requestParameters: ReservationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reservationIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The reservation endpoint allows deleting existing reservations.
     */
    async reservationIdDelete(requestParameters: ReservationIdDeleteRequest): Promise<void> {
        await this.reservationIdDeleteRaw(requestParameters);
    }

    /**
     * The reservation endpoint returns reservations listed in the reservation system.
     */
    async reservationIdGetRaw(requestParameters: ReservationIdGetRequest): Promise<runtime.ApiResponse<Reservation & OrderJustId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reservationIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     * The reservation endpoint returns reservations listed in the reservation system.
     */
    async reservationIdGet(requestParameters: ReservationIdGetRequest): Promise<Reservation & OrderJustId> {
        const response = await this.reservationIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The reservation endpoint allows changing state of reservation with a reason message
     */
    async reservationIdPatchRaw(requestParameters: ReservationIdPatchRequest): Promise<runtime.ApiResponse<Reservation & OrderJustId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reservationIdPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: "",
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     * The reservation endpoint allows changing state of reservation with a reason message
     */
    async reservationIdPatch(requestParameters: ReservationIdPatchRequest): Promise<Reservation & OrderJustId> {
        const response = await this.reservationIdPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * The reservation endpoint allows editing existing reservations.
     */
    async reservationIdPutRaw(requestParameters: ReservationIdPutRequest): Promise<runtime.ApiResponse<Reservation & OrderJustId>> {
        if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
            throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationIdPut.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reservationIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationToJSON(requestParameters.reservation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     * The reservation endpoint allows editing existing reservations.
     */
    async reservationIdPut(requestParameters: ReservationIdPutRequest): Promise<Reservation & OrderJustId> {
        const response = await this.reservationIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * The reservation endpoint accepts reservations.
     */
    async reservationPostRaw(requestParameters: ReservationPostRequest): Promise<runtime.ApiResponse<Reservation & OrderCreationResponse>> {
        if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
            throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';
        headerParameters['Accept'] = 'application/json';

        const response = await this.request({
            path: `/reservation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationToJSON(requestParameters.reservation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     * The reservation endpoint accepts reservations.
     */
    async reservationPost(requestParameters: ReservationPostRequest): Promise<Reservation & OrderCreationResponse> {
        const response = await this.reservationPostRaw(requestParameters);
        return await response.value();
    }

}
