import * as React from "react";

import { ReduxActions, ReduxState } from "../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { AppBar, Button, Toolbar, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/header";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { NullableToken, CustomStyles } from "../../types";
import strings from "../../localization/strings";
import { KeycloakInstance } from "keycloak-js";
// import { setLocale } from "../../actions/locale";
// import LanguageIcon from '@material-ui/icons/Language';

/**
 * Optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  locale: string;
  // setLocale: typeof setLocale;
  keycloak?: KeycloakInstance;
  accessToken?: NullableToken;
}

/**
 * Top bar component
 *
 * @param props component props
 */
const TopBar: React.FC<Props & OptionalProps> = ({
  classes,
  customStyles,
  keycloak,
  accessToken,
}) => {
  const [ logo, setLogo ] = React.useState<string | undefined>();

  /**
   * Imports logo after component mounts
   */
  React.useEffect(() => {
    const logoPath = "resources/svg/logo.svg";
    import(`../../dynamic-content/${logoPath}`)
      .then(logo => setLogo(logo.default))
      .catch(() => {})
  }, []);

  // On the first phase customer doesn't need language selection. Comment this out when they need it
  // /**
  //  * Renders language options
  //  */
  // const renderLanguageOptions = () => {
  //   return strings.getAvailableLanguages()
  //     .map(language =>
  //       <MenuItem
  //         key={ language }
  //         value={ language }
  //         className={ classes.menuItem }
  //         style={ customStyles?.menuItem }
  //       >
  //         { language }
  //       </MenuItem>
  //     );
  // }

  /**
   * Renders user auth action
   */
  const renderAuthAction = () => {
    const label = accessToken ?
      strings.auth.logout :
      strings.auth.login;

    return (
      <Button
        variant="text"
        className={ classes.button }
        style={ customStyles?.button }
        onClick={ () => {
          if (accessToken) {
            if (keycloak) {
              keycloak.logout();
            }
          } else {
            if (keycloak) {
              keycloak.login({idpHint: "oidc"});
            }
          }
        }
        }
      >
        { label }
      </Button>
    );
  }

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <AppBar
        position="static"
        className={ classes.appBar }
        style={ customStyles?.appBar }
      >
        <Toolbar
          className={ classes.toolBar }
          style={ customStyles?.toolBar }
        >
          <img
            alt="Kouvola logo"
            src={ logo }
            className={ classes.logo }
            style={ customStyles?.logo }
          />
          {/* On the first phase customer doesn't need language selection. Comment this out when they need it */}
          {/* <TextField
            select
            className={ classes.selectRoot }
            style={ customStyles?.selectRoot }
            value={ strings.getLanguage() }
            onChange={ event => setLocale(event.target.value as string) }
            InputProps={{
              className: classes.selectInput,
            }}
            SelectProps={{
              className: classes.select,
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <LanguageIcon/>
                </InputAdornment>
              )
            }}
          >
            { renderLanguageOptions() }
          </TextField> */}
          { renderAuthAction() }
        </Toolbar>
      </AppBar>
    </div>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
function mapStateToProps(state: ReduxState) {
  return {
    locale: state.locale.locale,
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
    // setLocale: (locale: string) => dispatch(setLocale(locale))
  };
}

const Styled = withStyles(styles)(TopBar);
const CustomStyled = withCustomStyles("generic/header")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;