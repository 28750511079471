import * as React from "react";

import { CustomStyles } from "../../types";
import styles from "../../styles/generic/confirm-dialog";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles, WithStyles, FormGroup, FormControlLabel, Checkbox, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import strings from "../../localization/strings";
import { LocalizedValue } from "../../generated/client";

/**
 * Interface representing component properties
 */
interface Props extends WithStyles<typeof styles> {
  title: string;
  customStyles?: CustomStyles;
  confirmButtonText: string;
  cancelButtonText: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  needsConfirmation?: boolean;
  open: boolean;
  locale?: string;
  termsOfUse?: LocalizedValue;
  formValid?: boolean;
}

/**
 * React component displaying confirm dialogs
 */
const ConfirmDialog: React.FC<Props> = ({
  classes,
  children,
  title,
  customStyles,
  confirmButtonText,
  cancelButtonText,
  onClose,
  onCancel,
  onConfirm,
  needsConfirmation,
  open,
  locale,
  termsOfUse,
  formValid
}) => {

  /**
   * Returns localized terms of use
   */
  const getLocalizedTerms = () => {
    if (!termsOfUse) {
      return;
    }

    if (!locale) {
      return termsOfUse.fi;
    }

    return termsOfUse[locale as keyof LocalizedValue] ?? termsOfUse.fi;
  }

  /**
   * Toggles terms of use dialog
   */
  const toggleTermsOfUse = () => {
    setTermsOfUseOpen(!termsOfUseOpen);
  }

  /**
   * Toggles confirm state of terms of use
   */
  const toggleConfirm = () => {
    setConfirmed(!confirmed);
  }

  /**
   * Renders confirm terms of use checkbox and link to terms of use
   */
  const renderConfirmTermsOfUse = () => {
    return (
      <div className={ classes.termsOfUseContainer }>
        <FormGroup
          row
          className={ classes.confirmFormGroup }
          style={ customStyles?.confirmFormGroup }
        >
          <FormControlLabel
            label={ strings.reservation.confirmDialog.approve }
            className={ classes.text }
            style={ customStyles?.text }
            control={
              <Checkbox
                checked={ confirmed }
                onChange={ toggleConfirm }
                color="primary"
                classes={{
                  root: classes.confirmCheckboxRoot
                }}
              />
            }
          />
        </FormGroup>
        <Typography
          tabIndex={ 0 }
          className={ classes.termsOfUseLink }
          style={ customStyles?.termsOfUseLink }
          onClick={ toggleTermsOfUse }
          onKeyPress={event => {
            if (event.key === 'Enter' || event.key === 'Spacebar') {
              toggleTermsOfUse();
            }
          }}
        >
          { strings.reservation.confirmDialog.rules }
        </Typography>
      </div>
    );
  }

  /**
   * Renders terms of use dialog
   */
  const renderTermsOfUseDialog = () => {
    return (
      <Dialog
        open={ termsOfUseOpen }
        fullScreen={ isMobileScreen }
        className={ classes.termsOfUseDialogRoot }
        style={ customStyles?.termsOfUseDialog }
        PaperProps={{
          className: classes.termsOfUseDialogPaper,
          style: customStyles?.termsOfUseDialogPaper
        }}
        scroll="paper"
        onClose={ toggleTermsOfUse }
      >
        <DialogContentText
          className={ classes.termsOfUseDialogContent }
          style={ customStyles?.termsOfUseDialogContent }
        >
          { getLocalizedTerms() }
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={ toggleTermsOfUse }
            className={ classes.cancelButton }
            style={ customStyles?.cancelButton }
          >
            { strings.actions.close }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Renders action buttons
   */
  const renderActionButtons = () => {
    return (
      <div className={ classes.actionButtonsContainer }>
        { onCancel &&
          <Button
            disableElevation
            variant="contained"
            onClick={ onCancel }
            className={ classes.cancelButton }
            style={ customStyles?.cancelButton }
          >
            { cancelButtonText }
          </Button>
        }
        { onConfirm &&
          <Button
            disableElevation
            variant="contained"
            onClick={ onConfirm }
            disabled={ isConfirmDisabled() }
            autoFocus
            className={ classes.confirmButton }
            style={ !isConfirmDisabled() ? customStyles?.confirmButton : {} }
          >
            { confirmButtonText }
          </Button>
        }
      </div>
    );
  }

  /**
   * Method that checks whether confirm button should be disabled or not
   */
  const isConfirmDisabled = () => {
    return (needsConfirmation && !confirmed) || (formValid !== undefined && !formValid);
  }

  const [ confirmed, setConfirmed ] = React.useState(false);
  const [ termsOfUseOpen, setTermsOfUseOpen ] = React.useState(false);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Dialog
        open={ open }
        maxWidth="md"
        fullScreen={ isMobileScreen }
        onClose={ onClose }
        className={ classes.root }
        style={ customStyles?.root }
        PaperProps={{
          className: classes.dialog,
          style: customStyles?.dialog
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className={ classes.title }
          style={ customStyles?.title }
        >
          { title }
        </DialogTitle>
        <DialogContent>
          { children }
        </DialogContent>
        <DialogActions
          className={ classes.dialogActions }
          style={ customStyles?.dialogActions }
        >
          { termsOfUse &&
            <div className={ classes.dialogActionsRow }>
              <Typography>
                { strings.reservation.addDialog.acceptStoringUserInfo }
              </Typography>
            </div>
          }
          <div className={ classes.dialogActionsRow }>
            { termsOfUse && renderConfirmTermsOfUse() }
            { renderActionButtons() }
          </div>
        </DialogActions>
      </Dialog>
      { termsOfUse &&
        renderTermsOfUseDialog()
      }
    </>
  );
}

const Styled = withStyles(styles)(ConfirmDialog);
const CustomStyled = withCustomStyles("generic/confirm-dialog")(Styled);

export default CustomStyled;
