import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {},

  infoContainer: {
    width: "100%",
    display: "flex",
    padding: "0 25px",
    marginTop: 25,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },

  descriptionContainer: {
    width: "70%",
    marginBottom: 25,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  title: {
    fontSize: 25,
    marginBottom: 20
  },

  description: { },

  detailsContainer: {
    width: "30%",
    marginBottom: 25,
    paddingLeft: 25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 10
    }
  },

  equipmentContainer: {
    width: "100%",
    padding: "0 25px",
    marginBottom: 25
  },

  equipment: { },

  equipmentTitle: {
    marginTop: 10
  },

  equipmentName: {
    fontWeight: "bold"
  },

  equipmentDescription: { },

  imageContainer: {
    width: "100%",
    overflow: "hidden",
    marginBottom: 25
  },

  resourceImage: {
    width: "100%",
  },

  reservationInfoCard: {
    marginTop: 10,
    width: "fit-content"
  },

  reservationInfo: {
    padding: 15,
    whiteSpace: "pre-line"
  }

});
