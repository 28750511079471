/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Price data of the product. Has always at least `type` field. Existence of other fields depends on the type.
 * @export
 * @interface ProductPrice
 */
export interface ProductPrice {
    /**
     * - `fixed`: The price stays always the same regardless of the reservation, so if `amount` is `10.00` the final price is 10.00 EUR.
     *    Other price fields present with this type: `tax_percentage` and `amount`.
     * - `per_period`: When price type is `per_period`, field `period` contains length of the period, for example if `amount` is `10.00` and `period` is `00:30:00` it means the actual price is 10.00 EUR / 0.5h.
     *    Other price fields present with this type: `tax_percentage`, `amount` and `period.
     * @type {string}
     * @memberof ProductPrice
     */
    type?: ProductPriceTypeEnum;
    /**
     * Present when `type` is `fixed` or `per_period`.
     * @type {string}
     * @memberof ProductPrice
     */
    taxPercentage?: ProductPriceTaxPercentageEnum;
    /**
     * The product's price in euros. However, there are different ways the value should be interpreted depending on `type` field's value.
     * Present when `type` is `fixed` or `per_period`.
     * @type {string}
     * @memberof ProductPrice
     */
    amount?: string;
    /**
     * When `type` is `per_period`, this field contains the period's duration, format hh:mm:ss.
     * Present when `type` is `per_period`.
     * @type {string}
     * @memberof ProductPrice
     */
    period?: string;
}

export function ProductPriceFromJSON(json: any): ProductPrice {
    return ProductPriceFromJSONTyped(json, false);
}

export function ProductPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'taxPercentage': !exists(json, 'tax_percentage') ? undefined : json['tax_percentage'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'period': !exists(json, 'period') ? undefined : json['period'],
    };
}

export function ProductPriceToJSON(value?: ProductPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'tax_percentage': value.taxPercentage,
        'amount': value.amount,
        'period': value.period,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProductPriceTypeEnum {
    Fixed = 'fixed',
    PerPeriod = 'per_period'
}
/**
* @export
* @enum {string}
*/
export enum ProductPriceTaxPercentageEnum {
    _000 = '0.00',
    _1000 = '10.00',
    _1400 = '14.00',
    _2400 = '24.00'
}


