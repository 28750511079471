import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { withStyles, WithStyles, Typography } from "@material-ui/core";
import styles from "../../styles/screens/more-information-screen";
import AppLayout from "../layouts/app-layout";
import { CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import config from "../../config";
import HTMLParser, { domToReact } from "html-react-parser";
import { Variant } from "@material-ui/core/styles/createTypography";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Component for more information screen
 *
 * @param props component properties
 */
const MoreInformationScreen: React.FC<Props> = ({ classes, customStyles }) => {
  const [ moreInformation, setMoreInformation ] = React.useState<string>();
  /**
   * Effect that fetches public content
   */
  React.useEffect(() => {
    config.getPublicContent()
      .then(content => content && setMoreInformation(content?.moreInformationPage))
      .catch(console.warn);
  }, []);

  /**
   * Renders typography with given parameters
   *
   * @param variant typography variant
   * @param className typography className
   * @param children children
   * @param styles custom styles or undefined
   */
  const renderTypography = (
    variant: Variant,
    className: string,
    children: any,
    styles?: React.CSSProperties
  ) => {
    return (
      <Typography
        variant={ variant }
        className={ className }
        style={ styles }
      >
        { children }
      </Typography>
    );
  }

  /**
   * Replace method for html-react-parser
   *
   * @param domNode DOM node
   */
  const replace = (domNode: any) => {
    if (domNode.type === "tag") {
      switch (domNode.name) {
        case "h1":
          return renderTypography(
            "h4",
            classes.heading,
            domToReact(domNode.children),
            customStyles?.description
          );
        case "h2":
          return renderTypography(
            "h5",
            classes.subheading,
            domToReact(domNode.children),
            customStyles?.subheading
          );
        case "p":
          return renderTypography(
            "body1",
            classes.description,
            domToReact(domNode.children),
            customStyles?.description
          );
      }
    }
  }

  /**
   * Component render
   */
  return (
    <AppLayout>
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { HTMLParser(moreInformation || "", { replace }) }
      </div>
    </AppLayout>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  locale: state.locale.locale
});

const Styled = withStyles(styles)(MoreInformationScreen);
const CustomStyled = withCustomStyles("screens/more-information-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
