import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { CircularProgress, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/screens/resources-screen";
import AppLayout from "../layouts/app-layout";
import { NullableToken, BreadcrumbLink, CustomStyles } from "../../types";
import { InlineResponse2002 as ResourceResponse, PurposeName, Resource, Type, TypeName } from "../../generated/client";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import Breadcrumb from "../generic/breadcrumb";
import Filters from "../generic/filters";
import SearchBar from "../generic/search-bar";
import Pagination from "../generic/pagination";
import ResourcesLister from "../resources/resources-lister";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  purpose: string;
  type?: string;
  locale: string;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  count: number;
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  resourceResponse?: ResourceResponse;
  typeList: Type[];
  breadcrumbs: BreadcrumbLink[];
}

/**
 * Resources screen component
 */
class ResourcesScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      count: 1,
      pageNumber: 1,
      itemsPerPage: 5,
      searchString: "",
      typeList: [],
      breadcrumbs: []
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    await this.fetchResources();
    this.setState({ loading: false});
  }

  /**
   * Component did update life cycle handler
   */
  public componentDidUpdate = async (prevProps: Props, state: State) => {
    const { purpose, type, locale } = this.props;
    const {
      pageNumber,
      searchString,
      itemsPerPage
    } = this.state;

    if (
      (type !== prevProps.type) ||
      (purpose !== prevProps.purpose) ||
      (pageNumber !== state.pageNumber) ||
      (itemsPerPage !== state.itemsPerPage) ||
      (searchString !== state.searchString) ||
      (locale !== prevProps.locale)
    ) {
      this.setState({ loading: true});
      await this.fetchResources();
      this.setState({ loading: false});
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const {
      classes,
      locale,
      customStyles,
    } = this.props;
    const {
      loading,
      resourceResponse,
      count,
      pageNumber,
      itemsPerPage,
      typeList,
      breadcrumbs,
      searchString
    } = this.state;

    if (loading) {
      return (
        <AppLayout>
          <div
            className={ classes.container }
            style={ customStyles }
          >
          <Breadcrumb
            breadcrumbs={ breadcrumbs }
          />
          <SearchBar
            searchValue={ searchString }
            onChange={ this.changeSearchString }
          />
          <Filters
            types={ typeList }
            locale={ locale }
          />
            <div className={ classes.loaderContainer }>
              <CircularProgress size={ 50 } color="secondary"/>
            </div>
          </div>
        </AppLayout>
      );
    }

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles }
        >
          <Breadcrumb
            breadcrumbs={ breadcrumbs }
          />
          <SearchBar
            searchValue={ searchString }
            onChange={ this.changeSearchString }
          />
          <Filters
            types={ typeList }
            locale={ locale }
          />
          <ResourcesLister resourceResponse={ resourceResponse } locale={ locale } />
          { resourceResponse && resourceResponse.results && resourceResponse.results.length > 0 &&
            <Pagination
              count={ count }
              changePageNumber={ this.changePageNumber }
              pageNumber={ pageNumber }
              onChangeRowsPerPage={ this.changeRowsPerPage }
              itemsPerPage={ itemsPerPage }
            />
          }
        </div>
      </AppLayout>
    );
  }

  /**
   * Fetch resources from API
   */
  private fetchResources = async () => {
    const { accessToken, purpose, type } = this.props;
    const { pageNumber, itemsPerPage, searchString } = this.state;

    const breadcrumbs: BreadcrumbLink[] = [];
    breadcrumbs.push(await this.getPurposesBreadcrumb());
    breadcrumbs.push(await this.getTypesBreadcrumb());

    const resourceApi = Api.getResourceApi(accessToken);
    const resourceResponse = await resourceApi.resourceGet({
      pageSize: itemsPerPage,
      search: searchString,
      page: pageNumber,
      purpose: purpose,
      type: type,
    });
    const resultCount = resourceResponse.count;
    const count = resultCount ? resultCount : 1;
    const typeList = type ? [] : this.generateTypeList(resourceResponse.results || []);

    this.setState({
      count: count,
      resourceResponse: resourceResponse,
      typeList,
      breadcrumbs
    });
  }

  /**
   * Get purposes breadcrumbs
   *
   * @returns breadcrumbs link promise
   */
  private getPurposesBreadcrumb = async (): Promise<BreadcrumbLink> => {
    const { accessToken, purpose, locale } = this.props;
    const breadcrumbLink: BreadcrumbLink = {
      label: "",
      path: ""
    };

    const purposesApi = Api.getFilterApi(accessToken);
    const foundPurpose = await purposesApi.purposeIdGet({ id: purpose });
    if (!foundPurpose.name) {
      return breadcrumbLink;
    }

    breadcrumbLink.label =  foundPurpose.name[locale as keyof PurposeName] || strings.errorMessages.purposeNameMissing;
    breadcrumbLink.path = `/purposes/${purpose}`|| "";

    return breadcrumbLink;
  }

  /**
   * Get types breadcrumbs
   *
   * @returns breadcrumbs link promise
   */
  private getTypesBreadcrumb = async (): Promise<BreadcrumbLink> => {
    const { accessToken, purpose, type, locale } = this.props;

    const breadcrumbLink: BreadcrumbLink = {
      label: "",
      path: ""
    };

    if (!type) {
      return breadcrumbLink;
    }

    const purposesApi = Api.getFilterApi(accessToken);
    const foundType = await purposesApi.typeIdGet({ id: type });
    if (!foundType.name) {
      return breadcrumbLink;
    }

    breadcrumbLink.label =  foundType.name[locale as keyof TypeName] || strings.errorMessages.typeNameMissing;
    breadcrumbLink.path = `/purposes/${purpose}/types/${foundType.id}` || "";

    return breadcrumbLink;
  }

  /**
   * Generate type list from resource list
   *
   * @param resources list of resources
   * @returns list of unique types
   */
  private generateTypeList = (resources: Resource[]): Type[] => {

    const typeList: Type[] = [];
    resources.forEach(resource => {
      const type = resource.type;
      if (type) {
        if (typeList.findIndex(_type => _type.id === type.id) === -1) {
          typeList.push(type);
        }
      }
    });
    return typeList;
  }

  /**
   * Sets search string
   *
   * @param searchString search string
   */
  private changeSearchString = (searchString: string) => {
    this.setState({ searchString });
  }

  /**
   * Handles changing page number
   *
   * @param event event object
   * @param page page number
   */
  private changePageNumber = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({
      pageNumber: page + 1
    });
  }

  /**
   * Handles changing rows per page
   *
   * @param event event object
   */
  private changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsPerPage: parseInt(event.target.value),
      pageNumber: 1
    })
  };

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(ResourcesScreen);
const CustomStyled = withCustomStyles("screens/resources-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;
