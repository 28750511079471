import config from "../config";
import { Configuration, FilterApi, ResourceApi, UnitApi, ReservationApi, HTTPHeaders } from "../generated/client";
import { NullableToken } from "../types";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets api configuration
   *
   * @param accessToken access token
   */
  private static getConfiguration(accessToken?: NullableToken) {
    const headers: HTTPHeaders = accessToken ?
      { "Authorization": `JWT ${ accessToken.access_token }` } :
      {};

    return new Configuration({
      basePath: config.get().api.basePath,
      headers: headers
    });
  }

  /**
   * Gets initialized filter API
   *
   * @param accessToken keycloak access token
   * @returns initialized filter API
   */
  public static getFilterApi(accessToken?: NullableToken) {
    return new FilterApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized resource API
   *
   * @param accessToken keycloak access token
   * @returns initialized resource API
   */
  public static getResourceApi(accessToken?: NullableToken) {
    return new ResourceApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized unit API
   *
   * @param accessToken keycloak access token
   * @returns initialized unit API
   */
  public static getUnitApi(accessToken?: NullableToken) {
    return new UnitApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized reservation API
   *
   * @param accessToken keycloak access token
   * @returns initialized reservation API
   */
  public static getReservationApi(accessToken?: NullableToken) {
    return new ReservationApi(Api.getConfiguration(accessToken));
  }

}
