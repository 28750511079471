/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeName,
    TypeNameFromJSON,
    TypeNameFromJSONTyped,
    TypeNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * Unique identifier of the resource type
     * @type {string}
     * @memberof Type
     */
    id?: string;
    /**
     * The main category of this resource type
     * @type {string}
     * @memberof Type
     */
    mainType?: string;
    /**
     * 
     * @type {TypeName}
     * @memberof Type
     */
    name?: TypeName;
}

export function TypeFromJSON(json: any): Type {
    return TypeFromJSONTyped(json, false);
}

export function TypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Type {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mainType': !exists(json, 'main_type') ? undefined : json['main_type'],
        'name': !exists(json, 'name') ? undefined : TypeNameFromJSON(json['name']),
    };
}

export function TypeToJSON(value?: Type | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'main_type': value.mainType,
        'name': TypeNameToJSON(value.name),
    };
}


