import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/pagination";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { TablePagination } from "@material-ui/core";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  count: number;
  pageNumber: number;
  itemsPerPage: number;
  customStyles?: CustomStyles;
  changePageNumber: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

/**
 * Breadcrumb component
 *
 * @param props component props
 */
const Pagination: React.FC<Props> = ({
  count,
  itemsPerPage,
  classes,
  pageNumber,
  customStyles,
  changePageNumber,
  onChangeRowsPerPage
}) => {

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <TablePagination
        rowsPerPageOptions={ [5, 10, 15, 20, { value: count, label: strings.reservation.showAll }] }
        labelRowsPerPage={ strings.reservation.itemsPerPage }
        component="div"
        page={ pageNumber = pageNumber - 1 }
        count={ count }
        rowsPerPage={ itemsPerPage }
        onChangeRowsPerPage={ onChangeRowsPerPage }
        onChangePage={ changePageNumber }
      />
    </div>
  );
};

const Styled = withStyles(styles)(Pagination);
const CustomStyled = withCustomStyles("generic/pagination")(Styled);

export default CustomStyled;
