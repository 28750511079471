import * as React from "react";
import { withStyles, WithStyles, Breadcrumbs } from "@material-ui/core";
import { Link } from 'react-router-dom';
import styles from "../../styles/generic/breadcrumb";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { BreadcrumbLink, CustomStyles } from "../../types";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  breadcrumbs?: BreadcrumbLink[];
}

/**
 * Breadcrumb component
 *
 * @param props component props
 */
const Breadcrumb: React.FC<Props> = ({
  classes,
  customStyles,
  breadcrumbs
}) => {

  /**
   * Renders breadcrumb links
   */
  const renderBreadcrumbLinks = () => {

    if (!breadcrumbs) {
      return null;
    }

    return breadcrumbs
      .filter(crumb => crumb.label !== "")
      .map((link, index) =>
        <Link
          key={ index }
          to={ link.path }
        >
          { link.label }
        </Link>
      );
  };

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb"
        className={ classes.breadcrumb }
        style={ customStyles?.breadcrumb }
      >
        <Link to="/">
          { strings.generic.breadcrumb.frontpage }
        </Link>
        { renderBreadcrumbLinks() }
      </Breadcrumbs>
    </div>
  );
};

const Styled = withStyles(styles)(Breadcrumb);
const CustomStyled = withCustomStyles("generic/breadcrumb")(Styled);

export default CustomStyled;
