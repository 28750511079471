
import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  loaderContainer: {
    width: "100%",
    height: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  container: {
    "& .MuiInputLabel-filled": {
      color: "black"
    }
  },

  requiredFieldsText: {
    marginTop: 10,
    color: theme.palette.error.main
  },

  metadataTextField: {
    marginTop: 20,
  }

});