import * as React from "react";
import { withStyles, WithStyles, Button } from "@material-ui/core";
import styles from "../../styles/generic/search-form";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, FormValues, DropdownOption } from "../../types";
import FormItem from "./form-item";
import SearchBar from "./search-bar";
import Dropdown from "./dropdown";
import Checkbox from "./checkbox";
import SearchIcon from '@material-ui/icons/Search';
import strings from "../../localization/strings";
import { LocalizedValue, Purpose, PurposeName, Unit } from "../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  units?: Unit[];
  locale: string;
  purposes?: Purpose[];
  customStyles?: CustomStyles;
  onSearch: (form: FormValues) => void;
}

/**
 * Interface describing component state
 */
interface State {
  formValues: FormValues
}

/**
 * Search form component
 *
 * @param props component props
 */
class SearchForm extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      formValues: {
        use: "",
        office: "",
        searchString: "",
        freeOfCharge: false
      }
    };
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, customStyles } = this.props;
    const { use, office, freeOfCharge, searchString } = this.state.formValues;

    return (
      <div
        className={ classes.root }
        style={ customStyles?.root }
      >
        <div
          className={ classes.formRow }
          style={ customStyles?.formRow }
        >
          <div
            className={ classes.formColumn }
            style={ customStyles?.formColumn }
          >
            {
              this.renderFormItem(
                strings.generic.searchForm.searchWordDesc,
                <SearchBar
                  searchValue={ searchString }
                  onChange={ this.updateFormData("searchString") }
                  onEnter={ this.search }
                />
              )
            }
            {
              this.renderFormItem(
                strings.generic.searchForm.freeOfCharge,
                <Checkbox
                  onChange={ this.updateFormData("freeOfCharge") }
                  checked={ freeOfCharge }
                />
              )
            }
          </div>
          <div
            className={ classes.formDivider }
            style={ customStyles?.formDivider }
          >
          </div>
          <div
            className={ classes.formColumn }
            style={ customStyles?.formColumn }
          >
            {
              this.renderFormItem(
                strings.generic.searchForm.use,
                <Dropdown
                  selected={ use }
                  onChange={ this.updateFormData("use") }
                  options={ this.mapPurposes() }
                />
              )
            }
            {
              this.renderFormItem(
                strings.generic.searchForm.office,
                <Dropdown
                  selected={ office }
                  onChange={ this.updateFormData("office") }
                  options={ this.mapUnits() }
                />
              )
            }
          </div>
        </div>
        <div
          className={ classes.formSubmit }
          style={ customStyles?.formSubmit }
        >
          <Button
            className={ classes.clearButton }
            style={ customStyles?.clearButton }
            onClick={ this.clear }
          >
            { strings.mainMenu.clear }
          </Button>
          <Button
            className={ classes.searchButton }
            style={ customStyles?.searchButton }
            onClick={ this.search }
          >
            { strings.mainMenu.search }
            <SearchIcon />
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Renders form item
   *
   * @param label string
   * @param component jsx element
   */
  private renderFormItem = (label: string, component: JSX.Element) => {
    return (
      <FormItem
        label={ label }
        component={ component }
      />
    );
  }

  /**
   * Event handler for search click
   */
  private search = () => {
    this.props.onSearch(this.state.formValues);
  }

  /**
   * Event handler for clear form click
   */
  private clear = () => {
    const emptyForm: FormValues = {
      freeOfCharge: false,
      office: "",
      searchString: "",
      use: ""
    }

    this.setState({
      formValues: emptyForm
    });

    this.props.onSearch(emptyForm);
  }

  /**
   * Updates form data
   *
   * @param key param key
   * @param value string or boolean value 
   */
  private updateFormData = (key: string) => (value: string | boolean) => {
    this.setState({ formValues: {...this.state.formValues, [key] : value } });
  }

  /**
   * Maps purposes to dropdown options
   *
   * @returns dropdown options array
   */
  private mapPurposes = (): DropdownOption[] => {
    const { purposes, locale } = this.props;

    if (!purposes) {
      return [];
    }

    return purposes.map((purpose: Purpose) => {

      if (!purpose || !purpose.name || !purpose.id) {
        return { label: "", value: "" };
      }

      return {
        value: purpose.id || "",
        label: purpose.name[locale as keyof PurposeName] || ""
      };
    });
  }

  /**
   * Maps units to dropdown options
   *
   * @returns dropdown options array
   */
  private mapUnits = (): DropdownOption[] => {
    const { units, locale } = this.props;

    if (!units) {
      return [];
    }

    return units
      .filter(unit => !!unit && !!unit.name)
      .map((unit: Unit) => ({
        label: unit.name![locale as keyof LocalizedValue] ?? unit.name!.fi ?? "",
        value: unit.id ?? ""
      }));
  }

}

const Styled = withStyles(styles)(SearchForm);
const CustomStyled = withCustomStyles("generic/search-form")(Styled);

export default CustomStyled;