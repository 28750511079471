import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import fi from "./fi.json";
// import en from "./en.json";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {

  /**
   * Translations related to application information
   */
  app: {
    title: string;
    footerDescription: string;
  };

  /**
   * Translations related to actions in app
   */
  actions: {
    search: string;
    cancel: string;
    clear: string;
    ok: string;
    close: string;
  };

  /**
   * Translations related to authorization
   */
  auth: {
    login: string;
    logout: string;
  };

  comingSoon: string;

  /**
   * Translations related to app main menu
   */
  mainMenu: {
    search: string;
    myReservations: string;
    moreInformation: string;
    genericTerms: string;
    clear: string;
    accessibilityStatement: string;
    privacyPolicy: string;
  };

  /**
   * Translations related to generic words
   */
  genericWords: {
    none: string;
    clock: string;
    hour: string;
    day: string;
    week: string;
    freeOfCharge: string;
    people: string;
    closed: string;
  };

  /**
   * Translations related to home screen
   */
  homeScreen: {
    bannerHeading: string;
    purposeQuestion: string;
  };

  /**
   * Translations related to more generic terms
   */
  genericTerms: {
    heading: string;
    subheading: string;
  };

  /**
   * Translations related to accessibility statement
   */
  accessibilityStatement: {
    heading: string;
  };

  /**
   * Translations related to privacy policy
   */
  privacyPolicy: {
    heading: string;
  }

  /**
   * Translations related to generic components
   */
  generic: {
    breadcrumb: {
      frontpage: string;
    };
    filters: {
      atleastTen: string;
      atleastTwenty: string;
      atleastThirty: string;
    };
    imageAlt: string;
    searchForm: {
      use: string;
      office: string;
      ending: string;
      beginning: string;
      freeOfCharge: string;
      searchWordDesc: string;
    };
    reservationCard: {
      begins: string;
      ends: string;
      cancelReservation: string
    };
    resourcesLister: {
      noResults: string;
    },
    close: string;
    error: string;
  };

  /**
   * Translations related to reservation
   */
  reservation: {
    time: string;
    status: string;
    reservationStatus: {
      needsLogin: string;
      notAvailable: string;
      available: string;
      reserved: string;
      noReservationItems: string;
    };
    actions: {
      loginToReserve: string;
      reserve: string;
      cancel: string;
      remove: string;
    };
    addDialog: {
      title: string;
      text: string;
      required: string;
      fieldRequired: string;
      invalidEmail: string;
      acceptStoringUserInfo: string;
      confirm: string;
      cancel: string;
    };
    confirmDialog: {
      approve: string;
      rules: string;
    };
    successDialog: {
      navigateToFrontPage: string;
      navigateToMyReservations: string;
    };
    deleteDialog: {
      text: string;
      title: string;
      cancel: string;
      confirm: string;
    };
    short: string;
    long: string;
    cannotReserve: string;
    invalidDate: string;
    startingTime: string;
    endingTime: string;
    itemsPerPage: string;
    showAll: string;
    noReservations: string;
    waitingForConfirmation: string;
    waitingForPayment: string;
    contactStaffToCancel: string;
    rejected: string;
    errorTitle: string;
    error: string;
    success: string;
    initialSuccess: string;
    maxUserReservations: string;
  };

  /**
   * Translations related to resource
   */
  resource: {
    description: string;
    equipment: string;
    metadata: {
      /**
       * To make localization process a bit easier these strings
       * should match the field names coming from Respa.
       */
      reserver_name: string;
      reserver_phone_number: string;
      reserver_address_street: string;
      reserver_address_zip: string;
      reserver_address_city: string;
      billing_address_street: string;
      billing_address_zip: string;
      billing_address_city: string;
      company: string;
      event_description: string;
      reserver_id: string;
      number_of_participants: string;
      reserver_email_address: string;
      event_subject: string;
      reservation_extra_questions: string;
      billing_first_name: string;
      billing_email_address: string;
      billing_last_name: string;
      billing_phone_number: string;
      host_name: string;
      participants: string;
    }
  };

  /**
   * Translations related to tooltip
   */
  tooltip: {
    address: string;
    peopleCapacity: string;
    reservationTimeLimit: string;
    price: string;
    openingHours: string;
    resourceArea: string;
  };

  /**
   * Translations related to errors or missing localizations (in Respa)
   */
  errorMessages: {
    purposeNameMissing: string;
    typeNameMissing: string;
  };
}

// On the first phase customer doesn't need language selection. Comment this out when they need it
const strings: IStrings = new LocalizedStrings({ fi, /** en */  });

export default strings;