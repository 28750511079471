import * as React from "react";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles, ReservationDatePickerType, SelectedDateHolder } from "../../types";
import { Reservation, Resource } from "../../generated/client";
import styles from "../../styles/resources/resource-date-picker";
import { MuiPickersUtilsProvider, Calendar } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { RespaDateObject } from "./constants";
import  * as Moment from "moment";
import { extendMoment } from "moment-range";
import strings from "../../localization/strings";

const moment = extendMoment(Moment);

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  resource: Resource;
  longReservation?: boolean;
  reservations?: Reservation[];
  type?: ReservationDatePickerType;
  customStyles?: CustomStyles;
  locale: string;
  selectedDates: SelectedDateHolder;
  onDateChange: (date: Date) => void;
}

/**
 * Resource date picker component
 *
 * @param props component props
 */
const ResourceDatePicker: React.FC<Props> = props => {
  const {
    classes,
    customStyles,
    resource,
    longReservation,
    reservations,
    type,
    selectedDates,
    onDateChange,
    locale
  } = props;

  /**
   * Event handler for change date
   * 
   * @param date Material UI Picker's date object
   */
  const onChange = (date: MaterialUiPickersDate) => {
    const parsedDate = date?.toDate();
    if (parsedDate) {
      onDateChange(parsedDate);
    }
  }

  /**
   * Returns selected date based on given date picker type
   */
  const getSelectedDate = () => {
    return moment(
      type && type === ReservationDatePickerType.END ?
        selectedDates.endDate :
        selectedDates.startDate
    );
  }

  /**
   * Returns min date for calendar
   *
   * @returns minimum date as Moment date
   */
  const getMinDate = (): moment.Moment => {
    const currentDate = moment().startOf("day");
    const minDaysInAdvance = resource.reservableMinDaysInAdvance;

    if (!minDaysInAdvance) {
      return currentDate;
    }

    const minStartDay = currentDate.add(minDaysInAdvance + 1, "day");
    if (!longReservation) {
      return minStartDay;
    }

    const minPeriod = resource.minPeriod;
    if (!type || type === ReservationDatePickerType.START || !minPeriod) {
      return minStartDay;
    }

    const selectedStartDate = moment(selectedDates.startDate);
    const minDuration = moment.duration(minPeriod);
    if (minDuration.asDays() < 1) {
      return selectedStartDate.endOf("day");
    }

    return selectedStartDate.add(minDuration).subtract(1, "day").endOf("day");
  }

  /**
   * Returns max date for calendar
   *
   * @returns maximum date as Moment date
   */
  const getMaxDate = (): moment.Moment => {
    const currentDate = moment().endOf("day");
    const maxDaysInAdvance = resource.reservableMaxDaysInAdvance;
    const maxDuration = Math.floor(moment.duration(resource.maxPeriod || 0).asDays());

    const limit = moment(currentDate).add(1, "year");
    const calculatedMax = maxDaysInAdvance && maxDuration >= 1 ?
      moment(currentDate).add(resource.reservableMaxDaysInAdvance, "days").add(maxDuration, "days") :
      undefined;

    const maxDate = calculatedMax && calculatedMax.isSameOrBefore(limit) ?
      calculatedMax :
      limit;

    const minDuration = Math.floor(moment.duration(resource.minPeriod || 0).asDays());
    if (type === ReservationDatePickerType.START) {
      return minDuration ?
        maxDate.subtract(minDuration, "days") :
        maxDate;
    }

    if (maxDuration >= 1) {
      const maxDateFromSelectedStartDate = moment(selectedDates.startDate).add(maxDuration, "days");
      return maxDateFromSelectedStartDate.isBefore(maxDate) ?
        maxDateFromSelectedStartDate :
        maxDate;
    }

    return maxDate;
  }

  /**
   * Check if resource is not reservable in given day
   * 
   * @param day Material UI Picker's day object
   */
  const isNotReservable = (day: MaterialUiPickersDate) => {
    const openingHours = resource.openingHours ?
      [ ...resource.openingHours ] as RespaDateObject[] :
      [];

    const correspondingDay = openingHours.find((dayOpeningHours: RespaDateObject) => dayOpeningHours.date === day?.format("YYYY-MM-DD"));

    return !!correspondingDay &&
      correspondingDay.opens === null &&
      correspondingDay.closes === null;
  }

  /**
   * Renders long reservation day
   * 
   * @param day date object of Material UI Picker
   * @param dayInCurrentMonth does day belong to currently visible month
   * @param dayComponent default day component
   */
  const renderLongReservationDay = (day: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
    if (!dayInCurrentMonth || day?.isBefore(moment(), "day")) {
      return dayComponent;
    }

    const utcDay = moment(day).format("YYYY-MM-DD HH:mm");
    const utcDayStartAsLocalDate = moment(utcDay).utc(true)
      .startOf("day")
      .local()
      .toDate();

    const existingReservation = (reservations || []).some(reservation => {
      const reservationRange = moment.range(moment(reservation.begin), moment(reservation.end));
      return day && reservationRange.contains(utcDayStartAsLocalDate, { excludeStart: false, excludeEnd: true });
    });

    if (!existingReservation) {
      return dayComponent;
    }

    return (
      <div className={ classes.reservedDayWrapper }>
        { dayComponent }
      </div>
    );
  }

  /**
   * Component render
   */
  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <MuiPickersUtilsProvider utils={ MomentUtils } locale={ locale }>
        <Calendar
          date={ getSelectedDate() }
          onChange={ onChange }
          disablePast
          maxDate={ getMaxDate() }
          minDate={ getMinDate() }
          shouldDisableDate={ day => !longReservation && isNotReservable(day) }
          renderDay={ (day, selectedDate, dayInCurrentMonth, dayComponent) =>
            longReservation && reservations ?
              renderLongReservationDay(day, dayInCurrentMonth, dayComponent) :
              dayComponent
          }
          allowKeyboardControl={ false }
        />
      </MuiPickersUtilsProvider>
      { longReservation &&
        <div className={ classes.legendContainer }>
          <span className={ classes.legendMarkerReserved }/>
          <Typography>
            { strings.reservation.reservationStatus.reserved }
          </Typography>
        </div>
      }
    </div>
  );
};

const Styled = withStyles(styles)(ResourceDatePicker);
const CustomStyled = withCustomStyles("resources/resource-date-picker")(Styled);

export default CustomStyled;