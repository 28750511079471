/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * The resource specific equipment instances
 * @export
 * @interface ResourceEquipment
 */
export interface ResourceEquipment {
    /**
     * Additional data related to the resource specific equipment instance
     * @type {{ [key: string]: string; }}
     * @memberof ResourceEquipment
     */
    data?: { [key: string]: string; };
    /**
     * 
     * @type {LocalizedValue}
     * @memberof ResourceEquipment
     */
    name?: LocalizedValue;
    /**
     * Description of the resource specific equipment instance
     * @type {LocalizedValue}
     * @memberof ResourceEquipment
     */
    description?: LocalizedValue;
    /**
     * Unique identifier of the piece of equipment
     * @type {string}
     * @memberof ResourceEquipment
     */
    id?: string;
}

export function ResourceEquipmentFromJSON(json: any): ResourceEquipment {
    return ResourceEquipmentFromJSONTyped(json, false);
}

export function ResourceEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'name': !exists(json, 'name') ? undefined : LocalizedValueFromJSON(json['name']),
        'description': !exists(json, 'description') ? undefined : LocalizedValueFromJSON(json['description']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ResourceEquipmentToJSON(value?: ResourceEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'name': LocalizedValueToJSON(value.name),
        'description': LocalizedValueToJSON(value.description),
        'id': value.id,
    };
}
