import * as React from "react";

import { List, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/purposes/purposes-lister";

import { InlineResponse2002 as PurposeResponse } from "../../generated/client";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import PurposeCard from "../generic/purpose-card";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  purposeResponse?: PurposeResponse;
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Purpose lister component
 *
 * @param props component props
 */
const PurposesLister: React.FC<Props> = props => {
  const { classes, purposeResponse, locale, customStyles } = props;

  /**
   * Renders purposes
   */
  const renderPurposes = () => {
    if (!purposeResponse) {
      return null;
    }

    const purposes = purposeResponse.results;
    if (!purposes) {
      return null;
    }

    return purposes.map(purpose => 
      <PurposeCard
        key={ purpose.id }
        locale={ locale }
        purpose={ purpose }
      />
    );
  };

  /**
   * Component render
   */
  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <List
        className={ classes.list }
        style={ customStyles?.list }
      >
        { renderPurposes() }
      </List>
    </div>
  );
};

const Styled = withStyles(styles)(PurposesLister);
const CustomStyled = withCustomStyles("purposes/purposes-lister")(Styled);

export default CustomStyled;