import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    width: "100%"
  },

  list: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around"
  },

  container: {
    width: "22%",
    textAlign: "center",
    borderRadius: "50px 50px 50px 50px",
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "47%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    }
  },

  card: {
    borderRadius: "50px 50px 50px 50px",
    backgroundColor: theme.palette.primary.main
  },

  cardContent: {
    paddingTop: 15,
    paddingBottom: 15,
    "&:last-child": {
      paddingTop: 15,
      paddingBottom: 15,
    }
  },

  cardName: {
    fontSize: 15,
    color: theme.palette.text.secondary
  },

  link: {
    textDecoration: "none"
  },

});
