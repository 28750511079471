import * as React from 'react';
import { CustomStyles } from "../../types";

/**
 * Interface describing injected properties
 */
interface InjectedProps {
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  loading?: boolean;
  customStyles?: CustomStyles;
}

/**
 * Higher-order component for adding custom styles
 *
 * @param stylesPath custom styles path
 * @param Component React component to inject with custom styles
 */
export const withCustomStyles = (stylesPath: string) =>
  <Props extends InjectedProps>(Component: React.ComponentType<Props>) =>
    class WithCustomStyles extends React.Component<Props, State> {

      /**
       * Component constructor
       * 
       * @param props props
       */
      constructor(props: Props) {
        super(props);
        this.state = {
          loading: true
        };
      }

      /**
       * Component did mount life cycle method
       */
      public componentDidMount = async () => {
        await import(`../../dynamic-content/custom-styles/${stylesPath}`)
          .then(module => this.setState({ customStyles: module.default }))
          .catch(e => console.log());
        this.setState({ loading: false });
      }

      /**
       * Component render method
       */
      public render = () => {
        if (this.state.loading) {
          return null;
        }

        return (
          <Component
            { ...this.props }
            customStyles={ this.state.customStyles }
          />
        );
      }
    }
