import * as React from "react";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import styles from "../../styles/generic/banner";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import strings from "../../localization/strings";
import config from "../../config";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Banner component
 *
 * @param props component props
 */
const Banner: React.FC<Props> = ({ classes, customStyles }) => {
  const [ bannerImage, setBannerImage ] = React.useState<string | undefined>();
  const [ description, setDescription ] = React.useState<string>();

  React.useEffect(() => {
    config.getPublicContent()
      .then(content => content && setDescription(content?.frontPageDescription))
      .catch(console.warn);

    const imagePath = "resources/images/banner-image.jpg";
    import(`../../dynamic-content/${imagePath}`)
      .then(imageUrl => setBannerImage(imageUrl.default))
      .catch(e => console.log("Banner image not found", e));
  }, []);

  return (
    <div
      className={ classes.root }
      style={ customStyles?.root }
    >
      <div
        className={ classes.banner }
        style={ customStyles?.banner }
      >
        <img
          src={ bannerImage }
          alt=""
          className={ classes.bannerImage }
        />
        <div className={ classes.bannerInfoContainer }>
          <Typography
            variant="h4"
            className={ classes.bannerHeading }
            style={ customStyles?.bannerHeading }
          >
            { strings.homeScreen.bannerHeading }
          </Typography>
          { description &&
            <Typography
              variant="subtitle1"
              className={ classes.bannerDescription }
              style={ customStyles?.bannerDescription }
            >
              { description }
            </Typography>
          }
        </div>
      </div>
    </div>
  );
};

const Styled = withStyles(styles)(Banner);
const CustomStyled = withCustomStyles("generic/banner")(Styled);

export default CustomStyled;