import * as React from "react";
import { withStyles, WithStyles, Typography, Divider } from "@material-ui/core";
import styles from "../../styles/resources/reserved-item";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { Reservation } from "../../generated/client";
import moment from "moment";
import strings from "../../localization/strings";
import * as ResourceUtils from "../../utils/resource-utils";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  reservation: Reservation;
  customStyles?: CustomStyles;
  locale: string;
}

/**
 * Individual reservation list item component
 *
 * @param props component props
 */
const ReservedItem: React.FC<Props> = props => {
  const {
    classes,
    customStyles,
    reservation
  } = props;

  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <div
        className={ classes.dataContainer }
        style={ customStyles?.dataContainer }
      >
        <Typography
          className={ classes.reservationTime }
          style={ customStyles?.reservationTime }
        >
          { constructDisplayTime(reservation) }
        </Typography>
        <Typography
          className={ classes.reservedText }
          style={ customStyles?.reservedText }
        >
          { strings.reservation.reservationStatus.reserved }
        </Typography>
      </div>
      <Divider variant="fullWidth"/>
    </div>
  );
};

/**
 * Construct display time
 *
 * @param reservation reservation
 * @returns display string
 */
const constructDisplayTime = (reservation: Reservation): string => {
  const { begin, end } = reservation;

  if (!begin || !end) {
    return strings.reservation.invalidDate;
  }

  const longReservation = moment(end).diff(moment(begin), 'days') >= 1;

  const displayStrings = longReservation ?
    ResourceUtils.getLongReservationDisplayString(begin, end) :
    ResourceUtils.getShortReservationDisplayString(begin, end);

  return `${displayStrings.begin} - ${displayStrings.end}`;
};

const Styled = withStyles(styles)(ReservedItem);
const CustomStyled = withCustomStyles("resources/reserved-item")(Styled);

export default CustomStyled;