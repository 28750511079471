import * as React from "react";

import { ReduxState } from "../../store";
import { connect } from "react-redux";

import { Button, withStyles, WithStyles, Divider, Typography } from "@material-ui/core";
import styles from "../../styles/screens/resource-screen";
import { History } from "history";
import AppLayout from "../layouts/app-layout";
import { NullableToken, CustomStyles, DateHolder, ReservationDatePickerType, RespaReservationInfo, SelectedDateHolder, ClientRoles } from "../../types";
import { InlineResponse2007, Reservation, ReservationStateEnum, Resource } from "../../generated/client";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import Breadcrumb from "../generic/breadcrumb";
import GeneralResourceInfo from "../resources/resource-general-info";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ResourceDatePicker from "../resources/resource-date-picker";
import ResourceDateLister from "../resources/resource-date-list";
import { ResourceReservationDataHolder, RespaDateObject } from "../resources/constants";
import strings from "../../localization/strings";
import ConfirmDialog from "../generic/confirm-dialog";
import produce from "immer";
import ResourcesReservationLister from "../resources/resource-reservation-lister";
import * as ResourceUtils from "../../utils/resource-utils";
import { KeycloakInstance } from "keycloak-js";
import ResourceMetadata from "../resources/resource-metadata";
import  * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  keycloak?: KeycloakInstance;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  locale: string;
  resourceId: string;
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  confirmDialogOpen: boolean;
  loadingReservations: boolean;
  resource?: Resource;
  reservationData?: ResourceReservationDataHolder;
  existingReservations: Reservation[];
  checkedTimes: DateHolder[];
  selectedDates: SelectedDateHolder;
  longReservation: boolean;
  reservationExtraInfo: RespaReservationInfo;
  formValid: boolean;
  successDialogOpen: boolean;
  error: boolean;
  errorMessage?: string;
  ownReservationCount?: number;
}

/**
 * Component for resource screen
 */
class ResourceScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    const currentDate = new Date();
    this.state = {
      loading: true,
      loadingReservations: true,
      checkedTimes: [],
      selectedDates: {
        startDate: currentDate,
        endDate: currentDate
      },
      existingReservations: [],
      longReservation: false,
      confirmDialogOpen: false,
      reservationExtraInfo: { },
      formValid: false,
      successDialogOpen: false,
      error: false
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    this.setState({
      loading: true,
      loadingReservations: true
    });

    await this.fetchResource();
    this.setState({ loading: false });

    await this.fetchReservations();
    this.updateExistingReservations();
    this.setState({ loadingReservations: false });

    await this.fillUserInfo();
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component properties
   * @param prevState previous component state
   */
  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.selectedDates.startDate.getTime() !== this.state.selectedDates.startDate.getTime() ||
      prevState.selectedDates.endDate.getTime() !== this.state.selectedDates.endDate.getTime()
    ) {
      this.updateExistingReservations();
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, locale, customStyles } = this.props;
    const { resource, loading, loadingReservations } = this.state;

    if (loading || !resource || !resource.id || !resource.name) {
      return (
        <AppLayout>
          <div
            className={ classes.container }
            style={ customStyles?.container }
          >
            <Breadcrumb
              key="resourceBreadcrumb"
              breadcrumbs={ [] }
            />
            <div
              className={ classes.loaderContainer }
              style={ customStyles?.loaderContainer }
            >
              <CircularProgress size={ 50 } color="secondary"/>
            </div>
          </div>
        </AppLayout>
      );
    }

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <Breadcrumb
            key={ `resourceBreadcrumb-${resource.id}` }
            breadcrumbs={ [] }
          />
          <Divider />
          <GeneralResourceInfo
            resource={ resource }
            locale={ locale }
          />
            { loadingReservations &&
              this.renderLoader()
            }
            { !this.isMaxReservationsExceeded() && !loadingReservations &&
              this.renderDateComponents()
            }
            { this.renderActionButtons() }
            { this.renderConfirmDialog() }
            { this.renderSuccessDialog() }
        </div>
      </AppLayout>
    );
  }

  /**
   * Render date components based on reservation type
   */
  private renderDateComponents = () => {
    const { longReservation } = this.state;

    return longReservation ?
      this.renderLongReservationDateComponents() :
      this.renderShortReservationDateComponents();
  }

  /**
   * Render long reservation date components
   */
  private renderLongReservationDateComponents = () => {
    const { classes, locale, customStyles } = this.props;
    const { resource, reservationData, selectedDates, existingReservations } = this.state;

    if (!resource || !resource.id || !reservationData) {
      return null;
    }

    return (
      <div
        className={ classes.reservationContainer }
        style={ customStyles?.reservationContainer }
      >
        <div
          className={ classes.datePickerContainer }
          style={ customStyles?.datePickerContainer }
        >
          <Typography
            variant="h4"
            className={ classes.longReservationDateTitle }
            style={ customStyles?.longReservationDateTitle }
          >
            { strings.reservation.startingTime }
          </Typography>
          <ResourceDatePicker
            resource={ resource }
            locale={ locale }
            type={ ReservationDatePickerType.START }
            longReservation={ true }
            reservations={ reservationData.reservations }
            selectedDates={ selectedDates }
            onDateChange={ this.updateSelectedTime(ReservationDatePickerType.START) }
          />
        </div>
        <div
          className={ classes.datePickerContainer }
          style={ customStyles?.datePickerContainer }
        >
          <Typography
            variant="h4"
            className={ classes.longReservationDateTitle }
            style={ customStyles?.longReservationDateTitle }
          >
            { strings.reservation.endingTime }
          </Typography>
          <ResourceDatePicker
            resource={ resource }
            locale={ locale }
            type={ ReservationDatePickerType.END }
            longReservation={ true }
            reservations={ reservationData.reservations }
            selectedDates={ selectedDates }
            onDateChange={ this.updateSelectedTime(ReservationDatePickerType.END) }
          />
        </div>
        <div
          className={ classes.reservedTimesContainer }
          style={ customStyles?.reservedTimesContainer }
        >
          <ResourcesReservationLister
            locale={ locale }
            reservations={ existingReservations }
            selectedDates={ selectedDates }
          />
        </div>
      </div>
    );
  }

  /**
   * Render short reservation date components
   */
  private renderShortReservationDateComponents = () => {
    const {
      accessToken,
      classes,
      locale,
      customStyles
    } = this.props;

    const {
      resource,
      loadingReservations,
      reservationData,
      selectedDates,
      checkedTimes
    } = this.state;

    if (!resource || !resource.id) {
      return null;
    }

    return (
      <div
        className={ classes.reservationContainer }
        style={ customStyles?.reservationContainer }
      >
        <div
          className={ classes.datePickerContainer }
          style={ customStyles?.datePickerContainer }
        >
          <ResourceDatePicker
            resource={ resource }
            locale={ locale }
            selectedDates={ selectedDates }
            onDateChange={ this.updateSelectedTime(ReservationDatePickerType.START) }
          />
        </div>
        <div
          className={ classes.dateListContainer }
          style={ customStyles?.dateListContainer }
        >
          <ResourceDateLister
            loading={ loadingReservations }
            notReservable={ !resource.reservable }
            needsLogin={ !accessToken }
            resource={ resource }
            selectedDates={ selectedDates }
            checkedTimes={ checkedTimes }
            locale={ locale }
            reservationData={ reservationData }
            updateCheckedTimes={ this.updateCheckedTimes }
          />
        </div>
      </div>
    );
  }

  /**
   * Renders loader
   */
  private renderLoader = () => {
    return (
      <div className={ this.props.classes.loaderContainer }>
        <CircularProgress size={ 50 } color="secondary"/>
      </div>
    );
  }

  /**
   * Renders action buttons
   */
  private renderActionButtons = () => {
    const { accessToken, classes, customStyles } = this.props;
    const { resource } = this.state;

    if (!resource) {
      return null;
    }

    const userReservationCountExceeded = this.isMaxReservationsExceeded();
    const maxReservationsText = strings.formatString(strings.reservation.maxUserReservations, resource.maxReservationsPerUser || 0);

    const content = userReservationCountExceeded ? (
        <Typography
          variant="h5"
          className={ classes.maxUserReservations }
        >
          { maxReservationsText }
        </Typography>
      ) : (
        <>
          { this.renderCancelButton() }
          { accessToken ?
            this.renderReserveButton() :
            this.renderLoginToReserveButton()
          }
        </>
      );

    return (
      <div
        className={ classes.actionButtonContainer }
        style={ customStyles?.actionButtonContainer }
      >
        { content }
      </div>
    );
  }

  /**
   * Renders cancel button
   */
  private renderCancelButton = () => {
    const { classes, customStyles } = this.props;

    return (
      <Button
        variant="contained"
        disableElevation
        className={ classes.cancelButton }
        style={ customStyles?.cancelButton }
        onClick={ () => this.props.history.goBack() }
      >
        { strings.actions.cancel }
      </Button>
    );
  }

  /**
   * Renders login to reserve button
   */
  private renderLoginToReserveButton = () => {
    const { classes, customStyles, keycloak } = this.props;

    return (
      <Button
        variant="contained"
        disableElevation
        onClick={ () => keycloak ?
          keycloak.login({ idpHint: "oidc" }) :
          console.log("No keycloak instance")
        }
        className={ classes.reserveButton }
        style={ customStyles?.reserveButton }
      >
        { strings.reservation.actions.loginToReserve }
      </Button>
    );
  }

  /**
   * Renders reserve button
   */
  private renderReserveButton = () => {
    const { classes, customStyles } = this.props;
    const { longReservation, checkedTimes, existingReservations } = this.state;

    if (longReservation) {
      const disabled = existingReservations.length > 0;
      return (
        <Button
          variant="contained"
          disableElevation
          onClick={ this.openDialog }
          className={ classes.reserveButton }
          style={{ ...customStyles?.reserveButton, ...disabled ? customStyles?.disabled : {} }}
          disabled={ disabled }
        >
          { strings.reservation.actions.reserve }
        </Button>
      );
    }

    const disabled = checkedTimes.length === 0;
    return (
      <Button
        variant="contained"
        disableElevation
        onClick={ this.openDialog }
        className={ classes.reserveButton }
        style={{ ...customStyles?.reserveButton, ...disabled ? customStyles?.disabled : {} }}
        disabled={ disabled }
      >
        { strings.reservation.actions.reserve }
      </Button>
    );
  }

  /**
   * Method for rendering confirm dialog
   */
  private renderConfirmDialog = () => {
    const { locale } = this.props;
    const {
      confirmDialogOpen,
      resource,
      selectedDates,
      longReservation,
      reservationExtraInfo,
      formValid
    } = this.state;

    if (!resource || !resource.name) {
      return;
    }

    let extraContent: string | undefined | JSX.Element;

    if (longReservation) {
      const { startDate, endDate } = selectedDates;
      const text = strings.reservation.addDialog.text;
      const name = ResourceUtils.getLocalizedProperty(resource.name, locale);
      extraContent = name ?
        strings.formatString(text, name, this.getDateRangeString(startDate, endDate, true)) as string :
        undefined;
    } else {
      extraContent = this.renderCheckedTimes(resource);
    }

    return (
      <ConfirmDialog
        locale={ locale }
        formValid={ formValid }
        open={ confirmDialogOpen }
        onClose={ this.closeDialog }
        onCancel={ this.closeDialog }
        onConfirm={ this.confirmReservation }
        title={ strings.reservation.addDialog.title }
        cancelButtonText={ strings.reservation.addDialog.cancel }
        confirmButtonText={ strings.reservation.addDialog.confirm }
        needsConfirmation
        termsOfUse={ resource.genericTerms }
      >
        <ResourceMetadata
          requiredReservationExtraFields={ resource.requiredReservationExtraFields || [] }
          supportedReservationExtraFields={ resource.supportedReservationExtraFields || [] }
          extraContent={ extraContent }
          reservationExtraInfo={ reservationExtraInfo }
          onUpdate={ this.onInfoUpdate }
          isValid={ valid => this.setState({ formValid: valid }) }
        />
      </ConfirmDialog>
    );
  }

  /**
   * Renders success dialog
   */
  private renderSuccessDialog = () => {
    const { locale, history } = this.props;
    const {
      successDialogOpen,
      error,
      resource
    } = this.state;

    const successText = resource?.needManualConfirmation ?
      strings.reservation.initialSuccess :
      strings.reservation.success;

    const title = error ?
      strings.reservation.error :
      successText;

    return (
      <ConfirmDialog
        locale={ locale }
        open={ successDialogOpen }
        onClose={ this.closeDialog }
        onCancel={ error ?
          () => this.closeDialog() :
          () => history.push("/my-reservations")
        }
        onConfirm={ error ?
          undefined :
          () => history.push("/")
        }
        title={ title }
        cancelButtonText={ error ?
          strings.actions.close :
          strings.reservation.successDialog.navigateToMyReservations
        }
        confirmButtonText={ strings.reservation.successDialog.navigateToFrontPage }
      />
    );
  }

  /**
   * Returns whether maximum amount of reservations for single user is exceeded or not
   */
  private isMaxReservationsExceeded = () => {
    const { accessToken }= this.props;
    const { ownReservationCount, resource } = this.state;

    return !!resource &&
      !!ownReservationCount &&
      !!resource.maxReservationsPerUser &&
      ownReservationCount >= resource.maxReservationsPerUser &&
      !accessToken?.clientRoles.includes(ClientRoles.CAN_IGNORE_RESOURCES_MAX_RESERVATIONS_PER_USER);
  }

  /**
   * Confirms reservation
   */
  private confirmReservation = async () => {
    this.setState({ loading: true });
    await this.addReservation();
    this.setState({ loading: false });
  }

  /**
   * Event handler for reservation info update
   *
   * @param updatedReservationInfo updated reservation info
   */
  private onInfoUpdate = (updatedReservationInfo: RespaReservationInfo) => {
    this.setState({
      reservationExtraInfo: updatedReservationInfo
    });
  }

  /**
   * Method for opening confirm dialog
   */
  private openDialog = () => {
    this.setState({
      confirmDialogOpen: true
    });
  }

  /**
   * Method for closing confirm dialog
   */
  private closeDialog = () => {
    this.setState({
      confirmDialogOpen: false,
      successDialogOpen: false,
      error: false
    });
  }

  /**
   * Method for rendering checked times for confirm dialog
   *
   * @param resource resource
   */
  private renderCheckedTimes = (resource: Resource) => {
    const { checkedTimes } = this.state;
    const { locale } = this.props;

    if (!resource.name) {
      return;
    }

    const localizedName = ResourceUtils.getLocalizedProperty(resource.name, locale);
    if (!localizedName) {
      return;
    }

    const reservationStartTime = ResourceUtils.getShortReservationStartTime(checkedTimes);
    const reservationEndTime = ResourceUtils.getShortReservationEndTime(checkedTimes);
    const dialogText = strings.formatString(
      strings.reservation.addDialog.text,
      localizedName,
      this.getDateRangeString(
        moment(reservationStartTime).toDate(),
        moment(reservationEndTime).toDate()
      )
    );

    return (
      <Typography paragraph>
        { dialogText }
      </Typography>
    );
  }

  /**
   * Event handler for updating selected time
   *
   * @param type reservation date picker type
   * @param data date
   */
  private updateSelectedTime = (type: ReservationDatePickerType) => (date: Date) => {
    const { longReservation, resource, selectedDates } = this.state;

    this.setState(
      produce((draft: State) => {
        if (longReservation) {
          const minPeriod = resource?.minPeriod ?
            moment.duration(resource.minPeriod) :
            undefined;

          const maxPeriod = resource?.maxPeriod ?
            moment.duration(resource.maxPeriod) :
            undefined;

          draft.selectedDates = ResourceUtils.updateLongReservationTimes(date, { ...selectedDates }, type, minPeriod, maxPeriod);
        } else {
          draft.selectedDates = ResourceUtils.updateShortReservationTimes(date, { ...selectedDates });
          draft.checkedTimes = [];
        }
      })
    );
  }

  /**
   * Event handler for add reservation click
   */
  private addReservation = async () => {
    await this.createReservation();
    await this.fetchReservations();
  }

  /**
   * Handles reservation creation
   */
  private createReservation = async () => {
    const { accessToken } = this.props;
    const {
      resource,
      selectedDates,
      reservationExtraInfo,
      longReservation,
      checkedTimes
    } = this.state;

    if (!accessToken || !resource) {
      return;
    }

    const newReservation = longReservation ?
      ResourceUtils.createSingleReservation(
        resource.id!!,
        selectedDates.startDate,
        selectedDates.endDate,
        reservationExtraInfo,
        true
      ) :
      ResourceUtils.constructReservationFromCheckedTimes(
        resource,
        checkedTimes,
        reservationExtraInfo
      );

    const reservationApi = Api.getReservationApi(accessToken);
    try {
      await reservationApi.reservationPost({
        reservation: newReservation
      })
    } catch (error) {
      const errorMessage = await error.json();
      this.setState({
        error: true,
        errorMessage: errorMessage.non_field_errors ?
          errorMessage.non_field_errors[0] :
          strings.reservation.error,
        checkedTimes: [],
        confirmDialogOpen: false,
        successDialogOpen: true
      });
      return;
    }

    this.setState({
      checkedTimes: [],
      confirmDialogOpen: false,
      successDialogOpen: true,
      error: false,
      errorMessage: undefined
    });
  }

  /**
   * Update checked times list
   *
   * @param dateHolder data holder
   * @param checked boolean if clicked item is checked or not
   */
  private updateCheckedTimes = (dateHolder: DateHolder, checked: boolean) => {
    let updatedList = this.state.checkedTimes as DateHolder[];
    if (checked) {
      updatedList = [ ...updatedList, dateHolder ]
    } else {
      updatedList = updatedList.filter(time => time.dates.starts.getTime() !== dateHolder.dates.starts.getTime());
    }

    this.setState({
      checkedTimes: updatedList
    });
  }

  /**
   * Fetch resource from API
   */
  private fetchResource = async () => {
    const { resourceId } = this.props;

    const resourceApi = Api.getResourceApi();
    const resource = await resourceApi.resourceIdGet({
      id: resourceId,
      start: moment().utc(true).startOf("day").toDate(),
      end: moment().utc(true).endOf("day").add(1, "year").toDate()
    });

    this.setState({ resource });
  }

  /**
   * Fetch reservations of selected resource from API
   */
  private fetchReservations = async () => {
    const { accessToken } = this.props;
    const { resource } = this.state;
    if (!resource) {
      return;
    }

    const reservationApi = Api.getReservationApi(accessToken);
    const reservations = await reservationApi.reservationGet({
      resource: resource.id,
      start: moment().utc(true).startOf("day").toISOString(),
      end: moment().utc(true).endOf("day").add(1, "year").toISOString(),
      pageSize: 1000
    });

    const filteredReservations = {
      ...reservations,
      results: reservations.results?.filter(reservation =>
        reservation.state !== ReservationStateEnum.Cancelled
      )
    };

    let ownReservationCount: number | undefined = undefined;

    if (accessToken) {
      const ownReservationResponse = await reservationApi.reservationGet({
        resource: resource.id,
        isOwn: true
      });
      ownReservationCount = (ownReservationResponse.results || []).filter(reservation =>
        reservation.state !== ReservationStateEnum.Cancelled &&
        moment(reservation.end).isAfter(moment())
      ).length;
    }

    const reservationData = this.constructReservationData(resource, filteredReservations);
    const longReservation = reservationData?.longReservationOnly || false;
    const selectedDates = this.getInitialSelectedDates(resource, longReservation);

    this.setState({
      reservationData,
      ownReservationCount,
      longReservation,
      selectedDates
    });
  }


  /**
   * Fills user info from Keycloak access token.
   */
  private fillUserInfo = async () => {
    const { accessToken } = this.props;
    if (!accessToken) {
      return;
    }

    this.setState(
      produce((draft: State) => {
        draft.reservationExtraInfo.reserver_name = `${accessToken.firstName} ${accessToken.lastName}`;
        draft.reservationExtraInfo.reserver_email_address = accessToken.email || "";
      })
    );
  }

  /**
   * Update existing reservations based on selected dates and reservation data
   */
  private updateExistingReservations = () => {
    const { reservationData, selectedDates } = this.state;
    if (!reservationData) {
      return;
    }

    const { startDate, endDate } = selectedDates;
    const utcStartString = moment(startDate).format("YYYY-MM-DD HH:mm");
    const localStartDate = moment.utc(utcStartString)
      .startOf("day")
      .local()
      .toDate();

    const utcEndString = moment(endDate).format("YYYY-MM-DD HH:mm");
    const localEndDate = moment.utc(utcEndString)
      .startOf("day")
      .local()
      .toDate();

    const existingReservations = reservationData.reservations.filter(reservation => {
      if (!reservation.begin || !reservation.end) {
        return false;
      }

      const reservationRange = moment.range(moment(reservation.begin), moment(reservation.end));
      const selectedDatesRange = moment.range(moment(localStartDate), moment(localEndDate));
      return reservationRange.overlaps(selectedDatesRange) || reservationRange.contains(selectedDatesRange.end);
    });

    this.setState({ existingReservations });
  }

  /**
   * Returns initial selected dates based on resource data
   *
   * @param resource resource
   * @param isLongReservation is long reservation
   * @returns selected dates
   */
  private getInitialSelectedDates = (resource: Resource, isLongReservation: boolean): SelectedDateHolder => {
    const minDaysInAdvance = resource.reservableMinDaysInAdvance;
    const firstPossibleDay = moment().add(minDaysInAdvance ?? 0, "days");
    const defaultDates: SelectedDateHolder = {
      startDate: firstPossibleDay.startOf("day").toDate(),
      endDate: firstPossibleDay.endOf("day").toDate()
    };

    if (isLongReservation) {
      const minPeriod = resource.minPeriod;
      const minDuration = minPeriod ? moment.duration(minPeriod) : undefined;
      const startDate = firstPossibleDay.startOf("day").toDate();
      const endDate = minDuration && minDuration.asDays() >= 1 ?
        firstPossibleDay.endOf("day").add(minDuration).toDate() :
        firstPossibleDay.endOf("day").toDate();

      return { startDate, endDate };
    }

    const openingHours = resource.openingHours ?
      [ ...resource.openingHours ] as RespaDateObject[] :
      [];

    const startIndex = openingHours.findIndex(dayOpeningHours => dayOpeningHours.date === firstPossibleDay.format("YYYY-MM-DD"));
    if (!startIndex) {
      return defaultDates;
    }

    const firstOpenDay = openingHours
      .slice(startIndex)
      .find(dayOpeningHours =>
        dayOpeningHours.opens !== null || dayOpeningHours.closes !== null
      );

    const firstOpenDate = firstOpenDay ? moment(firstOpenDay.date) : undefined;

    return firstOpenDate ? {
      startDate: firstOpenDate.startOf("day").toDate(),
      endDate: firstOpenDate.endOf("day").toDate()
    } : defaultDates;
  }

  /**
   * Construct reservation data
   *
   * @param resource resource
   * @param reservationsResponse reservations response
   * @returns resource reservation data holder or undefined
   */
  private constructReservationData = (resource: Resource, reservationsResponse: InlineResponse2007): ResourceReservationDataHolder | undefined => {
    if (!resource.openingHours || resource.openingHours.length < 1) {
      return;
    }

    const reservations: Reservation[] = reservationsResponse.results ? reservationsResponse.results : [];
    const openingHours = [ ...resource.openingHours ] as RespaDateObject[];
    const reservationData: ResourceReservationDataHolder = {
      openingHours: openingHours,
      minPeriod: resource.minPeriod ?? "",
      maxPeriod: resource.maxPeriod,
      reservations: reservations,
      reservable: resource.reservable || false,
      longReservationOnly: resource.minPeriod ?
        (resource.minPeriod.length > 8 && resource.minPeriod.includes(" ")) :
        false
    };

    return reservationData;
  }

  /**
   * Method for getting date range string between two dates
   *
   * @param startDate start date
   * @param endDate end date
   * @param includeTimes include times to strings
   * @returns date range string
   */
  private getDateRangeString = (startDate: Date, endDate: Date, longReservation?: boolean): string => {
    const startDateString = moment(startDate).format("DD.MM.YYYY");
    const endDateString = moment(endDate).format("DD.MM.YYYY");

    if (longReservation) {
      const isSameDay = moment(startDate).isSame(endDate, "day");
      return isSameDay ?
        startDateString :
        `${ startDateString }- ${ endDateString }`;
    }

    const clock = strings.genericWords.clock;
    const startTimeString = moment(startDate).format("HH.mm");
    const endTimeString = moment(endDate).format("HH.mm");
    return `${ startDateString } ${ clock } ${ startTimeString } - ${ endTimeString }`;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  keycloak: state.auth.keycloak,
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

const Styled = withStyles(styles)(ResourceScreen);
const CustomStyled = withCustomStyles("screens/resource-screen")(Styled);
const Connected = connect(mapStateToProps)(CustomStyled);

export default Connected;