import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    width: "100%",
  },

  detailRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10
  },

  detailName: {
    marginRight: 10
  },

  detailIcon: {
    fontSize: 26,
    marginRight: 10,
    "& svg": {
      color: "#252729"
    }
  },

  detailValue: { },

});
