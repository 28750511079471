import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  loaderContainer: {
    width: "100%",
    height: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  container: {
    width: "100%",
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 5,
    paddingBottom: 25,
    [theme.breakpoints.up("sm")]: {
      flex: 1,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      padding: 25
    }
  }

});