import * as React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {}

/**
 * Render between icon
 * 
 * @param props component properties
 */
const BetweenIcon: React.FC<Props> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M6.87857 13.25L9.43571 15.9425L8.42857 17L4.14286 12.5L8.42857 8L9.44286 9.0575L6.87857 11.75L17.1214 11.75L14.5643 9.0575L15.5714 8L19.8571 12.5L15.5714 17L14.5571 15.9425L17.1214 13.25L6.87857 13.25Z" fill="black"/>
      <path d="M3.42857 8L3.42857 17H2V8H3.42857Z" fill="black"/>
      <path d="M22 17H20.5714V8H22V17Z" fill="black"/>
    </SvgIcon>
  );
}

export default BetweenIcon;