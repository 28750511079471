/* tslint:disable */
/* eslint-disable */
/**
 * Respa
 * The Respa API provides categorized data on resources available for reservation within a city or metropolitan area and enables the reservation of these resources. The API provides data in the JSON format, in a RESTful fashion.
 *
 * The version of the OpenAPI document: 1.6.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnitStreetAddress
 */
export interface UnitStreetAddress {
    /**
     * Swedish address for the unit
     * @type {string}
     * @memberof UnitStreetAddress
     */
    sv?: string;
    /**
     * Finnish address for the unit
     * @type {string}
     * @memberof UnitStreetAddress
     */
    fi?: string;
    /**
     * English address for the unit
     * @type {string}
     * @memberof UnitStreetAddress
     */
    en?: string;
}

export function UnitStreetAddressFromJSON(json: any): UnitStreetAddress {
    return UnitStreetAddressFromJSONTyped(json, false);
}

export function UnitStreetAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitStreetAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function UnitStreetAddressToJSON(value?: UnitStreetAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sv': value.sv,
        'fi': value.fi,
        'en': value.en,
    };
}


