import * as React from "react";
import { withStyles, WithStyles, Card, CardContent, Typography, List } from "@material-ui/core";
import styles from "../../styles/generic/filters";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import { Link } from 'react-router-dom';
import { Type, TypeName } from "../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  types?: Type[];
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  filters: string[];
  filterName: string;
}

/**
 * Filters component
 */
class Filters extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: [],
      filterName: "types"
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    const filterCards = this.renderFilterCards();

    return (
      <div
        className={ classes.root }
        style={ customStyles?.root }
      >
        <List
          className={ classes.list }
          style={ customStyles?.list }
        >
          { filterCards }
        </List>
      </div>
    );
  }

  /**
   * Renders filter cards
   */
  private renderFilterCards = () => {
    const { types } = this.props;

    if (!types) {
      return null;
    }

    return types.map(type => {
      return this.renderFilterCard(type);
    });

  }

  /**
   * Renders single filter card
   *
   * @param type Type
   */
  private renderFilterCard = (type: Type) => {
    const { classes, customStyles, locale } = this.props;
    const { filterName } = this.state;
    const { id, name } = type;

    if (!id || !name) {
      return null;
    }

    const nameLocale = name[locale as keyof TypeName];

    return (
      <div
        key={ nameLocale }
        className={ classes.container }
        style={ customStyles?.container }
      >
        <Link
          className={ classes.link }
          style={ customStyles?.link }
          to={ `${ window.location.pathname.replace(/\/$/g, "") }/${ filterName }/${ id }` }
        >
          <Card
            variant="outlined"
            className={ classes.card }
            style={ customStyles?.card }
          >
            <CardContent
              className={ classes.cardContent }
              style={ customStyles?.cardContent }
            >
              <Typography
                className={ classes.cardName }
                style={ customStyles?.cardName }
              >
                { nameLocale }
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    );
  }
}

const Styled = withStyles(styles)(Filters);
const CustomStyled = withCustomStyles("generic/filters")(Styled);

export default CustomStyled;
