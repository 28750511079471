import * as React from 'react';

import { Provider } from "react-redux";
import { createStore } from "redux";
import { ReduxActions, ReduxState, rootReducer } from "../store";
import * as immer from "immer";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { responsiveFontSizes, ThemeProvider, CssBaseline, Theme } from "@material-ui/core";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import strings from "../localization/strings";
import HomeScreen from "./screens/home-screen";
import MyReservationsScreen from "./screens/my-reservations-screen";
import ResourcesScreen from "./screens/resources-screen";
import ResourceScreen from "./screens/resource-screen";
import SearchScreen from "./screens/search-screen";
import AccessTokenRefresh from "./containers/access-token-refresh";
import StoreInitializer from "./containers/store-initializer";
import defaultTheme from "../theme/default-theme";
import AuthorizedOnly from "./containers/authorized-only";
import MoreInformationScreen from "./screens/more-information-screen";
import GenericTermsScreen from "./screens/generic-terms-screen";
import AccessibilityStatementScreen from "./screens/accessibility-statement-screen";
import PrivacyPolicyScreen from "./screens/privacy-policy-screen";
import { SiteUtils } from "../utils/site-utils";
import * as Sentry from "@sentry/react";

/**
 * Initialize Sentry Redux enhancer
 */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({ });

/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer, sentryReduxEnhancer);

/**
 * Interface describing component properties
 */
interface Props {
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  theme: Theme;
}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      theme: defaultTheme
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    moment.locale(strings.getLanguage());
    immer.enableAllPlugins();

    const themePath = "/theme";
    await import(`../dynamic-content${themePath}`)
      .then(themeModule => this.setState({ theme: themeModule.default }))
      .catch(e => console.log("Custom theme not found, using default theme.", e));

    const faviconPath = "/resources/images/favicon.png";
    await import(`../dynamic-content${faviconPath}`)
      .then(favicon => favicon.default)
      .then(SiteUtils.setFavicon)
      .catch(e => console.log("Custom favicon not found, using default one.", e));

    this.setState({ loading: false });
  }

  /**
   * Component render
   */
  public render = () => {
    if (this.state.loading) {
      return null;
    }

    return (
      <ThemeProvider theme={ responsiveFontSizes(this.state.theme) }>
        <CssBaseline />
        <Provider store={ store }>
          <AccessTokenRefresh>
            <StoreInitializer>
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={ HomeScreen }
                  />
                  <Route
                    exact
                    path="/search"
                    component={ SearchScreen }
                  />
                  <Route
                    exact
                    path="/more-information"
                    component={ MoreInformationScreen }
                  />
                  <Route
                    exact
                    path="/generic-terms"
                    component={ GenericTermsScreen }
                  />
                  <Route
                    exact
                    path="/accessibility-statement"
                    component={ AccessibilityStatementScreen }
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={ PrivacyPolicyScreen }
                  />
                  <Route
                    exact
                    path="/my-reservations"
                    render={ () => (
                      <AuthorizedOnly>
                        { authorized => authorized ?
                          <MyReservationsScreen/> :
                          <Redirect to="/"/>
                        }
                      </AuthorizedOnly>
                    )}
                  />
                  <Route
                    exact
                    path="/purposes/:purpose"
                    render={({ match }) => <ResourcesScreen purpose={ match.params.purpose }/> }
                  />
                  <Route
                    exact
                    path="/purposes/:purpose/types/:type"
                    render={({ match }) => (
                      <ResourcesScreen
                        type={ match.params.type }
                        purpose={ match.params.purpose }
                      />
                    )}
                  />
                  <Route
                    path="/resources/:resourceId"
                    exact
                    render={({ history, match }) => (
                      <ResourceScreen
                        history={ history }
                        resourceId={ match.params.resourceId }
                      />
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </StoreInitializer>
          </AccessTokenRefresh>
        </Provider>
      </ThemeProvider>
    );
  }

}

export default Sentry.withProfiler(App);
