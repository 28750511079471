import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    width: "100%",
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {      
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: 1200
    }
  },

  heading: {
    fontSize: 30,
    fontWeight: 500,
    color: theme.palette.text.primary
  },

  purposes: {
    flex: 1,
    padding: 25,
    [theme.breakpoints.up("md")]: {
      padding: 25
    }
  },

  swapIcon: {

  },

});