import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    overflowX: "hidden",
    "& div:focus": {
      outline: "1px auto",
      outlineOffset: 2
    },
    "& li:focus": {
      outline: "1px auto",
      outlineOffset: 2
    },
    "& a:focus": {
      outline: "1px auto",
      outlineOffset: 2
    },
    "& button:focus": {
      outline: "1px auto",
      outlineOffset: 2
    }
  },

  content: {
    display: "flex",
    flex: 1,
    backgroundColor: theme.palette.secondary.main,
  }

});