import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  container: {
    backgroundColor: "#757575",
    marginTop: 25,
    marginBottom: 25,
  },

  card: {
    height: "auto",
  },

  cardContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  cardImageContainer: {
    width: "25%",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  cardImage: {
    width: "100%"
  },

  cardText: {
    flex: 2,
    padding: 10,
    display: "flex",
    flexDirection: "column"
  },

  cardDetails: {
    flex: 1,
    padding: 10,
    display: "flex",
    flexDirection: "column"
  },

  cardName: {
    color: theme.palette.text.primary,
  },

  cardDescription: {
    color: theme.palette.text.primary,
  },

  subDescription: {
    color: theme.palette.text.primary,
    marginTop: 20
  },

  cancelButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary
  },

  noticeContainer: {
    padding: 10,
    backgroundColor: "#DBDBDB",
    color: theme.palette.grey[200],
    textTransform: "uppercase",
    textAlign: "center"
  },

  rejectedContainer: {
    padding: 10,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    textAlign: "center"
  },

  removeButton: {
    marginTop: 10
  }

});
